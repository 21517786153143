import { Doughnut } from "react-chartjs-2";

function DoughnutChart({ data, showLegend }) {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        display: Boolean(showLegend),
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.label}: ${tooltipItem.raw} units`;
          },
        },
      },
    },
  };

  const hasData =
    data &&
    data.datasets &&
    data.datasets.length > 0 &&
    data.datasets.some((dataset) => dataset.data.some((value) => value > 0));

  return (
    <div className="w-100 h-100">
      {hasData ? (
        <Doughnut data={data} options={options} />
      ) : (
        <div className="d-flex justify-content-center align-items-center h-100">
          <p>No hay datos para mostrar la gráfica.</p>
        </div>
      )}
    </div>
  );
}

export default DoughnutChart;
