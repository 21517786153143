export const deleteDocumentService = (fetch) => async (document) => {
  try {
    const response = await fetch({
      url: "/api/v2/delete/document/institucional/ftp",
      method: "post",
      body: { document },
      permission: "1",
    });

    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};
