import { Container, Table } from "react-bootstrap";

function Responsable() {
  return (
    <>
      <br />
      <h1>Responsable del PESV</h1>
      <Container>
        <br />
        <p className="texto">
          El Responsable del PESV en SOFERCO S.A.S es la persona que haga sus
          veces como Responsable de la Logística a nivel de todas las sedes de
          la empresa, el cual ha sido designado por la Gerencia y que
          desarrollará las siguientes funciones:
          <br />
          <br />
          ▪ Asegurar que se establezcan, implementen y mantengan los procesos
          necesarios para el Plan Estratégico de Seguridad Vial.
          <br />
          ▪ Informar a la junta directiva sobre el desempeño del Plan
          Estratégico de Seguridad Vial con los indicadores correspondientes y
          de cualquier necesidad de mejora.
          <br />
          ▪ Velar por la existencia de los recursos necesarios para el
          funcionamiento del Plan Estratégico de Seguridad Vial.
          <br />
          <br />
          El Responsable del PESV contará con el apoyo del área de Seguridad y
          Salud en el Trabajo.
          <br />
          <br />
        </p>
        <Table style={{ border: "2px solid black", textAlign: "center" }}>
          <tbody>
            <tr>
              <td>Nombre:</td>
              <td>Leonardo González</td>
            </tr>
            <tr>
              <td>Cargo:</td>
              <td>Responsable de Logística</td>
            </tr>
            <tr>
              <td>Profesión:</td>
              <td>Ingeniero Industrial</td>
            </tr>
            <tr>
              <td>Contacto:</td>
              <td>Leonardo.gonzalez@ferremaster.com.co</td>
            </tr>
          </tbody>
        </Table>
        <br />
        <h3>Idoneidad del Responsable del PESV</h3>
        <br />
        <p className="texto">
          El Gerente General garantiza los mecanismos necesarios que permitan
          certificar la idoneidad para el diseño, desarrollo, implementación y
          seguimiento del Plan estratégico de Seguridad vial y todas las
          acciones contempladas en este.
          <br />
          El Responsable Logístico tiene una formación como Ingeniero
          Industrial, especialista en Logística y Maestría en Ingeniería
          Industrial con trayectoria de 9 años en la empresa, sumado a que se
          cuenta con la asesoría de una profesional con licencia vigente en
          salud ocupacional y quien además es auditor interno de Seguridad Vial.
          <br />
        </p>
        <br />
      </Container>
    </>
  );
}
export default Responsable;
