import "./assets/index.css";
import { useReportAccidentCommand } from "./hooks/useReportAccidentCommand";
import Textfield from "../../common/components/Textfiled";
import Select from "../../common/components/Select";
import Button from "../../common/components/Button";

function RegisterAccidents() {
  const {
    accident,
    handleChangeAccident,
    createReportAccident,
    errors: { errors },
  } = useReportAccidentCommand();

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    createReportAccident(accident);
  };

  return (
    <main class="main-container">
      <header class="header-container">
        <h1 class="header-title">Registrar reporte de siniestros viales</h1>
      </header>
      <section class="section-container">
        <form class="form-container" onSubmit={handleSubmit}>
          <Textfield
            label="Fecha del siniestro"
            type="date"
            name="dateAccident"
            value={accident.dateAccident}
            onChange={handleChangeAccident}
            error={errors.dateAccident}
          />
          <Textfield
            label="Nombre del implicado"
            name="namePersonInvolved"
            placeholder="Nombre completo"
            value={accident.namePersonInvolved}
            onChange={handleChangeAccident}
            error={errors.namePersonInvolved}
          />
          <Textfield
            label="Cédula del implicado"
            name="documentPersonInvolved"
            type="number"
            placeholder="Número de cédula"
            value={accident.documentPersonInvolved}
            onChange={handleChangeAccident}
            error={errors.documentPersonInvolved}
          />
          <Textfield
            label="Placa del vehículo implicado"
            name="vehiclePlate"
            placeholder="Número de placa"
            value={accident.vehiclePlate}
            onChange={handleChangeAccident}
            error={errors.vehiclePlate}
          />
          <Select
            label="Tipo de contratación"
            name="typeContract"
            value={accident.typeContract}
            onChange={handleChangeAccident}
            error={errors.typeContract}
            options={[
              { value: "termino fijo", label: "Termino fijo" },
              { value: "termino indefinido", label: "Termino indefinido" },
              { value: "contratista", label: "Contratista" },
              { value: "tercerizado", label: "Tercerizado" },
            ]}
          />
          <Textfield
            label="Lugar del suceso"
            name="placeOfEvent"
            placeholder="Lugar del suceso"
            value={accident.placeOfEvent}
            onChange={handleChangeAccident}
            error={errors.placeOfEvent}
          />
          <h2 class="form-subtitle">Nivel de pérdida</h2>
          <Textfield
            label="Fatalidad"
            name="fatality"
            type="number"
            placeholder="Número de fallecidos"
            value={accident.fatality}
            onChange={handleChangeAccident}
            error={errors.fatality}
          />
          <Textfield
            label="Heridos graves"
            name="seriousInjuries"
            type="number"
            placeholder="Número de heridos graves"
            value={accident.seriousInjuries}
            onChange={handleChangeAccident}
            error={errors.seriousInjuries}
          />
          <Textfield
            label="Heridos leves"
            name="minorInjuries"
            type="number"
            placeholder="Número de heridos leves"
            value={accident.minorInjuries}
            onChange={handleChangeAccident}
            error={errors.minorInjuries}
          />
          <Textfield
            label="Choque simple"
            name="simpleShock"
            type="number"
            placeholder="Número de choques simples"
            value={accident.simpleShock}
            onChange={handleChangeAccident}
            error={errors.simpleShock}
          />
          <Textfield
            label="Informe Policial de Accidente de Tránsito (IPAT)"
            name="ipat"
            placeholder="Número de IPAT"
            value={accident.ipat}
            onChange={handleChangeAccident}
            error={errors.ipat}
          />
          <Textfield
            label="Nombre de quien elaboró el IPAT"
            name="ipatAuthor"
            placeholder="Nombre completo"
            value={accident.ipatAuthor}
            onChange={handleChangeAccident}
            error={errors.ipatAuthor}
          />
          <footer class="form-footer">
            <Button>Registrar</Button>
          </footer>
        </form>
      </section>
    </main>
  );
}

export default RegisterAccidents;
