import { Container } from "react-bootstrap";
import React from "react";

import firma from "../img/firma.png";
import integrantes from "../img/integrantes.png";
import responsable from "../img/responsable.png";

function ActaConformacionCOSEVI() {
  return (
    <>
      <Container>
        <br />
        <h1>ACTA DE CONFORMACIÓN DEL COMITÉ DE SEGURIDAD VIAL</h1>
        <br />
        <p className="texto">
          En Guadalajara de Buga a los 16 días del mes de noviembre del año
          2021, en las instalaciones de la SOCIEDAD FERRETERA DE COMERCIO S.A.S.
          (SOFERCO S.A.S.), se reunieron las personas que han sido designadas
          para conformar el Comité de Seguridad Vial y así dar cumplimiento a la
          Resolución 1565 de 2014. El Sr. Rodrigo Cuadros Gil, Representante
          Legal de la empresa, delega al personal relacionado a ser los
          representes del Comité de Seguridad Vial, dadas sus competencias y
          roles que desempeñan dentro de la organización para dar así
          cumplimiento a las funciones adjuntas a este documento. Así mismo
          asume el compromiso de propiciar los espacios, las condiciones y los
          recursos necesarios que permitan garantizar el buen funcionamiento
          orientado al cumplimiento del requerimiento normativo.
          <br />
          <br />
          De acuerdo a lo anterior, se acuerda desarrollar el siguiente orden
          del día: <br />
          <br />
          1. Establecer el Objetivo del Comité de Seguridad Vial. <br />
          2. Designación de áreas que integran el Comité. <br />
          3. Nombramiento responsable general del Plan Estratégico de Seguridad
          Vial. <br />
          4. Asignación deberes y responsabilidades de las áreas que integran el
          Comité. <br />
          5. Determinación frecuencia de las reuniones del Comité. <br />
        </p>
        <br />
        <br />

        <h5>OBJETIVO DEL COMITÉ DE SEGURIDAD VIAL </h5>
        <br />
        <p className="texto">
          El Comité de Seguridad Vial de la SOCIEDAD FERRETERA DE COMERCIO
          S.A.S. (SOFERCO S.AS.), tiene como objetivo planear, diseñar,
          implementar y medir las acciones que permitan lograr el cumplimiento
          del Plan Estratégico de Seguridad Vial de la empresa con el ánimo de
          prevenir los incidentes y accidentes viales en los trabajadores
          <br />
        </p>
        <br />
        <br />
        <h5>DESIGNACIÓN DE ÁREAS QUE INTEGRAN EL COMITÉ SEGURIDAD VIAL </h5>
        <br />
        <p className="texto">
          La Gerencia General de la SOCIEDAD FERRETERA DE COMERCIO S.A.S.
          (SOFERCO S.A.S.) conforma el Comité de Seguridad Vial, en el cual
          participan los delegados de las áreas de trabajo de acuerdo a los
          requerimientos del Plan Estratégico de Seguridad Vial, así:
        </p>
        <br />
        <p>
          <img src={integrantes} alt="Integrantes" style={{ width: "100%" }} />
        </p>
        <br />
        <p>
          Cuando el Comité de Seguridad Vial de la empresa, presente
          modificaciones en sus áreas de trabajo o con sus integrantes, se
          actualizará en las reuniones de seguimiento, dejando constancia de lo
          anterior. <br />
        </p>

        <br />
        <h5>NOMBRAMIENTO RESPONSABLE PLAN ESTRATÉGICO DE SEGURIDAD VIAL</h5>
        <br />
        <p className="texto">
          De acuerdo con el numeral 7.1 de la Resolución 1565 de 2014 la
          Gerencia de la SOCIEDAD FERRETERA DE COMERCIO S.A.S. (SOFERCO S.A.S)
          designa como Responsable del PESV al Ing. Leonardo Alberto González
          Valencia, con cargo al interior de la empresa de Responsable
          Logístico, quien será el encargado de elaborar, implementar y realizar
          el seguimiento al Plan Estratégico de Seguridad Vial de SOFERCO S.A.S.{" "}
          <br />
          <br />
          Adicionalmente, la empresa para los casos que exija la ley o en casos
          puntales que los requiera como asesoría, capacitaciones, auditorías
          entre otros temas que requiera el Plan Estratégico de Seguridad Vial,
          cuenta con el apoyo de la Asesora Externa del SGSST, la Sra. María
          Elena Obando Colonia, con competencias en Seguridad Vial, sumado al
          apoyo de la ARL SURA. <br />
        </p>
        <br />
        <h5>
          RESPONSABLE DEL PLAN ESTRATÉGICO DE SEGURIDAD VIAL Y DEL
          FUNCIONAMIENTO DEL COMITÉ DE SEGURIDAD VIAL
        </h5>
        <br />
        <p style={{ textAlign: "center" }}>
          <img
            src={responsable}
            alt="Responsable del PESV"
            style={{ width: "75%" }}
          />
        </p>
        <br />

        <h5>FUNCIONES Y RESPONSABILIDADES</h5>
        <br />
        <h6>GERENCIA:</h6>
        <p className="texto">
          General de la SOCIEDAD FERRETERA DE COMERCIO S.A.S. (SOFERCO S.A.S)
          asume los siguientes compromisos: <br />
          • Participar activamente del proceso de creación e implementación del
          PESV. <br />
          • Promover la participación de los delegados designados por cada una
          de las áreas integrantes del Comité de Seguridad Vial. <br />
          • Facilitar los recursos técnicos, humanos y económicos encaminados a
          la implementación del PESV. <br />
          • Promover la divulgación efectiva del PESV entre el personal de la
          empresa. <br />
          • Adoptar las decisiones oportunas que hagan posible llevar a buen
          término las medidas adoptadas en relación al PESV. <br />
        </p>
        <br />
        <h6>COMITÉ DE SEGURIDAD VIAL:</h6>
        <p className="texto">
          El Comité de Seguridad Vial de SOFERCO S.A.S., será responsable de:{" "}
          <br />
          • Analizar los resultados obtenidos en el diagnóstico inicial y
          formular la hoja de ruta a seguir, conducente a reforzar los aspectos
          favorables encontrados, mitigar los riesgos y diseñar acciones para
          garantizar un cambio de actitud en los diversos actores de la
          movilidad en la empresa. <br />
          • Identificar los factores de riesgo y establecer un plan de acción
          personalizado para cada uno de ellos.
          <br />
          • Considerar la adopción de una política de seguridad, que garantice
          la integridad y bienestar y minimice los riesgos de un accidente de
          tránsito. <br />
          • Determinar las acciones de control y auditorías viales que considere
          pertinentes. <br />
          • Presentar anualmente las campañas y acciones de acompañamiento a ser
          desarrolladas. <br />
          • Establecer los cronogramas de las diversas actividades a ejecutar y
          hacer seguimiento de las mismas.
          <br />
          • Elaborar los informes periódicos para la Gerencia, Ministerio de
          Transporte, organismo de tránsito u otros interesados, que den cuenta
          de las acciones y programas adelantados por la empresa, en materia de
          seguridad vial. <br />
        </p>
        <br />
        <h6>RESPONSABLE DEL PLAN ESTRATÉGICO DE SEGURIDD VIAL :</h6>
        <p className="texto">
          Son funciones del Responsable del Plan Estratégico de Seguridad Vial:
          <br />
          • Asumir la dirección del Plan Estratégico de Seguridad Vial de la
          empresa, asesorando a la Gerencia en la formulación de lineamientos y
          procedimientos para el desarrollo del plan.
          <br />
          • Informar periódicamente a la Gerencia sobre las actividades y
          avances del Plan Estratégico de Seguridad Vial, que se han
          desarrollado para el cumplimento de los objetivos propuestos. <br />
          • Validar con el personal de las áreas que integran el Comité de
          Seguridad Vial, los planes de acción y realizar seguimiento a su
          cumplimiento. <br />
          • Realizar seguimiento al cumplimiento de las reuniones del Comité de
          Seguridad Vial de la empresa. <br />
          • Programar oportunamente reuniones, asesorías, auditorías
          relacionados con el Plan Estratégico de Seguridad Vial, con
          proveedores idóneos. <br />
        </p>
        <br />
        <h6>ÁREAS DE TRABAJO QUE INTEGRAN EL COMITÈ:</h6>
        <p className="texto">
          Las responsabilidades de las áreas que integran el Comité de Seguridad
          de la empresa se definen de acuerdo a los requerimientos de cada pilar
          del Plan Estratégico de Seguridad Vial:
          <br />
          • Aprobación de políticas requeridas en el Plan Estratégico de
          Seguridad Vial, de acuerdo a las necesidades operacionales de la
          empresa. <br />
          • Garantizar la vinculación del personal idóneo de acuerdo a perfil
          del cargo, validando los requerimientos en seguridad vial establecidos
          por la empresa.
          <br />
          • Garantizar la realización de los exámenes médicos y de seguridad
          vial de ingreso establecidos a los diferentes cargos de la empresa.
          <br />
          • Realizar seguimiento a los indicadores establecidos en el Plan
          Estratégico de Seguridad Vial. <br />
          • Conocer de manera oportuna los resultados de las auditorías
          realizadas al Plan Estratégico de Seguridad Vial y direccionar
          acciones de mejora con el Comité de Seguridad Vial.
          <br />
          • Realizar el diagnóstico en seguridad vial de la empresa, teniendo en
          cuenta los criterios establecidos en la Resolución 1565 del 2014 y
          generar planes de acción. <br />
          • Realizar el control de la información de las hojas de vida en el
          proceso de selección y durante la permanencia laboral de los
          trabajadores, relacionada con seguridad vial (licencias de conducción,
          infracciones de tránsito, investigación de accidentes viales, otros).{" "}
          <br />
          • Garantizar la realización de los exámenes médicos y de seguridad
          vial periódicos establecidos a los diferentes cargos de la empresa.{" "}
          <br />
          • Establecer cronograma de capacitaciones de acuerdo a las necesidades
          de la empresa respecto a seguridad vial (rol vial, vehículo, riesgo) y
          realizar seguimiento al cumplimiento del mismo. <br />
          • Supervisar el cumplimiento del uso adecuado de los elementos de
          protección personal así como de su mantenimiento, requeridos para la
          conducción.
          <br />
          • Realizar el seguimiento a la ejecución de la lista de verificación
          pre-operacional realizada a los vehículos y gestionar la corrección
          oportuna de las no conformidades encontradas.
          <br />
          • Mantener de forma adecuada y segura las áreas internas de la
          empresa, destinadas para el uso de vehículos y tránsito peatonal.{" "}
          <br />
          • Identificar los riesgos en seguridad vial en infraestructura externa
          con el propósito de generar medidas de prevención para divulgar a los
          trabajadores.
          <br />
          • Realizar de manera oportuna la investigación de accidentes laborales
          por riesgo tránsito, generar acciones de prevención y divulgar a los
          trabajadores por medio de lecciones aprendidas o Lección de un punto
          (LUP). <br />
          • Divulgar de manera permanente el protocolo de atención a víctimas
          establecida por la empresa a los trabajadores. <br />
          • Divulgar a todo el personal de la empresa todos los procesos o
          planes de acción implementados para el cumplimiento de la política de
          seguridad vial y del PESV. <br />
          • Realizar el control de los indicadores establecidos para el área
          respecto al Plan Estratégico de Seguridad Vial e informar sobre sus
          resultados a la Gerencia. <br />
          • Programar de manera oportuna las auditorías requeridas del Plan
          Estratégico de Seguridad Vial e informar sobre sus resultados a la
          Gerencia y Comité de Seguridad Vial. <br />
          • Realizar seguimiento al mantenimiento preventivo y correctivo de los
          vehículos de propiedad de la empresa. <br />
          • Realizar el seguimiento al control establecido por la empresa a los
          vehículos de los trabajadores, para que estos estén en óptimas
          condiciones de uso. <br />
          • Realizar el control documental (soat, otros) de los vehículos
          disponibles al servicio de la empresa para el cumplimento de las
          actividades misionales. <br />
        </p>
        <br />
        <h6>TRABAJADORES DE LA EMPRESA RESPECTO AL P.E.S.V. :</h6>
        <p className="texto">
          Los trabajadores de la SOCIEDAD FERRETERA DE COMERCIO S.A.S. (SOFERCO
          S.A.S) que para el cumplimiento de las actividades laborales, deban
          conducir vehículos de propiedad de la empresa o de su propiedad deben:{" "}
          <br />
          • Realizar inspección pre–operacional diariamente de vehículo, de
          acuerdo al formato e instrucciones recibidas por el área de Seguridad
          y Salud en el Trabajo.
          <br />
          • Generar el reporte de las anomalías en los vehículos de propiedad de
          la empresa, al área Operativa.
          <br />
          • Si el vehículo es de propiedad del trabajador al servicio de la
          empresa, es deber del trabajador realizar los mantenimientos
          requeridos con personal idóneo, para disponer de una herramienta de
          trabajo en buenas condiciones. <br />
          • Respetar las normas nacionales en seguridad vial y las políticas
          establecidas por la empresa. <br />
          • Participar de las actividades y capacitaciones en seguridad vial
          programadas por la empresa. <br />
          • Mantener actualizado los documentos de tránsito del conductor como
          del vehículo. <br />
          • Los trabajadores que ejerzan el rol de pasajero o peatón para el
          cumplimiento de las actividades laborales, deben cumplir y respetar
          las normas de tránsito a nivel nacional, para un desplazamiento
          seguro. <br />
        </p>
        <br />
        <h6>REUNIONES DEL COMITÉ:</h6>
        <p className="texto">
          El Comité de Seguridad Vial se reunirá cada tres (3) meses y sesionará
          con la mitad más uno de sus integrantes y extraordinariamente cuando
          se presenten casos que requieran de su inmediata intervención y podrá
          ser convocado por cualquiera de sus integrantes. De cada sesión se
          elaborará un acta de asistencia y los temas tratados. <br />
          Cuando el Comité de Seguridad Vial de la empresa, presente
          modificaciones en sus áreas de trabajo (integrantes), éste se
          actualizará en las reuniones de seguimiento, dejando constancia de lo
          anterior en la misma acta de la reunión. <br />
          La Presidencia del comité será asumida por Leonardo Alberto González
          Valencia y las funciones de Secretario (a) serán asumidas por Steven
          Sanchez Rojas
          <br />
          El presente Comité se encuentra definido por la Gerencia General de la
          SOCIEDAD FERRETERA DE COMERCIO S.A.S. (SOFERCO S.A.S), mediante su
          Representante Legal.
          <br />
        </p>
        <br />
        <br />
        <p style={{ textAlign: "center" }}>
          <img src={firma} alt="Firma" className="imagenFirma" />
        </p>
        <br />
        <p className="texto">Guadalajara de Buga, 16 de Noviembre del 2021</p>
        <br />
        <br />
      </Container>
    </>
  );
}

export default ActaConformacionCOSEVI;
