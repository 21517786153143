import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  ListGroup,
  Alert,
  Badge,
  Form,
} from "react-bootstrap";
import { IoMdNotifications } from "react-icons/io";
import axios from "axios";
import moment from "moment/moment";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import DoughnutChart from "./common/components/Charts/DoughnutChart";

const COLORS_BACKGROUND = ["#AEEA94", "#FF8383", "#FFCE56"];
const COLORS_HOVER_BACKGROUND = ["#88C273", "#F93827", "#FFB200"];
const defaultConfigChart = {
  labels: [],
  datasets: [
    {
      label: "My Dataset",
      data: [],
      backgroundColor: COLORS_BACKGROUND,
      hoverBackgroundColor: COLORS_HOVER_BACKGROUND,
    },
  ],
};

function Menu() {
  const cookie = new Cookies();
  const access_token = cookie.get("perv_v2_cookies_user_kdfoeinapjdkfawep");

  const navigate = useNavigate();

  const [dataAlerts, setDataAlerts] = useState([]);
  const [dataChart1, setDataChart1] = useState({ labels: [], values: [] });
  const [dataChart2, setDataChart2] = useState({ labels: [], values: [] });
  const [dataChart3, setDataChart3] = useState({ labels: [], values: [] });
  const [dataClaimsFreeDays, setDataClaimsFreeDays] = useState(0);
  const [dataCarsDrivesMaintenance, setDataCarsDrivesMaintenance] = useState({
    cars: 0,
    drivers: 0,
    maintenance: "0%",
  });
  const [dataMaintenance, setDataMaintenance] = useState(0);
  const [dataCPKAvailability, setDataCPKAvailability] = useState({
    cpk: "0",
    availability: "0",
  });

  const queryAlerts = async () => {
    try {
      let datosMes = {
        hoy: moment().utc().subtract(5, "hours").format("YYYY-MM-DD"),
      };
      let dataAlerts = await axios.post(
        process.env.REACT_APP_SERVIDOR + "/api/v2/alerts",
        datosMes,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );

      setDataAlerts(dataAlerts.data);
      configureDataChart3(dataAlerts.data);
      dataAlerts.data = dataAlerts.data.sort(
        (a, b) =>
          moment(a.date, "DD-MM-YYYY").unix() -
          moment(b.date, "DD-MM-YYYY").unix()
      );
      setDataAlerts(dataAlerts.data);

      /*  let contadorVencimientoMantenimiento = 0;

      for (let index = 0; index < dataAlerts.data.length; index++) {
        if (dataAlerts.data[index].text.search ('días: -') > 0) {
          contadorVencimientoMantenimiento++;
        }
      }
      let resultadoMantenimiento = 100 - contadorVencimientoMantenimiento * 25; */
      let dataPorcentaje = await axios.post(
        process.env.REACT_APP_SERVIDOR +
          "/api/v2/mantenimiento/informe_cumplimiento",
        datosMes,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (dataPorcentaje.data.estado) {
        setDataMaintenance(dataPorcentaje.data.porcentaje);
      } else {
        setDataMaintenance(0);
      }
    } catch (error) {
      console.log("Error while fetching data alerts: " + error.message);
    }
  };

  const configureDataChart3 = async (data) => {
    let cumplio = 0;
    let noCumplio = 0;
    for (let item of data) {
      if (item.estado !== "Completado") {
        noCumplio++;
      }

      if (item.estado === "Completado") {
        cumplio++;
      }
    }

    const dataChart3Aux = {
      labels: ["Completado", "No completado"],
      values: [cumplio, noCumplio],
    };

    setDataChart3(dataChart3Aux);
  };

  const onChangeUpdateTask = (e, id) => {
    const { checked } = e.target;
    let estado = checked ? "Completado" : "Activo";
    queryUpdateAlerts(estado, id);
  };

  const queryUpdateAlerts = async (estado, id) => {
    try {
      const { data } = await axios.put(
        process.env.REACT_APP_SERVIDOR + "/api/v2/update/task",
        { estado, id },
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );

      if (data.message === "success") {
        queryAlerts();
      }
    } catch (error) {
      console.log("Error while updating alerts: " + error.message);
    }
  };

  const queryChart1 = async () => {
    const cookie = new Cookies();
    const access_token = cookie.get("perv_v2_cookies_user_kdfoeinapjdkfawep");
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_SERVIDOR + "/api/v2/chart/daily/review",
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      setDataChart1(data);
    } catch (error) {
      console.log("Error while fetching chart1: " + error.message);
    }
  };

  const queryChart2 = async () => {
    const cookie = new Cookies();
    const access_token = cookie.get("perv_v2_cookies_user_kdfoeinapjdkfawep");
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_SERVIDOR + "/api/v2/chart/documentation",
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      setDataChart2(data);
    } catch (error) {
      console.log("Error while fetching chart2: " + error.message);
    }
  };

  const queryClaimsFreeDays = async () => {
    // Calcula dias sin siniestros
    const cookie = new Cookies();
    const access_token = cookie.get("perv_v2_cookies_user_kdfoeinapjdkfawep");
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_SERVIDOR + "/api/v2/claims/free/days",
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      setDataClaimsFreeDays(data.days);
    } catch (error) {
      console.log("Error while fetch claims free days: " + error.message);
    }
  };

  const queryCarsDriversMaintenance = async () => {
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_SERVIDOR + "/api/v2/cars/drivers/maintenance",
        { headers: { Authorization: `Bearer ${access_token}` } }
      );
      setDataCarsDrivesMaintenance(data);
    } catch (error) {
      console.log(
        "Error while fetching data cars, drivers and maintenance: " +
          error.message
      );
    }
  };

  const queryCPKAvailability = async () => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_SERVIDOR + "/api/v2/availability/CPK",
        "",
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      setDataCPKAvailability(data);
    } catch (error) {
      console.log(
        "Error while fetching data CPK availability: " + error.message
      );
    }
  };

  function redireccionarPlanAnual() {
    navigate("/gestion-tareas/calendario");
  }

  useEffect(() => {
    queryAlerts();
    queryChart1();
    queryChart2();
    queryClaimsFreeDays();
    queryCarsDriversMaintenance();
    queryCPKAvailability();
  }, []);

  return (
    <div
      className="containe-dashboard pt-3"
      style={{
        background: "#434242",
        height: "100%",
        width: "100%",
        color: "white",
        position: "fixed",
      }}
    >
      <main
        style={{
          width: "95%",
          marginLeft: "auto",
          marginRight: "auto",
          padding: "20px",
        }}
      >
        <Row className="justify-content-center">
          <Col xs={8} md={3}>
            <h1>
              <Badge className="mt-2" bg="dark" style={{ width: "100%" }}>
                Alertas <IoMdNotifications />
              </Badge>
            </h1>
            <ListGroup
              as="ol"
              style={{
                maxHeight: "700px",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {dataAlerts.map((item, i) => (
                <ListGroup.Item
                  key={i}
                  as="li"
                  className="d-flex justify-content-between align-items-start"
                  style={{ cursor: "pointer" }}
                >
                  <Row>
                    <Row>
                      {item.tipo !== "Mantenimiento" ? (
                        <Col className="col-2">
                          <Form.Check
                            checked={
                              item.estado === "Completado" ? true : false
                            }
                            onChange={(e) =>
                              onChangeUpdateTask(e, item.id_tarea)
                            }
                          />
                        </Col>
                      ) : null}
                      <Col className="col-10">
                        <Badge bg="dark" pill>
                          {item.date}
                        </Badge>
                      </Col>
                    </Row>
                    <Row onClick={() => redireccionarPlanAnual()}>
                      <Col>
                        <div
                          className={
                            item.estado === "Completado"
                              ? "text-decoration-line-through"
                              : null
                          }
                        >
                          {item.text}
                        </div>
                      </Col>
                    </Row>
                  </Row>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
          <Col xs={12} md={9}>
            <Row className="mb-5">
              <Col md={4} className="text-center mt-2">
                <h6 className="mt-2 fs-3">Revisión Diaria</h6>
                <div className="chartTorta" style={{ height: "250px" }}>
                  <DoughnutChart
                    data={{
                      ...defaultConfigChart,
                      labels: dataChart1.labels,
                      datasets: [
                        {
                          label: "My Dataset",
                          data: dataChart1.values,
                          backgroundColor: COLORS_BACKGROUND,
                          hoverBackgroundColor: COLORS_HOVER_BACKGROUND,
                        },
                      ],
                    }}
                  />
                </div>
              </Col>
              <Col md={4} className="text-center mt-2">
                <h6 className="mt-2 fs-3">Tareas</h6>
                <div className="chartTorta" style={{ height: "250px" }}>
                  <DoughnutChart
                    data={{
                      ...defaultConfigChart,
                      labels: dataChart3.labels,
                      datasets: [
                        {
                          label: "My Dataset",
                          data: dataChart3.values,
                          backgroundColor: COLORS_BACKGROUND,
                          hoverBackgroundColor: COLORS_HOVER_BACKGROUND,
                        },
                      ],
                    }}
                  />
                </div>
              </Col>
              <Col md={4} className="text-center">
                <h6 className="mt-2 fs-3">Documentación</h6>
                <div className="chartTorta" style={{ height: "250px" }}>
                  <DoughnutChart
                    data={{
                      ...defaultConfigChart,
                      labels: dataChart2.labels,
                      datasets: [
                        {
                          label: "My Dataset",
                          data: dataChart2.values,
                          backgroundColor: COLORS_BACKGROUND,
                          hoverBackgroundColor: COLORS_HOVER_BACKGROUND,
                        },
                      ],
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row className="mb-2 justify-content-center">
              <Col xs={6} md={4} className="mb-1">
                <Alert
                  className="text-center"
                  variant="success"
                  style={{ margin: 0, height: "120px" }}
                >
                  <Alert.Heading>
                    <a
                      target="new"
                      className="hipervinculo"
                      href="/VehiculoSeguroHojaVidaVehiculos"
                    >
                      Vehículos
                    </a>
                  </Alert.Heading>
                  <hr style={{ margin: 0 }} />
                  <p style={{ fontSize: "50px" }}>
                    {dataCarsDrivesMaintenance.cars}
                  </p>
                </Alert>
              </Col>
              <Col xs={6} md={4} className="mb-1">
                <Alert
                  className="text-center"
                  variant="success"
                  style={{ margin: 0, height: "120px" }}
                >
                  <Alert.Heading>
                    <a
                      target="new"
                      className="hipervinculo"
                      href="/conductores"
                    >
                      Conductores
                    </a>
                  </Alert.Heading>
                  <hr style={{ margin: 0 }} />
                  <p style={{ fontSize: "50px" }}>
                    {dataCarsDrivesMaintenance.drivers}
                  </p>
                </Alert>
              </Col>
              <Col xs={7} md={4} className="mb-1">
                <Alert
                  className="text-center"
                  variant="success"
                  style={{ margin: 0, height: "120px" }}
                >
                  <Alert.Heading>Mantenimiento</Alert.Heading>
                  <hr style={{ margin: 0 }} />
                  <p style={{ fontSize: "50px" }}>{dataMaintenance}%</p>
                </Alert>
              </Col>
            </Row>
            <Row>
              <Col xs={10} md={6} className="mb-1">
                <ListGroup horizontal>
                  <ListGroup.Item style={{ width: "200px" }}>
                    <a
                      className="hipervinculo"
                      target="new"
                      href="/RegistrosCaracteristicas"
                    >
                      Días sin Siniestros
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item style={{ width: "200px" }}>
                    {dataClaimsFreeDays}
                  </ListGroup.Item>
                </ListGroup>
                <ListGroup horizontal>
                  <ListGroup.Item style={{ width: "200px" }}>
                    CPK
                  </ListGroup.Item>
                  <ListGroup.Item style={{ width: "200px" }}>
                    ${dataCPKAvailability.cpk}
                  </ListGroup.Item>
                </ListGroup>
                <ListGroup horizontal>
                  <ListGroup.Item style={{ width: "200px" }}>
                    Disponibilidad
                  </ListGroup.Item>
                  <ListGroup.Item style={{ width: "200px" }}>
                    {dataCPKAvailability.availability}%
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </main>
    </div>
  );
}

export default Menu;
