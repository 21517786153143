import { Container, Form, Col, Row, Button } from "react-bootstrap";
import { useEffect, useState } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Cookies from "universal-cookie";
import { useLocation } from "react-router";

import Swal from "sweetalert2";
import axios from "axios";

import { BsPencil } from "react-icons/bs";

function Caracterizacion() {
  const cookie = new Cookies();
  const access_token = cookie.get("perv_v2_cookies_user_kdfoeinapjdkfawep");
  const [validated, setValidated] = useState(false);
  const [centro, setCentro] = useState([]);

  const [datosGuardar, setDatosGuardar] = useState({
    id: "",
    tipoEvento: "",
    fecha: "",
    cedula: "",
    nombre: "",
    centros: "",
    tipoContrato: "",
    mecanismo: "",
    tipoLesion: "",
    lugarSuceso: "",
    parteCuerpo: "",
    agenteLesion: "",
    descripcionAccidente: "",
    cargoImplicado: "",
    comportamiento: "",
    diasPerdidos: "",
    fecha_ejecucion_accion: "",
    accion_correctiva: "",
    responsable_accion: "",
    leccion_aprendida: "",
    fotografias_evento: "",
    id_leccion_aprendida: null,
    id_fotografias_evento: null,
  });
  const [infoInsertFileLeccion, setInfoInsertFileLeccion] = useState({
    file: [],
  });
  const [infoInsertFileEvento, setInfoInsertFileEvento] = useState({
    file: [],
  });

  const [archivoLeccion, setArchivoLeccion] = useState(false);
  const [archivoFotografias, setArchivoFotografias] = useState(false);
  let locacion = useLocation();

  const ValidarAccion = async () => {
    let id = locacion.search.split("=");
    const { data } = await axios.get(
      process.env.REACT_APP_SERVIDOR + `/consultar_caracterizacion_id/${id[1]}`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    if (data.estado) {
      const {
        id,
        evento,
        fecha_accidente,
        cedula,
        nombre_implicado,
        id_centro_fk,
        tipo_contratacion,
        mecanismo_accidente,
        tipo_lesion,
        lugar_suceso,
        cuerpo_afecatado,
        agente_lesion,
        descripcion_accidente,
        cargo_implicado,
        comportamiento_condicion,
        dias_incapacidad,
        fecha_ejecucion_accion,
        accion_correctiva,
        responsable_accion,
        leccion_aprendida,
        fotografias_evento,
      } = data.datos.objDatos[0];

      if (leccion_aprendida !== null) {
        setArchivoLeccion(true);
      }
      if (fotografias_evento !== null) {
        setArchivoFotografias(true);
      }
      setDatosGuardar({
        id: id,
        tipoEvento: evento,
        fecha: new Date(fecha_accidente),
        cedula: cedula,
        nombre_implicado: nombre_implicado,
        centros: id_centro_fk,
        tipoContrato: tipo_contratacion,
        mecanismo: mecanismo_accidente,
        tipoLesion: tipo_lesion,
        lugarSuceso: lugar_suceso,
        parteCuerpo: cuerpo_afecatado,
        agenteLesion: agente_lesion,
        descripcionAccidente: descripcion_accidente,
        cargoImplicado: cargo_implicado,
        comportamiento: comportamiento_condicion,
        diasPerdidos: dias_incapacidad,
        fecha_ejecucion_accion: fecha_ejecucion_accion,
        accion_correctiva: accion_correctiva,
        responsable_accion: responsable_accion,
        leccion_aprendida: leccion_aprendida,
        fotografias_evento: fotografias_evento,
        id_leccion_aprendida: data.datos.idLeccion,
        id_fotografias_evento: data.datos.idFotografia,
      });
    }
  };
  const onChangeDatos = (e) => {
    const { name, value } = e.target;
    setDatosGuardar({
      ...datosGuardar,
      [name]: value,
    });
  };
  const Actualizar = async (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      e.preventDefault();
      try {
        let idLeccionAprendida = null;
        let idFotografia = null;

        if (
          datosGuardar.leccion_aprendida === null ||
          datosGuardar.leccion_aprendida !== ""
        ) {
          if (infoInsertFileLeccion.file.length === undefined) {
            idLeccionAprendida = await queryInserFileLeccion();
          } else {
            idLeccionAprendida = datosGuardar.id_leccion_aprendida;
          }
        }
        if (
          datosGuardar.fotografias_evento === null ||
          datosGuardar.fotografias_evento !== ""
        ) {
          if (infoInsertFileEvento.file.length === undefined) {
            idFotografia = await queryInserFileEvento();
          } else {
            idFotografia = datosGuardar.id_fotografias_evento;
          }
        }

        const { data } = await axios.post(
          process.env.REACT_APP_SERVIDOR + "/actualizar_carcterizacion",
          [
            {
              datosGuardar,
              idLeccionAprendida: idLeccionAprendida,
              idFotografia: idFotografia,
            },
          ],
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
              Permission: "1",
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        if (data.message === "Modificacion Exitoso") {
          mostrarAlert("success", "Mensaje", "Actualización Exitoso");

          setDatosGuardar({
            id: "",
            tipoEvento: "",
            fecha: "",
            cedula: "",
            nombre: "",
            centros: "",
            tipoContrato: "",
            mecanismo: "",
            tipoLesion: "",
            lugarSuceso: "",
            parteCuerpo: "",
            agenteLesion: "",
            descripcionAccidente: "",
            cargoImplicado: "",
            comportamiento: "",
            diasPerdidos: "",
            fecha_ejecucion_accion: "",
            accion_correctiva: "",
            responsable_accion: "",
            leccion_aprendida: "",
            fotografias_evento: "",
            id_leccion_aprendida: null,
            id_fotografias_evento: null,
          });
          window.location.href = "../RegistrosCaracteristicas";
        }

        setValidated(false);
      } catch (error) {
        console.log("Error updating data caracterización", error);
      }
    }
  };
  const Centro = async () => {
    const { data } = await axios.get(
      process.env.REACT_APP_SERVIDOR + "/api/v2/list/centro",
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    if (data.length > 0) {
      setCentro(data);
    }
  };
  function mostrarAlert(icono, titulo, mensaje) {
    Swal.fire({
      icon: icono,
      title: mensaje,
    });
  }
  const onChangeFilePdf = async (e) => {
    let file = e.target.files[0];
    setInfoInsertFileLeccion({
      ...infoInsertFileLeccion,
      file: file,
    });
  };
  const onChangeFilePdfEvento = async (e) => {
    let file = e.target.files[0];
    setInfoInsertFileEvento({
      ...infoInsertFileEvento,
      file: file,
    });
  };
  const queryInserFileLeccion = async () => {
    let formData = new FormData();
    formData.append("filePDF", infoInsertFileLeccion.file);
    try {
      let pagina = "Caracterizacion";
      const { data } = await axios.post(
        process.env.REACT_APP_SERVIDOR +
          `/api/v2/files/formato_archivos_compania/caracterizacion/${pagina}`,
        formData,
        {
          headers: {
            Permission: "1",
            Authorization: `Bearer ${access_token}`,
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (data.message === "success") {
        setInfoInsertFileLeccion({ file: "" });
        return data.idDocumento;
      }
    } catch (error) {
      if (error.response.status === 403) {
        Swal.fire({
          icon: "error",
          title: "No tienes permiso para esta acción",
        });
      }
    }
  };
  const queryInserFileEvento = async () => {
    let formData = new FormData();
    formData.append("filePDF", infoInsertFileEvento.file);
    try {
      let pagina = "Caracterizacion";
      const { data } = await axios.post(
        process.env.REACT_APP_SERVIDOR +
          `/api/v2/files/formato_archivos_compania/caracterizacion/${pagina}`,
        formData,
        {
          headers: {
            Permission: "1",
            Authorization: `Bearer ${access_token}`,
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (data.message === "success") {
        setInfoInsertFileEvento({ file: "" });
        return data.idDocumento;
      }
    } catch (error) {
      if (error.response.status === 403) {
        Swal.fire({
          icon: "error",
          title: "No tienes permiso para esta acción",
        });
      }
    }
  };
  useEffect(() => {
    Centro();
    ValidarAccion();
  }, []);

  return (
    <>
      <Container>
        <br />
        <h1>CARACTERIZACIÓN DE SINIESTROS</h1>
        <br />
        <Form noValidate validated={validated} onSubmit={Actualizar}>
          <Row className="justify-content-md-center">
            <Col xs={4}>
              <Form.Group>
                <Form.Label>TIPO DE EVENTO</Form.Label>
                <Form.Select
                  required
                  value={datosGuardar.tipoEvento}
                  name="tipoEvento"
                  onChange={(e) => {
                    onChangeDatos(e);
                  }}
                  disabled
                >
                  <option></option>
                  <option value="CONDICION DE PELIGRO VIAL">
                    Condicion de peligro vial
                  </option>
                  <option value="CONDICION DE PELIGRO LABORAL">
                    Condicion de peligro laboral
                  </option>
                  <option value="CONDICIÓN DE SALUD">Condicion de salud</option>
                  <option value="SINIESTRO VIAL">Siniestro vial</option>
                  <option value="ACCIDENTE LABORAL">
                    Accidente laboral (Transito/Condución)
                  </option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Obligatorio
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Form.Group>
                <Form.Label>CENTRO</Form.Label>
                <Form.Select
                  required
                  value={datosGuardar.centros}
                  name="centros"
                  onChange={(e) => {
                    onChangeDatos(e);
                  }}
                >
                  <option></option>
                  {centro.map((fila, id) => (
                    <option key={id} value={fila.id_centro}>
                      {fila.centro_descripcion}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Obligatorio
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Form.Group>
                <Form.Label>AGENTE DE LESIÓN</Form.Label>
                <Form.Select
                  required
                  value={datosGuardar.agenteLesion}
                  name="agenteLesion"
                  onChange={(e) => {
                    onChangeDatos(e);
                  }}
                >
                  <option></option>
                  <option value="Maquinas">Maquinas y/o equipos</option>
                  <option value="MedioTransporte">Medios de transporte</option>
                  <option value="Aparatos">Aparatos</option>
                  <option value="Herramientas">
                    Herramientas, implementos o utensilios
                  </option>
                  <option value="Materiales">Materiales o sustancias</option>
                  <option value="AmbienteTrabajo">Ambiente de trabajo</option>
                  <option value="Otros">Otros agentes no clasificados</option>
                  <option value="Animales">
                    Animales (Vivos o productos animales)
                  </option>
                  <option value="AgentesNoClasificados">
                    Agentes no clasificados por falta de datos
                  </option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Obligatorio
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={4}>
              <Form.Group>
                <Form.Label>FECHA DEL SINIESTRO</Form.Label>
                <DatePicker
                  disabled
                  id="fecha"
                  dateFormat="dd/MM/yyyy"
                  size="sm"
                  className="form-control form-control-sm"
                  selected={datosGuardar.fecha}
                  name="fecha"
                  onChange={(e) => {
                    onChangeDatos(e);
                  }}
                />
              </Form.Group>
              <Form.Control.Feedback type="invalid">
                Obligatorio
              </Form.Control.Feedback>
            </Col>
            <Col xs={4}>
              <Form.Group>
                <Form.Label>TIPO DE CONTRATACIÓN</Form.Label>
                <Form.Select
                  required
                  value={datosGuardar.tipoContrato}
                  name="tipoContrato"
                  onChange={(e) => {
                    onChangeDatos(e);
                  }}
                >
                  <option></option>
                  <option value="Indefinido">Indefinido</option>
                  <option value="Termini Fijo">Termino Fijo</option>
                  <option value="Tercero">Tercero</option>
                  <option value="Contratista">Contratista</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Obligatorio
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Form.Group>
                <Form.Label>COMPORTAMIENTO O CONDICIÓN</Form.Label>
                <Form.Select
                  required
                  value={datosGuardar.comportamiento}
                  name="comportamiento"
                  onChange={(e) => {
                    onChangeDatos(e);
                  }}
                >
                  <Form.Control.Feedback type="invalid">
                    Obligatorio
                  </Form.Control.Feedback>
                  <option></option>
                  <option value="Comportamiento">Comportamiento</option>
                  <option value="Condicion">Condicion</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={4}>
              <Form.Group>
                <Form.Label>NOMBRE DEL IMPLICADO</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  placeholder="Ingrese el nombere del implicado"
                  value={datosGuardar.nombre_implicado}
                  name="nombre_implicado"
                  onChange={(e) => {
                    onChangeDatos(e);
                  }}
                />
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Form.Group>
                <Form.Label>MECANISMO DE SINIESTROS</Form.Label>
                <Form.Select
                  required
                  value={datosGuardar.mecanismo}
                  name="mecanismo"
                  onChange={(e) => {
                    onChangeDatos(e);
                  }}
                >
                  <option></option>
                  <option value="CaídaPersonas">Caída de personas</option>
                  <option value="CaídaObjetos">Caída de objetos</option>
                  <option value="Choques">Pisadas, choques o golpes</option>
                  <option value="Atrapamientos">Atrapamientos</option>
                  <option value="Sobreesfuerzo">
                    Sobreesfuerzo, esfuerzo excesivo o falso movimiento
                  </option>
                  <option value="Temperatura">
                    Exposición o contacto con temperatura extrema
                  </option>
                  <option value="Electricidad">
                    Exposición o contacto con la electricidad
                  </option>
                  <option value="Radiaciones">
                    Exposición o contacto con sustancias nocivas o radiaciones o
                    salpicaduras
                  </option>
                  <option value="Golpes">Golpes por o contra objetos</option>
                  <option value="Otros">Otros</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Obligatorio
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Form.Group>
                <Form.Label>DÍAS DE INCAPACIDAD</Form.Label>
                <Form.Control
                  required
                  size="sm"
                  type="number"
                  placeholder="Ingrese la cantidad de días de incapacidad "
                  value={datosGuardar.diasPerdidos}
                  name="diasPerdidos"
                  onChange={(e) => {
                    onChangeDatos(e);
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Obligatorio
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={4}>
              <Form.Group>
                <Form.Label>CÉDULA DEL IMPLICADO</Form.Label>
                <Form.Control
                  required
                  size="sm"
                  type="number"
                  placeholder="Ingrese el número de cedula"
                  value={datosGuardar.cedula}
                  name="cedula"
                  onChange={(e) => {
                    onChangeDatos(e);
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Obligatorio
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Form.Group>
                <Form.Label>TIPO DE LESIÓN</Form.Label>
                <Form.Select
                  required
                  value={datosGuardar.tipoLesion}
                  name="tipoLesion"
                  onChange={(e) => {
                    onChangeDatos(e);
                  }}
                >
                  <option></option>
                  <option value="Ninguna">Ninguna</option>
                  <option value="Leve">Leve</option>
                  <option value="Grave">Grave</option>
                  <option value="Moderado">Moderado</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Obligatorio
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Form.Group>
                <Form.Label>LUGAR DEL SUCESO</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  placeholder="Ingrese el lugar del accidente"
                  value={datosGuardar.lugarSuceso}
                  name="lugarSuceso"
                  onChange={(e) => {
                    onChangeDatos(e);
                  }}
                  disabled
                />
              </Form.Group>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={4}>
              <Form.Group>
                <Form.Label>CARGO DEL IMPLICADO</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  placeholder="Ingrese el cargo"
                  value={datosGuardar.cargoImplicado}
                  name="cargoImplicado"
                  onChange={(e) => {
                    onChangeDatos(e);
                  }}
                  disabled
                />
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Form.Group>
                <Form.Label>PARTE DEL CUERPO IMPLICADO</Form.Label>
                <Form.Select
                  required
                  value={datosGuardar.parteCuerpo}
                  name="parteCuerpo"
                  onChange={(e) => {
                    onChangeDatos(e);
                  }}
                >
                  <option></option>
                  <option value="Ninguna">Ninguna</option>
                  <option value="Cabeza">Cabeza</option>
                  <option value="Ojos">Ojos</option>
                  <option value="Cuello">Cuello</option>
                  <option value="Tronco">
                    Tronco (Incluye espalda, columna vertical, médula espinal,
                    pélvis)
                  </option>
                  <option value="Torax">Tórax</option>
                  <option value="Abdomen">Abdomen</option>
                  <option value="MiembrosSuperior">Miembros Superior</option>
                  <option value="MiembrosInferior">Miembros Inferior</option>
                  <option value="Manos">Manos</option>
                  <option value="Pies">Pies</option>
                  <option value="UbicacionesMultiples">
                    Ubicaciones Múltiples
                  </option>
                  <option value="Otros">Lesiones Generales u otras</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Obligatorio
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Form.Group>
                <Form.Label> OBSERVACIONES </Form.Label>
                <textarea
                  maxLength={600}
                  style={{ minHeight: "120px", minWidth: "100%" }}
                  value={datosGuardar.descripcionAccidente}
                  name="descripcionAccidente"
                  onChange={(e) => {
                    onChangeDatos(e);
                  }}
                  disabled
                ></textarea>
              </Form.Group>
            </Col>
          </Row>
          <br />
          <hr />
          <Row>
            <Col xs={16}>
              <Form.Group>
                <Form.Label>
                  <strong>INVESTIGACIÓN Y ACCIÓN CORRECTIVA:</strong>
                </Form.Label>
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Form.Group>
                <Form.Label>ACCIÓN CORRECTIVA</Form.Label>
                <textarea
                  maxLength={500}
                  style={{ minWidth: "100%", height: "100px" }}
                  value={datosGuardar.accion_correctiva}
                  name="accion_correctiva"
                  onChange={(e) => {
                    onChangeDatos(e);
                  }}
                ></textarea>
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Form.Group>
                <Form.Label>RESPONSABLE DE LA ACCIÓN</Form.Label>
                <textarea
                  maxLength={500}
                  style={{ minWidth: "100%", height: "100px" }}
                  value={datosGuardar.responsable_accion}
                  name="responsable_accion"
                  onChange={(e) => {
                    onChangeDatos(e);
                  }}
                ></textarea>
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Form.Group>
                <Form.Label>FECHA DE EJECUCION DE LA ACCIÓN</Form.Label>
                <textarea
                  maxLength={500}
                  style={{ minWidth: "100%", height: "100px" }}
                  value={datosGuardar.fecha_ejecucion_accion}
                  name="fecha_ejecucion_accion"
                  onChange={(e) => {
                    onChangeDatos(e);
                  }}
                ></textarea>
              </Form.Group>
            </Col>
          </Row>
          <br />

          <br />
          <Row>
            <Col xs={4}>
              <Form.Group>
                <Form.Label>INFORMACIÓN DEL EVENTO</Form.Label>
                {archivoLeccion ? (
                  <div>
                    <a
                      style={{ color: "black", border: "none" }}
                      target="_new"
                      href={
                        process.env.REACT_APP_HOST_FTP +
                        "/documentoCaracterizacion/" +
                        datosGuardar.leccion_aprendida
                      }
                    >
                      <strong>VER ARCHIVO</strong>
                    </a>
                    <BsPencil
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setArchivoLeccion(false);
                      }}
                    />
                  </div>
                ) : (
                  <Form.Control
                    size="sm"
                    accept="application/pdf"
                    type="file"
                    onChange={onChangeFilePdf}
                  />
                )}
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Form.Group>
                <Form.Label>LECCIÓN APRENDIDA</Form.Label>
                {archivoFotografias ? (
                  <div>
                    <a
                      style={{ color: "black", border: "none" }}
                      target="_new"
                      href={
                        process.env.REACT_APP_HOST_FTP +
                        "/documentoCaracterizacion/" +
                        datosGuardar.fotografias_evento
                      }
                    >
                      <strong>VER ARCHIVO</strong>
                    </a>
                    <BsPencil
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setArchivoFotografias(false);
                      }}
                    />
                  </div>
                ) : (
                  <Form.Control
                    size="sm"
                    accept="application/pdf"
                    type="file"
                    onChange={onChangeFilePdfEvento}
                  />
                )}
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Form.Group>
                <br />
                <Button style={{ width: "100%" }} type="submit">
                  ACTUALIZAR
                </Button>
              </Form.Group>
            </Col>
          </Row>
          <br />
          <br />
        </Form>
      </Container>
    </>
  );
}
export default Caracterizacion;
