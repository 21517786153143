import axios from "axios";
import React, { useState } from "react";
import { Container, Col, Row, Form, Button, Modal } from "react-bootstrap";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import Cookies from "universal-cookie";
import XLSX from "sheetjs-style";
import moment from "moment";
import { saveFile } from "../common/utils/saveFile.util";

function EncuestaEsv() {
  const access_token = new Cookies().get(
    "perv_v2_cookies_user_kdfoeinapjdkfawep"
  );

  const [validated, setValidated] = useState(false);
  const [surveyValidated, setSurveyValidated] = useState(false);
  const [showSurvey, setShowSurvey] = useState(false);
  const [, setShowExportExcel] = useState(false);
  const [requiredDataSurvey, setrequiredDataSurvey] = useState({
    groupQuestion: 5,
    initialDate: "",
    finalDate: "",
  });
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 0,
  });
  const [, setInfoExportExcel] = useState({
    groupQuestion: 5,
    initialDate: "",
    finalDate: "",
  });
  const [dataSurveys, setDataSurveys] = useState([]);
  const [headersTable, setHeadersTable] = useState([]);
  const [surveyUpdate, setSurveyUpdate] = useState([]);

  const onChangeRequiredDataSurvey = (e) => {
    const { name, value } = e.target;
    setrequiredDataSurvey({
      ...requiredDataSurvey,
      [name]: value,
    });
  };

  const validityForm = (e, page) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      e.preventDefault();
      querySurveys(page);
    }
  };

  const querySurveys = async (page) => {
    try {
      const { groupQuestion, initialDate, finalDate } = requiredDataSurvey;
      const { data } = await axios.get(
        process.env.REACT_APP_SERVIDOR +
          `/api/v2/list/surveys?groupQuestion=${groupQuestion}&initialDate=${initialDate}&finalDate=${finalDate}&page=${
            page || pagination.currentPage
          }`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      const headersTableAux = [];
      for (let key in data.data[0]) {
        headersTableAux.push(key);
      }

      setHeadersTable(headersTableAux);
      setDataSurveys(data.data);
      setPagination({
        currentPage: page || pagination.currentPage,
        totalPages: data.pages,
      });
      setValidated(false);
    } catch (error) {
      console.log("Error query survays: " + error.message);
    }
  };

  const queryUpdateSurvey = async (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setSurveyValidated(true);
    } else {
      e.preventDefault();
      try {
        const { data } = await axios.put(
          process.env.REACT_APP_SERVIDOR + "/api/v2/update/survey",
          surveyUpdate,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
              Permission: "1",
            },
          }
        );

        console.log(pagination.currentPage);
        if (data.message === "success") {
          setShowSurvey(false);
          setSurveyValidated(false);
          querySurveys(pagination.currentPage);
        }
      } catch (error) {
        console.log("Error updated survey: " + error.message);
      }
    }
  };

  const queryExportExcel = async (e) => {
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_SERVIDOR +
          `/api/v2/dowload/excel/survey?initialDate=${requiredDataSurvey.initialDate} 00:00:00&finalDate=${requiredDataSurvey.finalDate} 23:59:59&groupQuestion=${requiredDataSurvey.groupQuestion}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

      const ws = XLSX.utils.json_to_sheet(data);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { booktype: "xlsx", type: "array" });
      const file = new Blob([excelBuffer], { type: fileType });
      saveFile(
        file,
        `Encuesta_fecha_consulta${moment().format(
          "DD-MM-YYYY"
        )}_${moment().unix()}.xlsx`
      );
      setShowExportExcel(false);
      setInfoExportExcel({
        groupQuestion: 5,
        initialDate: "",
        finalDate: "",
      });
    } catch (error) {
      console.log("Error exporting Excel file: " + error.message);
    }
  };

  const onChangeSurvey = (e) => {
    const { name, value } = e.target;
    setSurveyUpdate({
      ...surveyUpdate,
      [name]: value,
    });
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:child td, &:child th": {
      border: 1,
    },
  }));

  const ModalUpdateSurvey = (
    <Modal
      show={showSurvey}
      onHide={() => setShowSurvey(false)}
      dialogClassName="modal-80w"
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header closeButton>
        <Modal.Title>Encuesta</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          id="formularioSurvey"
          noValidate
          validated={surveyValidated}
          onSubmit={queryUpdateSurvey}
        >
          <Row lg={3} md={3} sm={2} xs={2}>
            {headersTable.map((question, i) =>
              i === 0 ? null : (
                <Col key={i} className="mb-2">
                  <Form.Group>
                    <Form.Label>
                      <div
                        className="text-truncate col-12"
                        style={{ maxWidth: "150px" }}
                      >
                        {question}
                      </div>
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    name={question}
                    placeholder="Question"
                    value={surveyUpdate[question]}
                    onChange={onChangeSurvey}
                    required
                  />
                </Col>
              )
            )}
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button form="formularioSurvey" variant="dark" type="submit">
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <Container className="mt-3 text-center">
      <Row className="mb-3">
        <Col>
          <h2>ENCUESTA ESV</h2>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs="10">
          <Form noValidate validated={validated} onSubmit={validityForm}>
            <Row className="align-items-center justify-content-center">
              <Col xs="auto">
                <Form.Label>Rango de Fechas</Form.Label>
              </Col>
              <Col xs="auto">
                <Form.Control
                  type="date"
                  name="initialDate"
                  onChange={onChangeRequiredDataSurvey}
                />
              </Col>
              <Col xs="auto">
                <Form.Control
                  type="date"
                  name="finalDate"
                  onChange={onChangeRequiredDataSurvey}
                />
              </Col>
              <Col xs="auto">
                <Button variant="dark" type="submit">
                  Consultar
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col xs="2">
          <Button
            variant="dark"
            onClick={() => (window.location.href = "/preguntas/esv")}
          >
            ENCUESTA DE SEGURIDAD VIAL
          </Button>
        </Col>
      </Row>
      {dataSurveys.length > 0 ? (
        <Row className="text-center mb-3">
          <Col xs="10">
            <Form.Select
              style={{ width: "100px" }}
              value={pagination.currentPage}
              onChange={(e) => {
                setPagination({ ...pagination, currentPage: e.target.value });
                validityForm(e, e.target.value);
              }}
            >
              {Array.from({
                length: pagination.totalPages,
              }).map((item, i) => (
                <option key={i} value={i + 1}>
                  {i + 1}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col xs="2" className="text-end">
            <Button variant="success" onClick={() => queryExportExcel()}>
              Exportar Excel
            </Button>
          </Col>
        </Row>
      ) : null}
      <Row className="mb-3">
        <Paper
          elevation={3}
          sx={{ width: "100%", overflow: "hidden", padding: "1%" }}
        >
          <TableContainer sx={{ maxHeight: 500 }}>
            <Table stickyHeader aria-label="table-sticky">
              <TableHead>
                <TableRow>
                  {headersTable.map((header, index) =>
                    index < 3 ? null : (
                      <StyledTableCell key={index}>
                        <div
                          className="text-truncate col-12"
                          style={{ maxWidth: "150px" }}
                        >
                          {header}
                        </div>
                      </StyledTableCell>
                    )
                  )}
                  <StyledTableCell>Acción</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataSurveys.map((survey, index) => {
                  return (
                    <StyledTableRow key={index}>
                      {headersTable.map((header, i) =>
                        i < 3 ? null : survey[header] === "Rojo" ? (
                          <TableCell key={i} style={{ background: "red" }}>
                            {survey[header]}
                          </TableCell>
                        ) : survey[header] === "Verde" ? (
                          <TableCell key={i} style={{ background: "green" }}>
                            {survey[header]}
                          </TableCell>
                        ) : survey[header] === "Negro" ? (
                          <TableCell key={i} style={{ background: "black" }}>
                            {survey[header]}
                          </TableCell>
                        ) : (
                          <TableCell key={i}>{survey[header]}</TableCell>
                        )
                      )}
                      <TableCell>
                        <Button
                          variant="warning"
                          onClick={() => {
                            setShowSurvey(true);
                            setSurveyUpdate(survey);
                          }}
                        >
                          Modificar
                        </Button>
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Row>
      {dataSurveys.length > 0 ? (
        <Row className="text-start mb-3 justify-content-start">
          <Col xs="2">
            <Form.Select
              style={{ width: "100px" }}
              value={pagination.currentPage}
              onChange={(e) => {
                validityForm(e, e.target.value);
              }}
            >
              {Array.from({
                length: pagination.totalPages,
              }).map((item, i) => (
                <option key={i} value={i + 1}>
                  {i + 1}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>
      ) : null}
      {ModalUpdateSurvey}
    </Container>
  );
}

export default EncuestaEsv;
