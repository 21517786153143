function Button({ children, onClick }) {
  return (
    <button
      onClick={onClick}
      style={{
        padding: "0.5rem",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        backgroundColor: "#212529",
        color: "#fff",
        border: "none",
        borderRadius: "0.5rem",
        cursor: "pointer",
        width: "100%",
        transition: "background-color 0.3s, transform 0.2s",
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.backgroundColor = "#343a40";
        e.currentTarget.style.transform = "scale(1.05)";
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.backgroundColor = "#212529";
        e.currentTarget.style.transform = "scale(1)";
      }}
    >
      {children}
    </button>
  );
}

export default Button;
