import Swal from "sweetalert2";
import { useFetch } from "../../../hooks/useFetch";
import { updateDocumentSafeCarService } from "../services/updateDocumentSafeCar.service";
import { deleteDocumentSafeCarService } from "../services/deleteDocumentSafeCar.service";

export const useDocumentsSafeCarsCommand = (refresh) => {
  const fetchData = useFetch();

  const updateDocument = async ({ documentName, file }) => {
    try {
      const response = await updateDocumentSafeCarService(fetchData)({
        documentName,
        file,
      });
      refresh?.();
      Swal.fire({
        icon: "success",
        title: "Documento actualizado",
        text: "El documento fue actualizado correctamente",
      });
      console.log("response", response);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ocurrió un error al actualizar el documento",
      });
    }
  };

  const deleteDocument = async (document) => {
    try {
      const response = await deleteDocumentSafeCarService(fetchData)({
        document,
      });
      refresh?.();
      console.log("response", response);

      if (response.message === "no exist") {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "El documento no existe",
        });
        return;
      }

      Swal.fire({
        icon: "success",
        title: "Documento eliminado",
        text: "El documento fue eliminado correctamente",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ocurrió un error al eliminar el documento",
      });
    }
  };

  return {
    updateDocument,
    deleteDocument,
  };
};
