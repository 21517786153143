import { Container, Row, Col, Table, Button, Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";
import axios from "axios";
import { saveFile } from "../../common/utils/saveFile.util";
const moment = require("moment/moment");

function Ficha() {
  const cookie = new Cookies();
  const access_token = cookie.get("perv_v2_cookies_user_kdfoeinapjdkfawep");
  const tipo_vehiculo = cookie.get("tipo_vehiculo");
  const compania_user = cookie.get("compania_user");

  const [validated, setValidated] = useState(false);
  const [validacionAccion] = useState(false);

  const [vehiculo, setVehiculo] = useState([]);
  const [rutina, setRutina] = useState([]);
  const [conducto, setConducto] = useState([]);
  const [datosRutina, setDatosRuntina] = useState([]);

  const [validacionRutina, setValidacionRutina] = useState(false);

  const [datos, setDatos] = useState({
    Vehiculo: "",
    Kilometraje: "",
    Conductor: "",
    OrdenServicio: "",
    Rutina: "",
    Notas: "",
  });
  const ConsultarVehiculos = async () => {
    const { data } = await axios.post(
      process.env.REACT_APP_SERVIDOR +
        "/api/v2/mantenimiento/consultar/vehiculos",
      { tipo: tipo_vehiculo },
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    setVehiculo(data.datos);
  };

  const ConsultarRutina = async () => {
    const { data } = await axios.post(
      process.env.REACT_APP_SERVIDOR + "/api/v2/listar/rutinas",
      { tipo: tipo_vehiculo },
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );

    if (data.estado) {
      setRutina(data.datos);
    } else {
      Swal.fire({
        icon: "warning",
        title: "Mensaje",
        text: "No existen rutinas registradas",
        showConfirmButton: false,
        footer:
          '<a href="/gestion-mantenimiento/consultar-rutinas">REGISTRAR</a>',
      });
    }
  };
  const ConsultarConductor = async () => {
    const { data } = await axios.post(
      process.env.REACT_APP_SERVIDOR + "/api/v2/list/mantenimiento/conductores",
      { tipo: tipo_vehiculo },
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    setConducto(data);
  };
  const MostrarTablaRutina = async (e) => {
    onChangeInfo(e);

    setValidacionRutina(false);
    setDatosRuntina([]);

    let rutina = e.target.value;

    const { data } = await axios.post(
      process.env.REACT_APP_SERVIDOR +
        "/api/v2/mantenimiento/consultar_runtinas",
      { id_rutina: rutina, tipo: tipo_vehiculo },
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );

    if (data.estado) {
      setDatosRuntina(data.datos);
      setValidacionRutina(true);
    } else {
      setValidacionRutina(false);
      setDatosRuntina([]);
    }
  };
  const validityForm = async (e) => {
    try {
      const form = e.currentTarget;

      if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
        setValidated(true);
      } else {
        e.preventDefault();

        if (!validacionAccion) {
          const { data } = await axios.post(
            process.env.REACT_APP_SERVIDOR +
              "/api/v2/list/mantenimiento/fichaMantenimiento",
            {
              id_rutina: datos.Rutina,
              tipo: tipo_vehiculo,
              compania: compania_user,
              datos: datos,
            },
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
                Permission: "1",
              },
              responseType: "arraybuffer",
            }
          );
          const blob = new Blob([data], {
            type: "application/pdf;charset=utf8",
          });
          saveFile(
            blob,
            `Ficha de Mantenimiento del Vehiculo ${moment().unix()}.pdf`
          );
        }
      }
    } catch (error) {
      if (error.response.status === 403) {
        Swal.fire({
          icon: "error",
          title: "No tienes permiso para esta acción",
        });
      }
    }
  };
  const onChangeInfo = (e) => {
    const { name, value } = e.target;
    setDatos({
      ...datos,
      [name]: value,
    });
  };
  useEffect(() => {
    ConsultarRutina();
    ConsultarVehiculos();
    ConsultarConductor();
  }, []);
  return (
    <>
      <Container className="mt-3 text-center">
        <h1>Generar Ficha de Mantenimiento</h1>
        <br />
        <Row className="mb-3">
          <Form
            noValidate
            validated={validated}
            onSubmit={(e) => validityForm(e)}
          >
            <Row className="mb-1 align-items-center justify-content-center">
              <Col xs="2">
                <Form.Label>Vehículo:</Form.Label>
              </Col>
              <Col xs="3">
                <Form.Group>
                  <Form.Select
                    name="Vehiculo"
                    value={datos.Vehiculo}
                    onChange={onChangeInfo}
                  >
                    <option value="">Seleccione...</option>
                    {vehiculo.map((fila, id) => (
                      <option key={id} value={fila.placa}>
                        {fila.placa}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Obligatorio
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs="2">
                <Form.Label>Kilometraje:</Form.Label>
              </Col>
              <Col xs="3">
                <Form.Group>
                  <Form.Control
                    required
                    name="Kilometraje"
                    type="number"
                    value={datos.Kilometraje}
                    onChange={onChangeInfo}
                  />
                  <Form.Control.Feedback type="invalid">
                    {" "}
                    Obligatorio{" "}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <br />
            <Row className="mb-1 align-items-center justify-content-center">
              <Col xs="2">
                <Form.Label>Conductor:</Form.Label>
              </Col>
              <Col xs="3">
                <Form.Group>
                  <Form.Select
                    required
                    name="Conductor"
                    value={datos.Conductor}
                    onChange={onChangeInfo}
                  >
                    <option value="">Seleccione...</option>
                    {conducto.map((fila, id) => (
                      <option key={id} value={fila.nombre_conductor}>
                        {fila.nombre_conductor}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Obligatorio
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs="2">
                <Form.Label>Orden de Servicio:</Form.Label>
              </Col>
              <Col xs="3">
                <Form.Group>
                  <Form.Control
                    required
                    name="OrdenServicio"
                    type="number"
                    value={datos.OrdenServicio}
                    onChange={onChangeInfo}
                  />
                  <Form.Control.Feedback type="invalid">
                    {" "}
                    Obligatorio{" "}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-1 align-items-center justify-content-center">
              <Col xs="2">
                <Form.Label>Rutina:</Form.Label>
              </Col>
              <Col xs="3">
                <Form.Group>
                  <Form.Select
                    required
                    name="Rutina"
                    value={datos.Rutina}
                    onChange={(e) => MostrarTablaRutina(e)}
                  >
                    <option value="">Seleccione...</option>
                    {rutina.map((fila, id) => (
                      <option key={id} value={fila.rutina}>
                        {fila.rutina}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Obligatorio
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs="2">
                <Form.Label>Notas o Adicionales:</Form.Label>
              </Col>
              <Col xs="3">
                <Form.Group>
                  <Form.Control
                    required
                    name="Notas"
                    type="textarea"
                    value={datos.Notas}
                    onChange={onChangeInfo}
                    style={{ minHeight: "150px" }}
                    maxLength={250}
                  />
                  <Form.Control.Feedback type="invalid">
                    {" "}
                    Obligatorio{" "}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <br />
            <Row className="mb-1 align-items-center justify-content-center">
              <Col xs="2">
                <Button type="submit" variant="dark">
                  Descargar
                </Button>
              </Col>
            </Row>
            <br />
          </Form>
        </Row>
      </Container>
      <br />
      {validacionRutina ? (
        <Container className="text-center mt-3">
          <Row className="mb-1 align-items-center justify-content-center">
            <Col xs="12">
              <Table stickyHeader aria-label="table-sticky">
                <thead>
                  <tr>
                    <th>Rutina</th>
                    <th>Kilometraje</th>
                    <th>Tipo</th>
                    <th>Operaciones</th>
                    <th>Actividad</th>
                  </tr>
                </thead>
                <tbody>
                  {datosRutina.map((fila, id) => (
                    <tr key={id}>
                      <td>{fila.rutina}</td>
                      <td>{fila.kilometraje}</td>
                      <td>{fila.tipo}</td>
                      <td>{fila.operaciones}</td>
                      <td>{fila.actividad}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container></Container>
      )}
    </>
  );
}
export default Ficha;
