export const uploadClaimsCostsService =
  (fetchData) =>
  async ({ file }) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await fetchData({
        method: "post",
        url: "/api/v1/siniestros-viales/documentos/costos-siniestros-juridicos",
        body: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || error.message);
    }
  };
