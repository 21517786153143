import axios from "axios";
import Cookies from "universal-cookie";

export const useFetch = () => {
  const BASE_URL = process.env.REACT_APP_SERVIDOR;
  const ACCESS_TOKEN = new Cookies().get(
    "perv_v2_cookies_user_kdfoeinapjdkfawep"
  );

  const fetchData = async ({
    url,
    method = "get",
    body,
    permission,
    contentType = "application/json",
  }) => {
    try {
      const config = {
        url: `${BASE_URL}${url}`,
        method,
        data: body,
        headers: {
          Authorization: `Bearer ${ACCESS_TOKEN}`,
          "Content-Type": contentType,
        },
      };

      if (permission) {
        config.headers.Permission = permission;
      }

      const response = await axios(config);

      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return fetchData;
};
