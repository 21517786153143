import { useEffect, useState } from "react";
import { useFetch } from "../../../hooks/useFetch";
import { listDocumentsSafeCarsService } from "../services/listDocumentsSafeCars.service";

export const useDocumentsSafeCarsQuery = () => {
  const fetchData = useFetch();

  const [documentsSafeCars, setDocumentsSafeCars] = useState({});
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const fetchDocumentsSafeCars = async () => {
      try {
        setLoading(true);
        const response = await listDocumentsSafeCarsService(fetchData)();
        setDocumentsSafeCars(response[0]);
      } catch (error) {
        console.log(
          "Error al obtener los documentos de vehiculos seguros",
          error
        );
      } finally {
        setLoading(false);
      }
    };

    fetchDocumentsSafeCars();
  }, [refresh]);

  return { documentsSafeCars, loading, refresh: () => setRefresh(!refresh) };
};
