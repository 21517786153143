import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.min.css";

// import 'bootstrap/tables/plugins/node-waves/waves.css';
// import "bootstrap/tables/plugins/animate-css/animate.css";
// import "bootstrap/tables/plugins/jquery-datatable/skin/bootstrap/css/dataTables.bootstrap.css";
// import "bootstrap/tables/css/themes/all-themes.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
/*

import 'bootstrap/tables/plugins/node-waves/waves.js';
import 'bootstrap/tables/plugins/jquery-datatable/jquery.dataTables.js';
import 'bootstrap/tables/plugins/jquery-datatable/skin/bootstrap/js/dataTables.bootstrap.js';
import 'bootstrap/tables/plugins/jquery-datatable/extensions/export/dataTables.buttons.min.js';
import 'bootstrap/tables/plugins/jquery-datatable/extensions/export/buttons.flash.min.js';
import 'bootstrap/tables/plugins/jquery-datatable/extensions/export/jszip.min.js';
import 'bootstrap/tables/plugins/jquery-datatable/extensions/export/pdfmake.min.js';
import 'bootstrap/tables/plugins/jquery-datatable/extensions/export/vfs_fonts.js';
import 'bootstrap/tables/plugins/jquery-datatable/extensions/export/buttons.html5.min.js';
import 'bootstrap/tables/plugins/jquery-datatable/extensions/export/buttons.print.min.js';

import 'bootstrap/tables/js/pages/tables/jquery-datatable.js';
*/

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);
