import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Modal,
  Row,
  Form,
  ButtonGroup,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import { RiFileEditFill } from "react-icons/ri";
import { BsFillTrashFill } from "react-icons/bs";
import XLSX from "sheetjs-style";
import moment from "moment";
import { saveFile } from "../common/utils/saveFile.util";

function CECDR() {
  const access_token = new Cookies().get(
    "perv_v2_cookies_user_kdfoeinapjdkfawep"
  );
  const [action, setAction] = useState(true);
  const [id_control, setIdControl] = useState("");
  const [dataControlRiesgos, seTableCellataControlRiesgos] = useState([]);
  const [bodyControlRiesgos, setBodyControlRiesgos] = useState({
    proceso_area: "",
    zona_lugar: "",
    cargos: "",
    actividad_tarea: "",
    rutinario: "",
    clasificacion: "",
    descripcion: "",
    fuente_generadora: "",
    efectos_posibles: "",
    fuente: "",
    medio: "",
    individuo: "",
    nivel_exposicion: "",
    nivel_probabilidad: "",
    nivel_deficiencia: "",
    interpretacion_nivel_probabilidad: "",
    numero_expuesto: "",
    tiempo_exposicion: "",
    peor_consecuencia: "",
    existencia_req_legal: "",
    eliminacion: "",
    sustitucion: "",
    controles_ingenieria: "",
    controles_administrativos: "",
    equipos: "",
    observaciones: "",
  });

  const [show, setShow] = useState(false);
  const [showExcel, setShowExcel] = useState(false);
  const [validated, setValidated] = useState(false);
  const [showModalInsert, setShowModalInsert] = useState(false);
  const [data, setData] = useState([]);
  const [, setShowModalFile] = useState(false);
  const onChangeControlRiesgos = (e) => {
    const { name, value } = e.target;
    if (
      name === "nivel_deficiencia" ||
      name === "nivel_exposicion" ||
      name === "nivel_probabilidad"
    ) {
      calcularNivelProbabilidad(name, value);
    } else {
      setBodyControlRiesgos({
        ...bodyControlRiesgos,
        [name]: value,
      });
    }
  };

  function calcularNivelProbabilidad(name, value) {
    let objAuxiliar = {
      nivel_probabilidad: bodyControlRiesgos.nivel_probabilidad,
      interpretacion_nivel_probabilidad:
        bodyControlRiesgos.interpretacion_nivel_probabilidad,
      nivel_deficiencia: bodyControlRiesgos.nivel_deficiencia,
      nivel_exposicion: bodyControlRiesgos.nivel_exposicion,
      interpretacion_nr: bodyControlRiesgos.nivel_consecuencia,
      nivel_consecuencia: bodyControlRiesgos.nivel_consecuencia,
    };

    objAuxiliar[name] = value;

    if (
      objAuxiliar.nivel_exposicion !== "" &&
      objAuxiliar.nivel_probabilidad !== ""
    ) {
      objAuxiliar.nivel_deficiencia =
        objAuxiliar.nivel_probabilidad * objAuxiliar.nivel_exposicion;

      switch (true) {
        case objAuxiliar.nivel_deficiencia <= 2:
          objAuxiliar.interpretacion_nivel_probabilidad = "Bajo";
          break;

        case objAuxiliar.nivel_deficiencia <= 4:
          objAuxiliar.interpretacion_nivel_probabilidad = "Moderado";
          break;

        case objAuxiliar.nivel_deficiencia <= 9:
          objAuxiliar.interpretacion_nivel_probabilidad = "Critico";
          break;

        default:
          /* objAuxiliar.interpretacion_nivel_probabilidad = 'Muy Alto'; */
          break;
      }
    }
    setBodyControlRiesgos({
      ...bodyControlRiesgos,
      ...objAuxiliar,
    });
  }
  const handleCloseModalInsert = () => {
    setShowModalInsert(false);
  };
  const modalInsert = (
    <Modal
      show={showModalInsert}
      onHide={handleCloseModalInsert}
      backdrop="static"
    >
      <Modal.Body style={{ margin: "2%" }}>
        <Container>
          <Row>
            <Col xs={3} md={3}>
              PROCEDIMIENTO
            </Col>
            <Col xs={7} md={7} style={{ color: "#f58b84" }}>
              {data === undefined ||
              data.nombre_documentacion === "" ||
              data.nombre_documentacion === null ? (
                "NO HAY INFORME"
              ) : (
                <a
                  target={"new"}
                  href={
                    process.env.REACT_APP_HOST_FTP +
                    "pdf_pesv/" +
                    data.nombre_documentacion
                  }
                >
                  INFORME
                </a>
              )}
            </Col>
            <Col xs={1} md={1}>
              <label htmlFor="btn-file-pdf">
                <RiFileEditFill
                  aria-label="upload pdf"
                  onClick={() => {
                    setShowModalFile(true);
                  }}
                />
              </label>
            </Col>
            <Col xs={1} md={1}>
              <BsFillTrashFill onClick={() => queryDeleteFileFtp()} />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModalInsert}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const queryData = async () => {
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_SERVIDOR +
          "/api/v2/list/formato_archivos_compania_caracterizacion",
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      console.log("Paso");
      setData(data[0]);
      console.log(data[0]);
    } catch (error) {
      console.log("Error while fetching data from server: " + error.message);
    }
  };

  const queryDeleteFileFtp = async () => {
    try {
      let pagina = { nombre: "Inspeccion_Caracterizacion_Evaluacion" };

      const { data } = await axios.post(
        process.env.REACT_APP_SERVIDOR +
          `/api/v2/files/delete/formato_archivos_compania_caracterizacion/`,
        pagina,
        {
          headers: {
            Permission: "1",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (data.message === "success") {
        queryData();
      }
    } catch (error) {
      if (error.response.status === 403) {
        Swal.fire({
          icon: "error",
          title: "No tienes permiso para esta acción",
        });
      }
      console.log("Error while deleting file from ftp: " + error.message);
    }
  };

  const queryControlRiesgos = async () => {
    try {
      const access_token = new Cookies().get(
        "perv_v2_cookies_user_kdfoeinapjdkfawep"
      );
      const { data } = await axios.get(
        process.env.REACT_APP_SERVIDOR + "/api/v2/list/control/riesgo",
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      seTableCellataControlRiesgos(data);
    } catch (error) {
      console.log(error);
    }
  };

  const queryInsertControlRiesgo = async (e) => {
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      e.preventDefault();
      console.log(bodyControlRiesgos);
      try {
        const { data } = await axios.post(
          process.env.REACT_APP_SERVIDOR + "/api/v2/insert/control/riesgo",
          bodyControlRiesgos,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
              Permission: "1",
            },
          }
        );
        if (data.message === "success") {
          setBodyControlRiesgos({
            proceso_area: "",
            zona_lugar: "",
            cargos: "",
            actividad_tarea: "",
            rutinario: "",
            clasificacion: "",
            descripcion: "",
            fuente_generadora: "",
            efectos_posibles: "",
            fuente: "",
            medio: "",
            individuo: "",
            nivel_exposicion: "",
            nivel_probabilidad: "",
            nivel_deficiencia: "",
            interpretacion_nivel_probabilidad: "",
            numero_expuesto: "",
            tiempo_exposicion: "",
            peor_consecuencia: "",
            existencia_req_legal: "",
            eliminacion: "",
            sustitucion: "",
            controles_ingenieria: "",
            controles_administrativos: "",
            equipos: "",
            observaciones: "",
          });
          queryControlRiesgos();
          setValidated(false);
        }
      } catch (error) {
        if (error.response.status === 403) {
          Swal.fire({
            icon: "error",
            title: "No tienes permisos para esta acción",
          });
        }
      }
    }
  };

  const queryUpdateControlRiesgo = async (e) => {
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.prevenDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      e.preventDefault();
      try {
        const { data } = await axios.put(
          process.env.REACT_APP_SERVIDOR +
            `/api/v2/update/control/riesgo/${id_control}`,
          bodyControlRiesgos,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
              Permission: "1",
            },
          }
        );

        if (data.message === "success") {
          setBodyControlRiesgos({
            proceso_area: "",
            zona_lugar: "",
            cargos: "",
            actividad_tarea: "",
            rutinario: "",
            clasificacion: "",
            descripcion: "",
            fuente_generadora: "",
            efectos_posibles: "",
            fuente: "",
            medio: "",
            individuo: "",
            nivel_exposicion: "",
            nivel_probabilidad: "",
            nivel_deficiencia: "",
            interpretacion_nivel_probabilidad: "",
            numero_expuesto: "",
            tiempo_exposicion: "",
            peor_consecuencia: "",
            existencia_req_legal: "",
            eliminacion: "",
            sustitucion: "",
            controles_ingenieria: "",
            controles_administrativos: "",
            equipos: "",
            observaciones: "",
          });
          queryControlRiesgos();
          setValidated(false);
          setShow(false);
          setIdControl("");
        }
      } catch (error) {
        console.log("Error updating data control riesgo", error);
      }
    }
  };

  const modalInsert1 = (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      dialogClassName="modal-80w"
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header closeButton>
        <Modal.Title>Formulario</Modal.Title>
      </Modal.Header>
      <Modal.Body
        className="p-0 m-4"
        style={{ maxHeight: "500px", overflowY: "scroll", overflowX: "hidden" }}
      >
        <Form
          noValidate
          validated={validated}
          onSubmit={
            action ? queryInsertControlRiesgo : queryUpdateControlRiesgo
          }
        >
          <Row className="mb-2">
            <Form.Group as={Col} md="4">
              <Form.Label>Proceso/Área</Form.Label>
              <Form.Select
                required
                name="proceso_area"
                value={bodyControlRiesgos.proceso_area}
                onChange={onChangeControlRiesgos}
              >
                <option value="">Seleccione...</option>
                <option>ADMINISTRATIVO</option>
                <option>COMERCIAL</option>
                <option>LOGISTICA</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Obligatorio
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="4">
              <Form.Label>Zona/Lugar</Form.Label>
              <Form.Select
                required
                name="zona_lugar"
                value={bodyControlRiesgos.zona_lugar}
                onChange={onChangeControlRiesgos}
              >
                <option value="">Seleccione...</option>
                <option>RUTA INTERNA</option>
                <option>VIA PUBLICA</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Obligatorio
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="4">
              <Form.Label>Cargo</Form.Label>
              <Form.Control
                required
                name="cargos"
                type="text"
                placeholder="Cargos"
                value={bodyControlRiesgos.cargos}
                onChange={onChangeControlRiesgos}
              />
              <Form.Control.Feedback type="invalid">
                Obligatorio
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-2">
            <Form.Group as={Col} md="4">
              <Form.Label>Actividades/Tareas</Form.Label>
              <Form.Control
                as="textarea"
                required
                name="actividad_tarea"
                placeholder="Actividades/Tareas"
                value={bodyControlRiesgos.actividad_tarea}
                onChange={onChangeControlRiesgos}
                style={{ maxHeight: "100px" }}
              />
              <Form.Control.Feedback type="invalid">
                Obligatorio
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4">
              <Form.Label>Rutinario</Form.Label>
              <Form.Select
                required
                name="rutinario"
                value={bodyControlRiesgos.rutinario}
                onChange={onChangeControlRiesgos}
              >
                <option value="">Seleccione...</option>
                <option>SI</option>
                <option>NO</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Obligatorio
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="4">
              <Form.Label>Clasificación</Form.Label>
              <Form.Control
                required
                name="clasificacion"
                type="text"
                placeholder="Clasificación"
                value={bodyControlRiesgos.clasificacion}
                onChange={onChangeControlRiesgos}
              />
              <Form.Control.Feedback type="invalid">
                Obligatorio
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-2">
            <Form.Group as={Col} md="4">
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                as="textarea"
                required
                name="descripcion"
                placeholder="Descripción"
                value={bodyControlRiesgos.descripcion}
                onChange={onChangeControlRiesgos}
                style={{ maxHeight: "100px" }}
              />
              <Form.Control.Feedback type="invalid">
                Obligatorio
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4">
              <Form.Label>Fuente Generadora</Form.Label>
              <Form.Control
                required
                name="fuente_generadora"
                type="text"
                placeholder="Fuente Generadora"
                value={bodyControlRiesgos.fuente_generadora}
                onChange={onChangeControlRiesgos}
              />
              <Form.Control.Feedback type="invalid">
                Obligatorio
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="4">
              <Form.Label>Efectos Posibles</Form.Label>
              <Form.Control
                as="textarea"
                required
                name="efectos_posibles"
                placeholder="Efectos Posibles"
                value={bodyControlRiesgos.efectos_posibles}
                onChange={onChangeControlRiesgos}
                style={{ maxHeight: "100px" }}
              />
              <Form.Control.Feedback type="invalid">
                Obligatorio
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-2">
            <Form.Group as={Col} md="4">
              <Form.Label>Fuente</Form.Label>
              <Form.Control
                required
                name="fuente"
                type="text"
                placeholder="Fuente"
                value={bodyControlRiesgos.fuente}
                onChange={onChangeControlRiesgos}
              />
              <Form.Control.Feedback type="invalid">
                Obligatorio
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4">
              <Form.Label>Medio</Form.Label>
              <Form.Control
                as="textarea"
                required
                name="medio"
                placeholder="Medio"
                value={bodyControlRiesgos.medio}
                onChange={onChangeControlRiesgos}
                style={{ maxHeight: "100px" }}
              />
              <Form.Control.Feedback type="invalid">
                Obligatorio
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="4">
              <Form.Label>Individuo</Form.Label>
              <Form.Control
                required
                name="individuo"
                type="text"
                placeholder="Individuo"
                value={bodyControlRiesgos.individuo}
                onChange={onChangeControlRiesgos}
              />
              <Form.Control.Feedback type="invalid">
                Obligatorio
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-2">
            <Form.Group as={Col} md="4">
              <Form.Label>Nivel Exposición</Form.Label>
              <Form.Select
                required
                name="nivel_exposicion"
                placeholder="Nivel Exposicion"
                value={bodyControlRiesgos.nivel_exposicion}
                onChange={onChangeControlRiesgos}
              >
                <option value="">Seleccione...</option>
                <option>3</option>
                <option>2</option>
                <option>1</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Obligatorio
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4">
              <Form.Label>Nivel Probabilidad</Form.Label>
              <Form.Select
                required
                name="nivel_probabilidad"
                placeholder="Nivel Probabilidad"
                value={bodyControlRiesgos.nivel_probabilidad}
                onChange={onChangeControlRiesgos}
              >
                <option value="">Seleccione...</option>
                <option>3</option>
                <option>2</option>
                <option>1</option>
              </Form.Select>

              <Form.Control.Feedback type="invalid">
                Obligatorio
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4">
              <Form.Label>Nivel Deficiencia</Form.Label>
              <Form.Control
                required
                name="nivel_deficiencia"
                type="text"
                placeholder="Nivel Deficiencia"
                value={bodyControlRiesgos.nivel_deficiencia}
                onChange={onChangeControlRiesgos}
                readOnly="true"
              />
              <Form.Control.Feedback type="invalid">
                Obligatorio
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-2">
            <Form.Group as={Col} md="4">
              <Form.Label>Interpretación Nivel Probabilidad</Form.Label>
              <Form.Control
                required
                name="interpretacion_nivel_probabilidad"
                type="text"
                placeholder="Interpretación Nivel Probabilidad"
                value={bodyControlRiesgos.interpretacion_nivel_probabilidad}
                onChange={onChangeControlRiesgos}
                readOnly="true"
              />
              <Form.Control.Feedback type="invalid">
                Obligatorio
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4">
              <Form.Label>No. Expuestos</Form.Label>
              <Form.Control
                required
                name="numero_expuesto"
                type="number"
                placeholder="No. Expuestos"
                value={bodyControlRiesgos.numero_expuesto}
                onChange={onChangeControlRiesgos}
              />
              <Form.Control.Feedback type="invalid">
                Obligatorio
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4">
              <Form.Label>
                Tiempo de Exposición. Horas (efectivas)/día
              </Form.Label>
              <Form.Control
                required
                name="tiempo_exposicion"
                type="number"
                placeholder="Tiempo de Exposición. Horas (efectivas)/día"
                value={bodyControlRiesgos.tiempo_exposicion}
                onChange={onChangeControlRiesgos}
              />
              <Form.Control.Feedback type="invalid">
                Obligatorio
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-2">
            <Form.Group as={Col} md="4">
              <Form.Label>Peor Consecuencia</Form.Label>
              <br />
              <Form.Control
                required
                name="peor_consecuencia"
                type="text"
                placeholder="Peor Consecuencia"
                value={bodyControlRiesgos.peor_consecuencia}
                onChange={onChangeControlRiesgos}
                style={{ marginTop: "7%" }}
              />
              <Form.Control.Feedback type="invalid">
                Obligatorio
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4">
              <Form.Label>
                Existencia Requisito Legal Especifico Asociado
              </Form.Label>
              <Form.Select
                required
                name="existencia_req_legal"
                value={bodyControlRiesgos.existencia_req_legal}
                onChange={onChangeControlRiesgos}
                style={{ marginTop: "7%" }}
              >
                <option value="">Seleccione...</option>
                <option>SI</option>
                <option>NO</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Obligatorio
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4">
              <Form.Label>Eliminación</Form.Label>
              <br />
              <Form.Control
                required
                name="eliminacion"
                type="text"
                placeholder="Eliminación"
                value={bodyControlRiesgos.eliminacion}
                onChange={onChangeControlRiesgos}
                style={{ marginTop: "7%" }}
              />
              <Form.Control.Feedback type="invalid">
                Obligatorio
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-2">
            <Form.Group as={Col} md="4">
              <Form.Label>Sustitución</Form.Label>
              <Form.Control
                required
                name="sustitucion"
                type="text"
                placeholder="Sustitución"
                value={bodyControlRiesgos.sustitucion}
                onChange={onChangeControlRiesgos}
                style={{ marginTop: "7%", padding: "18px" }}
              />
              <Form.Control.Feedback type="invalid">
                Obligatorio
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4">
              <Form.Label>Controles Ingenieria</Form.Label>
              <Form.Control
                required
                name="controles_ingenieria"
                type="text"
                placeholder="Controles Ingenieria"
                value={bodyControlRiesgos.controles_ingenieria}
                onChange={onChangeControlRiesgos}
                style={{ marginTop: "7%", padding: "18px" }}
              />
              <Form.Control.Feedback type="invalid">
                Obligatorio
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4">
              <Form.Label>
                Controles Administrativos, Señalización, Advertencia
              </Form.Label>
              <Form.Control
                as="textarea"
                required
                name="controles_administrativos"
                placeholder="Controles Administrativos, Señalización, Advertencia"
                value={bodyControlRiesgos.controles_administrativos}
                onChange={onChangeControlRiesgos}
                style={{ maxHeight: "100px" }}
              />
              <Form.Control.Feedback type="invalid">
                Obligatorio
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-2">
            <Form.Group as={Col} md="4">
              <Form.Label>Equipo / EPP</Form.Label>
              <Form.Control
                as="textarea"
                required
                name="equipos"
                placeholder="Equipo / EPP"
                value={bodyControlRiesgos.equipos}
                onChange={onChangeControlRiesgos}
                style={{ maxHeight: "100px" }}
              />
              <Form.Control.Feedback type="invalid">
                Obligatorio
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4">
              <Form.Label>Observaciones</Form.Label>
              <Form.Control
                as="textarea"
                required
                name="observaciones"
                placeholder="Observaciones"
                value={bodyControlRiesgos.observaciones}
                onChange={onChangeControlRiesgos}
                style={{ maxHeight: "100px" }}
              />
              <Form.Control.Feedback type="invalid">
                Obligatorio
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <br />
          <Button variant="dark" type="submit">
            Guardar
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );

  const queryDataModify = async (id_control_riesgo) => {
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_SERVIDOR +
          `/api/v2/get/control/riesgo/${id_control_riesgo}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            Permission: "1",
          },
        }
      );

      setBodyControlRiesgos(data[0]);
      setShow(true);
    } catch (error) {
      if (error.response.status === 403) {
        Swal.fire({
          icon: "error",
          title: "No tienes permisos para esta acción",
        });
      }
      console.log("Error query modify data: " + error.message);
    }
  };

  const queryDataDelete = async (id_control_riesgo) => {
    try {
      const { data } = await axios.delete(
        process.env.REACT_APP_SERVIDOR +
          `/api/v2/get/control/riesgo/borrar/${id_control_riesgo}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            Permission: "1",
          },
        }
      );

      if (data.message === "success") {
        setBodyControlRiesgos({
          proceso_area: "",
          zona_lugar: "",
          cargos: "",
          actividad_tarea: "",
          rutinario: "",
          clasificacion: "",
          descripcion: "",
          fuente_generadora: "",
          efectos_posibles: "",
          fuente: "",
          medio: "",
          individuo: "",
          nivel_exposicion: "",
          nivel_probabilidad: "",
          nivel_deficiencia: "",
          interpretacion_nivel_probabilidad: "",
          numero_expuesto: "",
          tiempo_exposicion: "",
          peor_consecuencia: "",
          existencia_req_legal: "",
          eliminacion: "",
          sustitucion: "",
          controles_ingenieria: "",
          controles_administrativos: "",
          equipos: "",
          observaciones: "",
        });
        queryControlRiesgos();
        setValidated(false);
        setShow(false);
        setIdControl("");
      }
    } catch (error) {
      if (error.response.status === 403) {
        Swal.fire({
          icon: "error",
          title: "No tienes permisos para esta acción",
        });
      }
      console.log("Error query modify data: " + error.message);
    }
  };

  const paintRiesgo = (level) => {
    let color = "";
    if (level >= 1 && level <= 2) {
      color = "#ADE792";
    } else {
      if (level >= 3 && level <= 4) {
        color = "#FFC93C";
      } else {
        if (level >= 6 && level <= 9) {
          color = "#DC3535";
        }
      }
    }

    return color;
  };

  const queryExportExcel = async (e) => {
    try {
      let ojbDatos = dataControlRiesgos;
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

      for (let i = 0; i < ojbDatos.length; i++) {
        delete ojbDatos[i].id_control_riesgo;
        delete ojbDatos[i].id_compania_fk;
      }

      const ws = XLSX.utils.json_to_sheet(ojbDatos);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { booktype: "xlsx", type: "array" });
      const file = new Blob([excelBuffer], { type: fileType });
      const filename = `Matriz de riesgos ${moment().format(
        "DD-MM-YYYY"
      )}_${moment().unix()}.xlsx`;
      saveFile(file, filename);
    } catch (error) {
      console.log("Error exporting Excel file: " + error.message);
    }
  };

  /////////////////////////////////MATRIZ EXCEL //////////////////

  const querySubirMatriz = async (e) => {
    const target = e.target;

    const name = target.name;

    let hoja = [];
    if (name === "file") {
      let reader = new FileReader();
      reader.readAsArrayBuffer(target.files[0]);
      reader.onloadend = (e) => {
        let data = new Uint8Array(e.target.result);
        let workbook = XLSX.read(data, { type: "array" });
        workbook.SheetNames.forEach(function (sheetName) {
          let XL_row_object = XLSX.utils.sheet_to_row_object_array(
            workbook.Sheets[sheetName]
          );
          hoja.push({
            data: XL_row_object,
          });
        });
        //console.log(hoja[0].data)
        if (hoja[0].data.length > 0) {
          queryInsertExcel(hoja[0].data);
        }
      };
    }
  };

  const queryInsertExcel = async (objDatos) => {
    const { data } = await axios.post(
      process.env.REACT_APP_SERVIDOR + "/api/v2/insert/control/riesgoExcel",
      objDatos,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
          Permission: "1",
        },
      }
    );
    if (data.resultado) {
      setShowExcel(false);
      queryControlRiesgos();
    }
  };
  const modalInsertExcel = (
    <Modal show={showExcel} onHide={() => setShowExcel(false)}>
      <Modal.Header closeButton>
        <Modal.Title className="mx-1">Matriz de Riesgos</Modal.Title>
      </Modal.Header>
      <Modal.Body className="mx-1">
        <input
          type="file"
          name="file"
          id="file"
          onChange={querySubirMatriz}
          placeholder="Ingresar Matriz"
        />
      </Modal.Body>
    </Modal>
  );

  useEffect(() => {
    queryControlRiesgos();
  }, []);

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:child td, &:child th": {
      border: 1,
    },
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  return (
    <Container className="text-center mt-3 caracterizacion">
      <Row className="mb-3">
        <h3>CARACTERIZACIÓN, EVALUACIÓN Y CONTROL DE RIESGOS</h3>
      </Row>
      <Row className="text-start mb-3">
        <Col xs="3">
          <ButtonGroup style={{ paddingLeft: "10px" }}>
            <DropdownButton
              variant="success"
              as={ButtonGroup}
              title="Registrar"
              id="bg-nested-dropdown"
            >
              <Dropdown.Item
                eventKey="1"
                onClick={() => {
                  setShow(true);
                  setAction(true);
                }}
              >
                Manual
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="2"
                onClick={() => {
                  setShowExcel(true);
                }}
              >
                Excel
              </Dropdown.Item>
            </DropdownButton>
          </ButtonGroup>
          <ButtonGroup style={{ paddingLeft: "10px" }}>
            <DropdownButton
              variant="danger"
              as={ButtonGroup}
              title="Descargar"
              id="bg-nested-dropdown"
            >
              <Dropdown.Item
                eventKey="1"
                target={"_blanck"}
                href="https://ftppesv.datralabs.com/documentoCaracterizacion/INS-PESV-02-2023 MMS.pdf"
              >
                Instructivo
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="2"
                href="https://ftppesv.datralabs.com/documentoCaracterizacion/t102_control_riesgo.xlsx"
              >
                Formato
              </Dropdown.Item>
              <Dropdown.Item eventKey="3" onClick={() => queryExportExcel()}>
                Consolidado Actual
              </Dropdown.Item>
            </DropdownButton>
          </ButtonGroup>
        </Col>

        {modalInsertExcel}
        {modalInsert}
        {modalInsert1}
      </Row>
      <Row className="mb-12">
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Proceso/Área</StyledTableCell>
                  <StyledTableCell>Zona/Lugar</StyledTableCell>
                  <StyledTableCell>Cargos</StyledTableCell>
                  <StyledTableCell>Actividad/Tarea</StyledTableCell>
                  <StyledTableCell>Rutinario</StyledTableCell>
                  <StyledTableCell>Clasificación</StyledTableCell>
                  <StyledTableCell>Descripción</StyledTableCell>
                  <StyledTableCell>Fuente Generadora</StyledTableCell>
                  <StyledTableCell>Efectos Posibles</StyledTableCell>
                  <StyledTableCell>Fuente</StyledTableCell>
                  <StyledTableCell>Medio</StyledTableCell>
                  <StyledTableCell>Individuo</StyledTableCell>
                  <StyledTableCell>Nivel Exposición</StyledTableCell>
                  <StyledTableCell>Nivel Probabilidad</StyledTableCell>
                  <StyledTableCell>Nivel Deficiencia</StyledTableCell>
                  <StyledTableCell>Interpretación N.P.</StyledTableCell>
                  <StyledTableCell>Número Expuestos</StyledTableCell>
                  <StyledTableCell>Tiempo Expuestos</StyledTableCell>
                  <StyledTableCell>Peor Consecuencia</StyledTableCell>
                  <StyledTableCell>Existencia Req Legal</StyledTableCell>
                  <StyledTableCell>Eliminación</StyledTableCell>
                  <StyledTableCell>Sustitución</StyledTableCell>
                  <StyledTableCell>Controles Ingenieria</StyledTableCell>
                  <StyledTableCell>Controles Administrativos</StyledTableCell>
                  <StyledTableCell>Equipos</StyledTableCell>
                  <StyledTableCell>Observaciones</StyledTableCell>
                  <StyledTableCell>Acción</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataControlRiesgos.map((item, i) => (
                  <StyledTableRow className="tableCaracterizacion" key={i}>
                    <TableCell>{item.proceso_area}</TableCell>
                    <TableCell>{item.zona_lugar}</TableCell>
                    <TableCell>{item.cargos}</TableCell>
                    <TableCell>{item.actividad_tarea}</TableCell>
                    <TableCell>{item.rutinario}</TableCell>
                    <TableCell>{item.clasificacion}</TableCell>
                    <TableCell>{item.descripcion}</TableCell>
                    <TableCell>{item.fuente_generadora}</TableCell>
                    <TableCell>{item.efectos_posibles}</TableCell>
                    <TableCell>{item.fuente}</TableCell>
                    <TableCell>{item.medio}</TableCell>
                    <TableCell>{item.individuo}</TableCell>
                    <TableCell>{item.nivel_exposicion}</TableCell>
                    <TableCell>{item.nivel_probabilidad}</TableCell>
                    <TableCell>{item.nivel_deficiencia}</TableCell>
                    <TableCell
                      style={{
                        background: paintRiesgo(item.nivel_deficiencia),
                      }}
                    >
                      {item.interpretacion_nivel_probabilidad}
                    </TableCell>
                    {/* <TableCell>{item.nivel_consecuencia}</TableCell>
                    <TableCell
                      style={{ background: paintLR(item.nivel_riesgo) }}
                    >
                      {item.nivel_riesgo}
                    </TableCell>
                    <TableCell
                      style={{ background: paitnINR(item.interpretacion_nr) }}
                    >
                      {item.interpretacion_nr}
                    </TableCell>
                    <TableCell
                      style={{ background: paintAR(item.aceptabilidad_riesgo) }}
                    >
                      {item.aceptabilidad_riesgo}
                    </TableCell> */}
                    <TableCell>{item.numero_expuesto}</TableCell>
                    <TableCell>{item.tiempo_exposicion}</TableCell>
                    <TableCell>{item.peor_consecuencia}</TableCell>
                    <TableCell>{item.existencia_req_legal}</TableCell>
                    <TableCell>{item.eliminacion}</TableCell>
                    <TableCell>{item.sustitucion}</TableCell>
                    <TableCell>{item.controles_ingenieria}</TableCell>
                    <TableCell>{item.controles_administrativos}</TableCell>
                    <TableCell>{item.equipos}</TableCell>
                    <TableCell>{item.observaciones}</TableCell>
                    <TableCell style={{ display: "grid" }}>
                      <br />
                      <Button
                        variant="warning"
                        onClick={() => {
                          setIdControl(item.id_control_riesgo);
                          setAction(false);
                          queryDataModify(item.id_control_riesgo);
                        }}
                      >
                        Modificar
                      </Button>
                      <br />
                      <Button
                        variant="danger"
                        onClick={() => {
                          queryDataDelete(item.id_control_riesgo);
                        }}
                      >
                        Eliminar
                      </Button>
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Row>
    </Container>
  );
}

export default CECDR;
