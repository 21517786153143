import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";

function CardMantenimeinto({ props }) {
  const { inicio, final, tipo_vehiculo } = props.datos;

  const data = {
    labels: ["Cumplimiento"],
    datasets: [
      {
        data: [inicio, final],
        backgroundColor: ["rgba(83, 204, 108)", "rgba(102, 102, 102, 0.2)"],
        borderColor: ["rgba(198, 221, 210, 1)", "rgba(83, 204, 108, 0.2)"],

        borderWidth: 1,
        borderRadius: 3,
        cutout: "90%",
        circumference: 180,
        rotation: 270,
      },
    ],
  };
  const config = {
    aspectRation: 1.5,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    style: { marginTop: "-25px" },
  };
  const gaugeChartText = {
    id: "counter",
    beforeDraw(chart, args, options) {
      const { ctx } = chart;
      ctx.save();

      const xCenter = chart.getDatasetMeta(0).data[0].x;
      const yCenter = chart.getDatasetMeta(0).data[0].y;
      let valor = inicio + "%";

      //ctx.fillRect(xCenter, yCenter, 120, 1);

      function textLabel(text, x, y, fontSize, textBaseLine, texAling) {
        ctx.font = `${fontSize}px sans-serif`;
        ctx.fillStyle = "#666";
        ctx.textBaseLine = textBaseLine;
        ctx.texAling = texAling;
        ctx.fillText(text, x, y);
      }
      console.log("yCenter: " + yCenter);
      //textLabel('3', left, yCenter+10, 10, 'top', 'left')
      //textLabel('8', right, yCenter, 10, 'top', 'right')
      textLabel(valor, xCenter - 70, yCenter, 65, "bottom", "center");
    },
  };

  return (
    <>
      <Card className="mx-auto" style={{ width: "20rem", height: "100%" }}>
        {/* <Card.Title style={{ fontSize: '20px'}} ></Card.Title> */}
        <Card.Body>
          <Row>
            <Col style={{ height: "50px", fontSize: "20px" }}>
              <strong>{tipo_vehiculo}</strong>
            </Col>
            <Col className="text-start">
              <Doughnut
                data={data}
                options={config}
                plugins={[gaugeChartText]}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}

export default CardMantenimeinto;
