import { Container, Table, Form, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";
import axios from "axios";
import moment from "moment";

function TarjetaReporte() {
  const cookie = new Cookies();
  const access_token = cookie.get("perv_v2_cookies_user_kdfoeinapjdkfawep");

  const [validated, setValidated] = useState(false);

  const [datos, setDatos] = useState({
    evento: "",
    fechaAccidente: moment().format("YYYY-MM-DD"),
    nombre: "",
    cargo: "",
    nombreImplicado: "",
    cargoImplicado: "",
    lugarSuceso: "",
    descripcion: "",
  });

  const [compania, setCompania] = useState({ nombre: "", nit: "", logo: "" });

  const Registrar = async (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      e.preventDefault();
      try {
        const { data } = await axios.post(
          process.env.REACT_APP_SERVIDOR + `/insertar_carcterizacion_general`,
          datos,
          {
            headers: {
              Permission: "1",
              Authorization: `Bearer ${access_token}`,
            },
          }
        );

        if (data) {
          mostrarAlert("success", "Mensaje", "Registro Exitoso");
          setDatos({
            evento: "",
            fechaAccidente: moment().format("YYYY-MM-DD"),
            nombre: "",
            cargo: "",
            nombreImplicado: "",
            cargoImplicado: "",
            lugarSuceso: "",
            descripcion: "",
          });

          setValidated(false);
        }
      } catch (error) {
        if (error.response.status === 403) {
          Swal.fire({
            icon: "error",
            title: "No tienes permiso para esta acción",
          });
        }
      }
    }
  };
  const consultarCompania = async () => {
    const { data } = await axios.get(
      process.env.REACT_APP_SERVIDOR + `/api/v2/consultar_compania`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    if (data.estado) {
      setCompania({
        nombre: data.datos[0].nombre,
        nit: data.datos[0].nit,
        logo: data.datos[0].logo,
      });
    }
  };
  const onChangeDatos = (e) => {
    const { name, value } = e.target;
    setDatos({
      ...datos,
      [name]: value,
    });
  };

  function mostrarAlert(icono, titulo, mensaje) {
    Swal.fire({
      icon: icono,
      title: mensaje,
    });
  }
  useEffect(() => {
    consultarCompania();
  }, []);

  return (
    <>
      <Container>
        <Form noValidate validated={validated} onSubmit={Registrar}>
          <Table>
            <tbody>
              <tr>
                <td rowSpan={3} style={{ textAlign: "center" }}>
                  <img
                    style={{ width: "250px" }}
                    src={
                      process.env.REACT_APP_HOST_FTP +
                      "/documentoLogo/" +
                      compania.logo
                    }
                    alt="logo"
                  />
                </td>
              </tr>
              <tr>
                <td
                  colSpan={5}
                  style={{
                    textAlign: "center",
                    fontSize: "25px",
                    borderBlock: "0px solid transparent",
                  }}
                  className="sinBorde"
                >
                  <strong>{compania.nombre.toUpperCase()}</strong>
                </td>
              </tr>
              <tr>
                <td
                  colSpan={5}
                  style={{
                    textAlign: "center",
                    fontSize: "25px",
                    borderTop: "0px solid transparent",
                  }}
                  className="sinBorde"
                >
                  <strong>NIT: {compania.nit.toUpperCase()}</strong>
                </td>
              </tr>
              <tr>
                <td
                  colSpan={5}
                  className="sinBorde"
                  style={{ fontSize: "35px" }}
                >
                  <strong>TARJETA DE REPORTE DE SINIESTROS </strong>
                </td>
              </tr>
              <tr>
                <td>FECHA DEL INCIDENTE </td>
                <td colSpan={3}>
                  <Form.Group>
                    <Form.Control
                      required
                      type="date"
                      name="fechaAccidente"
                      value={datos.fechaAccidente}
                      onChange={(e) => {
                        onChangeDatos(e);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Obligatorio
                    </Form.Control.Feedback>
                  </Form.Group>
                </td>
              </tr>
              <tr>
                <td>NOMBRE DE QUIEN REPORTA: </td>
                <td>
                  <Form.Group>
                    <Form.Control
                      required
                      type="text"
                      name="nombre"
                      value={datos.nombre}
                      onChange={(e) => {
                        onChangeDatos(e);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Obligatorio
                    </Form.Control.Feedback>
                  </Form.Group>
                </td>
                <td>CARGO:</td>
                <td>
                  <Form.Group>
                    <Form.Control
                      required
                      type="text"
                      name="cargo"
                      value={datos.cargo}
                      onChange={(e) => {
                        onChangeDatos(e);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Obligatorio
                    </Form.Control.Feedback>
                  </Form.Group>
                </td>
              </tr>
              <tr>
                <td>NOMBRE DEL IMPLICADO: </td>
                <td>
                  <Form.Group>
                    <Form.Control
                      required
                      type="text"
                      name="nombreImplicado"
                      value={datos.nombreImplicado}
                      onChange={(e) => {
                        onChangeDatos(e);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Obligatorio
                    </Form.Control.Feedback>
                  </Form.Group>
                </td>
                <td>CARGO DEL IMPLICADO: </td>
                <td>
                  <Form.Group>
                    <Form.Control
                      required
                      type="text"
                      name="cargoImplicado"
                      value={datos.cargoImplicado}
                      onChange={(e) => {
                        onChangeDatos(e);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Obligatorio
                    </Form.Control.Feedback>
                  </Form.Group>
                </td>
              </tr>
              <tr></tr>
              <tr>
                <td>ÁREA DONDE SE PRESENTA EL EVENTO: </td>
                <td colSpan={3}>
                  <Form.Group>
                    <Form.Control
                      required
                      type="text"
                      name="lugarSuceso"
                      value={datos.lugarSuceso}
                      onChange={(e) => {
                        onChangeDatos(e);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Obligatorio
                    </Form.Control.Feedback>
                  </Form.Group>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>TIPO DE EVENTO:</strong>
                </td>
                <td colSpan={4} style={{ textAlign: "center" }}>
                  <Form.Group>
                    <Form.Check
                      required
                      inline
                      label="CONDICION DE PELIGRO VIAL"
                      value="CONDICION DE PELIGRO VIAL"
                      name="evento"
                      type="radio"
                      onChange={(e) => {
                        onChangeDatos(e);
                      }}
                    />
                    <Form.Check
                      required
                      inline
                      label="CONDICION DE PELIGRO LABORAL"
                      value="CONDICION DE PELIGRO LABORAL"
                      name="evento"
                      type="radio"
                      onChange={(e) => {
                        onChangeDatos(e);
                      }}
                    />
                    <Form.Check
                      required
                      inline
                      label="CONDICIÓN DE SALUD"
                      value="CONDICIÓN DE SALUD"
                      name="evento"
                      type="radio"
                      onChange={(e) => {
                        onChangeDatos(e);
                      }}
                    />
                    <Form.Check
                      required
                      inline
                      label="SINIESTRO VIAL"
                      value="SINIESTRO VIAL"
                      name="evento"
                      type="radio"
                      onChange={(e) => {
                        onChangeDatos(e);
                      }}
                    />
                    <Form.Check
                      required
                      inline
                      label="ACCIDENTE LABORAL"
                      value="ACCIDENTE LABORAL"
                      name="evento"
                      type="radio"
                      onChange={(e) => {
                        onChangeDatos(e);
                      }}
                    />

                    <Form.Control.Feedback type="invalid">
                      Obligatorio
                    </Form.Control.Feedback>
                  </Form.Group>
                </td>
              </tr>
              <tr>
                <td colSpan={4}>REALICE UNA DESCRIPCIÓN DEL EVENTO:</td>
              </tr>
              <tr>
                <td colSpan={4}>
                  <Form.Group>
                    <textarea
                      required
                      style={{
                        width: "100%",
                        minHeight: "200px",
                        lineHeight: "1.5",
                        border: "1px solid #ced4da",
                        borderRadius: "0.375rem",
                      }}
                      name="descripcion"
                      value={datos.descripcion}
                      maxLength={600}
                      onChange={(e) => {
                        onChangeDatos(e);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Obligatorio
                    </Form.Control.Feedback>
                  </Form.Group>
                </td>
              </tr>
              <tr>
                <td colSpan={4} style={{ textAlign: "center" }}>
                  <strong>
                    NOTA: DESPUÉS DE DILIGENCIADO, ESTE FORMATO DEBERÁ SER
                    ENTREGADO AL RESPONSABLE DE SST
                  </strong>
                </td>
              </tr>
              <tr>
                <td colSpan={5} className="sinBorde">
                  <Button type="submit">Guardar</Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </Form>
      </Container>
    </>
  );
}
export default TarjetaReporte;
