import { useEffect, useState } from "react";
import { useFetch } from "../../../hooks/useFetch";
import { listTypeVehiclesService } from "../services/listTypeVehicles.service";

export const useTypeVehiclesQuery = () => {
  const fetchData = useFetch();

  const [typeVehicles, setTypeVehicles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const fetchTypeVehicles = async () => {
      try {
        const response = await listTypeVehiclesService(fetchData)();
        if (response.estado) {
          setTypeVehicles(response.datos);
        }
      } catch (error) {
        throw error;
      } finally {
        setLoading(false);
      }
    };

    fetchTypeVehicles();
  }, [refresh]);

  return { typeVehicles, loading, refresh: () => setRefresh(!refresh) };
};
