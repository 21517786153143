import { useEffect, useState } from "react";
import { getPerfilUserService } from "../services/getPerfilUser.service";
import { useFetch } from "./useFetch";

export const usePerfilQuery = () => {
  const fetchData = useFetch();

  const [perfil, setPerfil] = useState(false);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const fetchPerfil = async () => {
      try {
        setLoading(true);
        const response = await getPerfilUserService(fetchData)();
        setPerfil(response);
      } catch (error) {
        console.error("Error al obtener el perfil", error);
        throw new Error(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPerfil();
  }, [refresh]);

  return { perfil, loading, refresh: () => setRefresh(!refresh) };
};
