import { useState, useEffect } from "react";
import { useFetch } from "../../../hooks/useFetch";
import { listDocumentsRoadAccidentsService } from "../services/listDocumentsRoadAccidents.service";

export const useDocumentsRoadAccidentsQuery = () => {
  const fetchData = useFetch();

  const [documentsRoadAccident, setDocumentsRoadAccident] = useState({
    sharedLessons: "",
    legalRepVerification: "",
    claimsCosts: "",
  });
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const fetchDocumentsRoadAccidents = async () => {
      try {
        setLoading(true);
        const response = await listDocumentsRoadAccidentsService(fetchData)();
        setDocumentsRoadAccident(response);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchDocumentsRoadAccidents();
  }, [refresh]);

  return {
    documentsRoadAccident,
    loading,
    refresh: () => setRefresh((prev) => !prev),
  };
};
