import Swal from "sweetalert2";
import moment from "moment";
import { useFetch } from "../../../common/hooks/useFetch";
import { createReportAccidentService } from "../services/createReportAccident.service";
import { useState } from "react";

const initialStateAccident = {
  dateAccident: moment().format("YYYY-MM-DD"),
  namePersonInvolved: "",
  documentPersonInvolved: "",
  vehiclePlate: "",
  typeContract: "",
  placeOfEvent: "",
  fatality: "",
  seriousInjuries: "",
  minorInjuries: "",
  simpleShock: "",
  ipat: "",
  ipatAuthor: "",
};

export const useReportAccidentCommand = (refresh) => {
  const fetchData = useFetch();
  const [errors, setErrors] = useState({ hasErrors: false, errors: {} });
  const [accident, setAccident] = useState(initialStateAccident);

  const handleChangeAccident = ({ name, value }) => {
    setAccident((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const createReportAccident = async (reportAccident) => {
    try {
      const isValid = validations(reportAccident);
      if (!isValid) return;
      const response = await createReportAccidentService(fetchData)(
        reportAccident
      );
      refresh?.();
      Swal.fire({
        icon: "success",
        title: "Siniestro registrado",
        text: response.message,
      });
      setAccident(initialStateAccident);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ocurrió un error al registrar el siniestro",
      });
    }
  };

  const validations = (reportAccident) => {
    const newErrors = {};

    if (!reportAccident.dateAccident) {
      newErrors.dateAccident = "La fecha del siniestro es requerida";
    }

    if (!reportAccident.namePersonInvolved) {
      newErrors.namePersonInvolved = "El nombre del implicado es requerido";
    }

    if (!reportAccident.documentPersonInvolved) {
      newErrors.documentPersonInvolved = "La cédula del implicado es requerida";
    }

    if (!reportAccident.vehiclePlate) {
      newErrors.vehiclePlate = "La placa del vehículo es requerida";
    }

    if (!reportAccident.typeContract) {
      newErrors.typeContract = "El tipo de contrato es requerido";
    }

    if (!reportAccident.placeOfEvent) {
      newErrors.placeOfEvent = "El lugar del evento es requerido";
    }

    if (!reportAccident.fatality) {
      newErrors.fatality = "Las fatalidades son requeridas";
    }

    if (!reportAccident.seriousInjuries) {
      newErrors.seriousInjuries = "Las lesiones graves son requeridas";
    }

    if (!reportAccident.minorInjuries) {
      newErrors.minorInjuries = "Las lesiones leves son requeridas";
    }

    if (!reportAccident.simpleShock) {
      newErrors.simpleShock = "Los choques simples son requeridos";
    }

    if (!reportAccident.ipat) {
      newErrors.ipat = "El IPAT es requerido";
    }

    if (!reportAccident.ipatAuthor) {
      newErrors.ipatAuthor = "El autor del IPAT es requerido";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors({ hasErrors: true, errors: newErrors });
      return false;
    }

    return true;
  };

  return { createReportAccident, errors, handleChangeAccident, accident };
};
