import { useEffect, useState } from "react";
import { useFetch } from "../../../hooks/useFetch";
import { listDocumentsService } from "../services/listDocuments.service";

export const useDocumentsQuery = () => {
  const fetchData = useFetch();

  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        setLoading(true);
        const response = await listDocumentsService(fetchData)();
        setDocuments(
          response.map((document, index) => ({
            ...document,
            originalNameFile: document.name_archivo,
            name_archivo: "/pdfsInstitucional/" + document.name_archivo,
            isTypeFile: index !== 5,
            url:
              index === 5
                ? "/caracterizacion-evaluacion-control-de-riesgo"
                : null,
          }))
        );
      } catch (error) {
        console.error("Error al obtener los documentos", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDocuments();
  }, [refresh]);

  return { documents, loading, refresh: () => setRefresh(!refresh) };
};
