import React from "react";
import Cookies from "universal-cookie";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";

import Logeo from "./Logeo";
import Menu from "./Menu";

import Navegador from "./Navegador";

//Usuarios
import IndexUsuario from "./GestionUsuario/IndexUsuario";
import FomularioIndex from "./GestionUsuario/FormularioUsuario";

//Gestión Institucional
import Objetivos from "./GestionInstitucional/Objetivos";
import Cosevi from "./GestionInstitucional/Cosevi";
import Responsable from "./GestionInstitucional/Responsable";
import ActaConformacionCOSEVI from "./GestionInstitucional/ActaConformacionCOSEVI";
import PoliticaSeguridadVial from "./GestionInstitucional/PoliticaSeguridadVial";
import Divulgacion from "./GestionInstitucional/Divulgacion";
import CaracteristicasEmpresa from "./GestionInstitucional/CaracteristicasEmpresa";

import IndexArchivos from "./Formato/IndexArchivos";
import FormatoArchivos from "./Formato/FormatoArchivos";

import RegistrosViales from "./GestionInstitucional/RegistrosViales";

//Atencion a victimas
import Registros from "./AtencionVictimas/Registros";
import TarjetaReporte from "./AtencionVictimas/TarjetaReporte";
import Caracterizacion from "./AtencionVictimas/Caracterizacion";

/* ---- Gestion Mantenimietno ---- */
import HojaVida from "./VehiculoSeguro/HojaVidaVehiculos";
import GestionMantenimientoCoductores from "./GestionMantenimiento/GestionMantenimientoConductores";
import GestionMantetenimientoMontaCarga from "./GestionMantenimiento/GestionMantenimientoMontacarga";
import RegistrarUsuario from "./Registrar/RegistrarUsuario";
import ListaPreoperativaVehiculos from "./GestionMantenimiento/ListaPreoperativaVehiculos";
import RegistrarNumero from "./Registrar/RegistrarNumero";
import ConsultarNumero from "./Registrar/ConsultarNumeros";

import Datos_Configuracion from "./GestionDatos/Datos_Configuracion";
import CECDR from "./GestionInstitucional/CECDR";
import Calendar from "./GestionTareas/Calendar";
import InspeccionVehiculos from "./VehiculosSeguros/InspeccionVehiculos";
import IndicadorESV from "./VehiculosSeguros/IndicadorESV";
import EncuestaEsv from "./AdministracionUsuarios/EncuestaEsv";
import PreguntasEsv from "./AdministracionUsuarios/PreguntasEsv";
import AdministrarEncuestado from "./AdministracionUsuarios/AdministrarEncuestados";

/* ---- Gestion Mantenimietno ---- */
import MantenimientoVehiculos from "./MantenimientoVehiculos/Inicio";
import ConsultarKilometraje from "./MantenimientoVehiculos/Kilometraje/Consultar";
import RegistrarKilometraje from "./MantenimientoVehiculos/Kilometraje/Registrar";
import ConsultarMantenimiento from "./MantenimientoVehiculos/Matenimiento/Consultar";
import RegistrarMantenimiento from "./MantenimientoVehiculos/Matenimiento/Registar";
import FichaMantenimiento from "./MantenimientoVehiculos/Matenimiento/Ficha";
import IndicadorFlota from "./MantenimientoVehiculos/IndicadorFlota";
import ConsultarRutina from "./MantenimientoVehiculos/Rutinas/Consultar";
import InformeKilometraje from "./MantenimientoVehiculos/InformeKilometraje";
import RegistrarRutina from "./MantenimientoVehiculos/Rutinas/Registrar";

/* ----- Gestion de Indicadores -------- */

import Indicadores from "./Indicadores/Inicador";
import FormularioIndicadores from "./Indicadores/FormularioIndicadores";
import { ROUTES } from "./core/routes/routes";
import RegisterAccidents from "./module/reportRoadAccidents/RegisterAccidents";
import RegisterInvestigation from "./module/investigationRoadAccidents/RegisterInvestigation";

const RequiredAuth = ({ children, redirectTo }) => {
  let authorized = false;
  const cookie = new Cookies();
  const access_token = cookie.get("perv_v2_cookies_user_kdfoeinapjdkfawep");
  if (access_token === null || access_token === undefined) {
    authorized = false;
  } else {
    authorized = true;
  }

  return authorized ? children : <Navigate to={redirectTo} />;
};

function Rutas() {
  return (
    <Routes className="mt-5">
      <Route index element={<Logeo />} />
      <Route path="/" element={<Layout />}>
        <Route
          exact
          path="/Menu"
          element={
            <RequiredAuth redirectTo="/">
              <Menu />
            </RequiredAuth>
          }
        />

        <Route exact path="/GestionUsuario" element={<IndexUsuario />} />
        <Route
          exact
          path="/FomularioUsuario/:datos"
          element={
            <RequiredAuth redirectTo="/">
              <FomularioIndex />
            </RequiredAuth>
          }
        />

        <Route
          exact
          path="/GestionInstitucionalPoliticaSeguridadVial"
          element={
            <RequiredAuth redirectTo="/">
              <PoliticaSeguridadVial />
            </RequiredAuth>
          }
        />

        <Route
          exact
          path="/GestionInstitucionalObjetivos"
          element={
            <RequiredAuth redirectTo="/">
              <Objetivos />
            </RequiredAuth>
          }
        />

        <Route
          exact
          path="/GestionInstitucionalCosevi"
          element={
            <RequiredAuth redirectTo="/">
              <Cosevi />
            </RequiredAuth>
          }
        />

        <Route
          exact
          path="/ResponsablePESV"
          element={
            <RequiredAuth redirectTo="/">
              <Responsable />
            </RequiredAuth>
          }
        />

        <Route
          exact
          path="/ActaConformacionCOSEVI"
          element={
            <RequiredAuth redirectTo="/">
              <ActaConformacionCOSEVI />
            </RequiredAuth>
          }
        />

        <Route
          exact
          path="/Divulgacion"
          element={
            <RequiredAuth redirectTo="/">
              <Divulgacion />
            </RequiredAuth>
          }
        />

        <Route
          exact
          path="/CaracteristicasEmpresa"
          element={
            <RequiredAuth redirectTo="/">
              <CaracteristicasEmpresa />
            </RequiredAuth>
          }
        />

        <Route
          exact
          path="/IndexArchivos"
          element={
            <RequiredAuth redirectTo="/">
              <IndexArchivos />
            </RequiredAuth>
          }
        />

        <Route
          exact
          path="/FormatoArchivos/:dato"
          element={
            <RequiredAuth redirectTo="/">
              <FormatoArchivos />
            </RequiredAuth>
          }
        />

        <Route
          exact
          path="/GestionInstitucionalRegistrosViales"
          element={
            <RequiredAuth redirectTo="/">
              <RegistrosViales />
            </RequiredAuth>
          }
        />

        <Route
          exact
          path="/RegistrosCaracteristicas"
          element={
            <RequiredAuth redirectTo="/">
              <Registros />
            </RequiredAuth>
          }
        />

        <Route
          exact
          path="/AtecionVictimasTarjetaReporte"
          element={
            <RequiredAuth redirectTo="/">
              <TarjetaReporte />
            </RequiredAuth>
          }
        />

        <Route
          exact
          path="/AtecionVictimasCaracterizacion/:datos"
          element={
            <RequiredAuth redirectTo="/">
              <Caracterizacion />
            </RequiredAuth>
          }
        />

        <Route
          exact
          path="/VehiculoSeguroHojaVidaVehiculos"
          element={
            <RequiredAuth redirectTo="/">
              <HojaVida />
            </RequiredAuth>
          }
        />

        <Route
          exact
          path="/lista-preguntas-preoperacional"
          element={
            <RequiredAuth redirectTo="/">
              <ListaPreoperativaVehiculos />
            </RequiredAuth>
          }
        />

        <Route
          exact
          path="/conductores"
          element={
            <RequiredAuth redirectTo="/">
              <GestionMantenimientoCoductores />
            </RequiredAuth>
          }
        />

        <Route
          exact
          path="/gestion-mantenimiento/montacarga"
          element={
            <RequiredAuth redirectTo="/">
              <GestionMantetenimientoMontaCarga />
            </RequiredAuth>
          }
        />

        <Route
          exact
          path="/gestion-tareas/calendario"
          element={
            <RequiredAuth redirectTo="/">
              <Calendar />
            </RequiredAuth>
          }
        />

        <Route
          exact
          path="/registrar-usuario"
          element={
            <RequiredAuth redirectTo="/">
              <RegistrarUsuario />
            </RequiredAuth>
          }
        />

        <Route
          exact
          path="/consultar-numero-celular"
          element={
            <RequiredAuth redirectTo="/">
              <ConsultarNumero />
            </RequiredAuth>
          }
        />
        <Route
          exact
          path="/registrar-numero-celular"
          element={
            <RequiredAuth redirectTo="/">
              <RegistrarNumero />
            </RequiredAuth>
          }
        />

        <Route
          exact
          path="/gestion-datos/configuracion"
          element={
            <RequiredAuth redirectTo="/">
              <Datos_Configuracion />
            </RequiredAuth>
          }
        />
        <Route
          exact
          path="/caracterizacion-evaluacion-control-de-riesgo"
          element={
            <RequiredAuth redirectTo="/">
              <CECDR />
            </RequiredAuth>
          }
        />
        <Route
          exact
          path="/vehiculos-seguros/inspeccion-vehiculos"
          element={
            <RequiredAuth redirectTo="/">
              <InspeccionVehiculos />
            </RequiredAuth>
          }
        />
        <Route
          exact
          path="/vehiculos-seguros/indicador-ESV"
          element={
            <RequiredAuth redirectTo="/">
              <IndicadorESV />
            </RequiredAuth>
          }
        />
        <Route
          exact
          path="/encuesta/esv"
          element={
            <RequiredAuth redirectTo="/">
              <EncuestaEsv />
            </RequiredAuth>
          }
        />
        <Route
          exact
          path="/preguntas/esv"
          element={
            <RequiredAuth redirectTo="/">
              <PreguntasEsv />
            </RequiredAuth>
          }
        />
        <Route
          exact
          path="/administrar-encuestados"
          element={
            <RequiredAuth redirectTo="/">
              <AdministrarEncuestado />
            </RequiredAuth>
          }
        />
        <Route
          exact
          path="/gestion-mantenimiento-vehiculos"
          element={
            <RequiredAuth redirectTo="/">
              <MantenimientoVehiculos />
            </RequiredAuth>
          }
        />
        <Route
          exact
          path="/Indicadores"
          element={
            <RequiredAuth redirectTo="/">
              <Indicadores />
            </RequiredAuth>
          }
        />
        <Route
          exact
          path="/FormularioIndicadores"
          element={
            <RequiredAuth redirectTo="/">
              <FormularioIndicadores />
            </RequiredAuth>
          }
        />
        <Route
          exact
          path="/gestion-mantenimiento/consultar-kilometraje"
          element={
            <RequiredAuth redirectTo="/">
              <ConsultarKilometraje />
            </RequiredAuth>
          }
        />
        <Route
          exact
          path="/gestion-mantenimiento/registrar-kilometraje/:datos"
          element={
            <RequiredAuth redirectTo="/">
              <RegistrarKilometraje />
            </RequiredAuth>
          }
        />
        <Route
          exact
          path="/gestion-mantenimiento/consultar-mantenimiento"
          element={
            <RequiredAuth redirectTo="/">
              <ConsultarMantenimiento />
            </RequiredAuth>
          }
        />
        <Route
          exact
          path="/gestion-mantenimiento/registrar-mantenimiento/:datos"
          element={
            <RequiredAuth redirectTo="/">
              <RegistrarMantenimiento />
            </RequiredAuth>
          }
        />
        <Route
          exact
          path="/gestion-mantenimiento/ficha-mantenimiento"
          element={
            <RequiredAuth redirectTo="/">
              <FichaMantenimiento />
            </RequiredAuth>
          }
        />
        <Route
          exact
          path="/gestion-mantenimiento/indicador-flota"
          element={
            <RequiredAuth redirectTo="/">
              <IndicadorFlota />
            </RequiredAuth>
          }
        />
        <Route
          exact
          path="/gestion-mantenimiento/consultar-rutinas"
          element={
            <RequiredAuth redirectTo="/">
              <ConsultarRutina />
            </RequiredAuth>
          }
        />
        <Route
          exact
          path="/gestion-mantenimiento/informeKilometraje"
          element={
            <RequiredAuth redirectTo="/">
              <InformeKilometraje />
            </RequiredAuth>
          }
        />
        <Route
          exact
          path="/gestion-mantenimiento/rutinas/:datos"
          element={
            <RequiredAuth redirectTo="/">
              <RegistrarRutina />
            </RequiredAuth>
          }
        />

        <Route
          exact
          path={ROUTES.REGISTER_ACCIDENTS}
          element={
            <RequiredAuth redirectTo="/">
              <RegisterAccidents />
            </RequiredAuth>
          }
        />
        <Route
          exact
          path={ROUTES.REGISTER_INVESTIGATION}
          element={
            <RequiredAuth redirectTo="/">
              <RegisterInvestigation />
            </RequiredAuth>
          }
        />
      </Route>
    </Routes>
  );
}

function Layout() {
  return (
    <>
      <Navegador />
      <Outlet />
    </>
  );
}
export { Rutas };
