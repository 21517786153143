import { Container } from "react-bootstrap";

function RegistrosViales() {
  return (
    <>
      <br />
      <h1>RegistrosViales</h1>

      <Container>
        <br />
        <div>
          <p className="texto">
            La empresa cuenta con una página web en la cual por medio del link
            https://master507.ferremaster.com.co/calidad/CONSULTA/SubirArchivo/Lista.php?Area=PL&COD2=SST&Tipo=&buscar=Consultar
            se puede consultar la política de SV de manera interna para todos
            los trabajadores que tienen acceso a la página; de igual forma se
            divulga la política por medio de las carteleras ubicadas en las
            sedes de la empresa y en socialización con el personal.
          </p>
          <br />
        </div>
      </Container>
    </>
  );
}
export default RegistrosViales;
