import { Col, Modal, Row } from "react-bootstrap";
import CardMantenimeinto from "../../../components/CardMantenimiento";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { useTypeVehiclesQuery } from "./hooks/useTypeVehiclesQuery";

function ModalTypeVehicle({ isOpen, onClose }) {
  const navigate = useNavigate();
  const { typeVehicles } = useTypeVehiclesQuery();

  const handleVehiculo = (id) => {
    new Cookies().set("tipo_vehiculo", id, { path: "/" });
    onClose();
    navigate("/gestion-mantenimiento-vehiculos");
  };

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      backdrop="static"
      size="lg"
      centered
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton />
      <Modal.Body>
        {typeVehicles.length > 0 ? (
          <Row lg={2} md={2} xs={1}>
            {typeVehicles.map((item, i) => (
              <Col key={i} className="mb-3" style={{ maxHeight: "500px" }}>
                <button
                  onClick={() => {
                    handleVehiculo(item.id);
                  }}
                  style={{ color: "black", cursor: "pointer" }}
                >
                  <CardMantenimeinto props={{ datos: item }} />
                </button>
              </Col>
            ))}
          </Row>
        ) : (
          <Row>
            <h2>No hay vehiculos registrados</h2>
          </Row>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default ModalTypeVehicle;
