import { Form } from "react-bootstrap";

function Textfield({
  label,
  value,
  onChange,
  placeholder,
  type,
  required,
  name,
  error,
  reference,
}) {
  const handleChange = (event) => {
    const { name, value } = event.target;
    const file = event.target.files ? event.target.files[0] : null;
    onChange({ name, value, file });
  };

  return (
    <div className="mb-3">
      <Form.Group>
        <Form.Label>{label}</Form.Label>
        <Form.Control
          ref={reference}
          className={error ? "is-invalid" : ""}
          type={type}
          placeholder={placeholder}
          value={value}
          name={name}
          onChange={handleChange}
          required={required}
        />
      </Form.Group>
      {error && <small className="text-danger">{error}</small>}
    </div>
  );
}

export default Textfield;
