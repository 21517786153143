import { Container, Row, Col, Table, Button, Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";
import XLSX from "sheetjs-style";
import { saveFile } from "../../common/utils/saveFile.util";

function ConsultarKilometraje() {
  const cookie = new Cookies();
  const access_token = cookie.get("perv_v2_cookies_user_kdfoeinapjdkfawep");
  const tipo_vehiculo = cookie.get("tipo_vehiculo");

  const [validarRegistros, setValidarRegistros] = useState(false);
  const [kilometraje, setKilometraje] = useState([]);
  const [centroCompania, setCentroCompania] = useState([]);
  const [vehiculo, setVehiculo] = useState([]);
  const [filtro, setFiltro] = useState({
    centro: "",
    vehiculo: "",
    rangoFechaInicio: "",
    rangoFechaFinal: "",
  });

  const [, setShowExportExcel] = useState(false);
  const ConsultarVehiculos = async () => {
    const { data } = await axios.post(
      process.env.REACT_APP_SERVIDOR +
        "/api/v2/mantenimiento/consultar/vehiculos",
      { tipo: tipo_vehiculo },
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );

    setVehiculo(data.datos);
  };
  const ConsultarCentro = async () => {
    const { data } = await axios.get(
      process.env.REACT_APP_SERVIDOR + "/api/v2/list/centro",
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    setCentroCompania(data);
  };
  const ConsultarKilometraje = async () => {
    try {
      setKilometraje([]);

      const objDatos = {
        tipo: tipo_vehiculo,
        filtro: filtro,
      };

      const { data } = await axios.post(
        process.env.REACT_APP_SERVIDOR + "/api/v2/listar/consultar/Kilometraje",
        objDatos,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (data.estado) {
        setKilometraje(data.datos);
        setValidarRegistros(false);
      } else {
        setValidarRegistros(true);
      }
    } catch (error) {
      console.log("Error consular kilometraje:" + error);
    }
  };
  const DeleteKilometraje = async (id) => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_SERVIDOR +
          "/api/v2/mantenimiento/delete_kilimetraje",
        { id_kilometraje: id },
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            Permission: "1",
          },
        }
      );

      if (data.message === "success") {
        mostrarAlert("success", "Mensaje", "Se Elimino Exitosamente");
        ConsultarKilometraje();
        await queryCumplimiento();
      }
    } catch (error) {
      console.log("Error delete kilometraje: " + error.message);
      if (error.response.status === 403) {
        Swal.fire({
          icon: "error",
          title: "No tienes permiso para esta acción",
        });
      }
    }
  };
  const queryCumplimiento = async () => {
    await axios.post(
      process.env.REACT_APP_SERVIDOR +
        "/api/v2/listar/mantenimiento/informe_cumplimiento/tipo_vehiculo",
      { tipo: tipo_vehiculo },
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
  };
  const onChangeInfo = (e) => {
    const { name, value } = e.target;
    setFiltro({
      ...filtro,
      [name]: value,
    });
  };

  const mostrarAlert = (icono, titulo, mensaje) => {
    Swal.fire({
      icon: icono,
      title: mensaje,
    });
  };

  const queryExportExcel = async (e) => {
    try {
      const kilometrajeExcel = kilometraje.map((item) => ({
        Fecha: item.fecha,
        Vehiculo: item.placa,
        Kilometraje: item.kilometraje,
        Galones: item.galones,
        Precio: item.precio,
        Precio_galon: item.precio_galon,
        Observaciones: item.observacion,
      }));
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

      const ws = XLSX.utils.json_to_sheet(kilometrajeExcel);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { booktype: "xlsx", type: "array" });
      const file = new Blob([excelBuffer], { type: fileType });
      const filename = `Kilometraje_fecha_consulta${moment().format(
        "DD-MM-YYYY"
      )}_${moment().unix()}.xlsx`;
      saveFile(file, filename);
      setShowExportExcel(false);
    } catch (error) {
      console.log("Error exporting Excel file: " + error.message);
    }
  };

  useEffect(() => {
    ConsultarCentro();
    ConsultarVehiculos();
    ConsultarKilometraje();
  }, []);
  return (
    <>
      <Container className="mt-3 text-center">
        <h1>Consultar Kilometraje</h1>
        <Row className="mb-3">
          <Form>
            <Row className="mb-1 align-items-center justify-content-center">
              <Col xs="auto">
                <Form.Label className="fs-6">Centros</Form.Label>
              </Col>
              <Col xs="auto">
                <Form.Select
                  name="centro"
                  value={filtro.centro}
                  onChange={onChangeInfo}
                >
                  <option value="">Seleccione...</option>
                  {centroCompania.map((fila, id) => (
                    <option key={id} value={fila.id_centro}>
                      {fila.centro_descripcion}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col xs="auto">
                <Form.Label className="fs-6">vehículo</Form.Label>
              </Col>
              <Col xs="auto">
                <Form.Select
                  name="vehiculo"
                  value={filtro.vehiculo}
                  onChange={onChangeInfo}
                >
                  <option value="">Seleccione...</option>
                  {vehiculo?.map((fila, id) => (
                    <option key={id} value={fila.id_vehiculo}>
                      {fila.placa}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col xs="auto">
                <Form.Label className="fs-6">Rango de fechas</Form.Label>
              </Col>
              <Col xs="auto" className="mb-2">
                <Form.Control
                  type="date"
                  name="rangoFechaInicio"
                  value={filtro.rangoFechaInicio}
                  onChange={onChangeInfo}
                />
              </Col>
              <Col xs="auto" className="mb-2">
                <Form.Control
                  type="date"
                  name="rangoFechaFinal"
                  value={filtro.rangoFechaFinal}
                  onChange={onChangeInfo}
                />
              </Col>
              <Col xs="auto" className="mb-2">
                <Button variant="dark" onClick={() => ConsultarKilometraje()}>
                  Consultar
                </Button>
              </Col>
              <Col xs="auto" className="mb-2">
                <Button
                  variant="success"
                  href="../gestion-mantenimiento/registrar-kilometraje/Registrar"
                >
                  Registrar
                </Button>
              </Col>
              <Col xs="2" className="mb-2">
                <Button variant="success" onClick={() => queryExportExcel()}>
                  Exportar Excel
                </Button>
              </Col>
            </Row>
          </Form>
          <br />
          <br />
          {tipo_vehiculo === "4" ? (
            <Table stickyHeader aria-label="table-sticky">
              <thead>
                <tr>
                  <th>Fecha</th>
                  <th>Vehiculo</th>
                  <th>Horometro</th>
                  <th>Observación</th>
                  <th colSpan={2}>Acción</th>
                </tr>
              </thead>
              <tbody>
                {kilometraje.map((fila, id) => (
                  <tr key={id}>
                    <td>{moment(fila.fecha).format("YYYY-MM-DD")}</td>
                    <td>{fila.placa}</td>
                    <td>{fila.kilometraje}</td>
                    <td>{fila.observacion}</td>
                    <td>
                      <Button
                        variant="warning"
                        href={
                          "../gestion-mantenimiento/registrar-kilometraje/Modificar?id=" +
                          fila.id_kilometraje
                        }
                      >
                        Modificar
                      </Button>
                    </td>
                    <td>
                      <Button
                        variant="danger"
                        onClick={() => DeleteKilometraje(fila.id_kilometraje)}
                      >
                        Eliminar
                      </Button>
                    </td>
                  </tr>
                ))}
                {validarRegistros ? (
                  <tr>
                    <td colSpan="8">No hay datos registrados</td>
                  </tr>
                ) : (
                  <tr></tr>
                )}
              </tbody>
            </Table>
          ) : (
            <Table stickyHeader aria-label="table-sticky">
              <thead>
                <tr>
                  <th>Fecha</th>
                  <th>Vehiculo</th>
                  <th>kilometraje</th>
                  <th>Galones</th>
                  <th>Precio</th>
                  <th>Precio/Galón</th>
                  <th>Observación</th>
                  <th colSpan={2}>Acción</th>
                </tr>
              </thead>
              <tbody>
                {kilometraje.map((fila, id) => (
                  <tr key={id}>
                    <td>{moment(fila.fecha).format("YYYY-MM-DD")}</td>
                    <td>{fila.placa}</td>
                    <td>{fila.kilometraje}</td>
                    <td>{fila.galones}</td>
                    <td>{fila.precio}</td>
                    <td>{fila.precio_galon}</td>
                    <td>{fila.observacion}</td>
                    <td>
                      <Button
                        variant="warning"
                        href={
                          "../gestion-mantenimiento/registrar-kilometraje/Modificar?id=" +
                          fila.id_kilometraje
                        }
                      >
                        Modificar
                      </Button>
                    </td>
                    <td>
                      <Button
                        variant="danger"
                        onClick={() => DeleteKilometraje(fila.id_kilometraje)}
                      >
                        Eliminar
                      </Button>
                    </td>
                  </tr>
                ))}
                {validarRegistros ? (
                  <tr>
                    <td colspan="8">No hay datos registrados</td>
                  </tr>
                ) : (
                  <tr></tr>
                )}
              </tbody>
            </Table>
          )}
        </Row>
      </Container>
    </>
  );
}
export default ConsultarKilometraje;
