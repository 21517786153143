export const createReportAccidentService = (fetch) => async (report) => {
  try {
    const response = await fetch({
      url: "/api/v1/siniestros-viales/reportes",
      method: "post",
      body: report,
    });

    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};
