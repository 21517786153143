import { useEffect, useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation } from "react-router";

import Swal from "sweetalert2";
import Cookies from "universal-cookie";

function FormatoArchivos() {
  const cookie = new Cookies();
  const access_token = cookie.get("perv_v2_cookies_user_kdfoeinapjdkfawep");

  const [usuario] = useState(
    localStorage.getItem(
      "ajfdkjakfljasdkljflkasjdflkasjdlfkaslkjffklasjdflkajslkfjalkdfjaksljdfklajdlfkjjfalk"
    )
  );
  const [visualizacion, setVisualizacion] = useState("none");
  const [id, setId] = useState("");
  const [idClase, setIdClase] = useState("");
  const [nombre, setNombre] = useState("");
  const [fecha, setfecha] = useState(new Date());

  let locacion = useLocation();
  let icono = "";
  let titulo = "";
  let mensaje = "";

  function GetUsuario() {
    if (usuario === null) {
      window.location.href = "/";
    } else {
      fetch(
        process.env.REACT_APP_SERVIDOR + "/consulta_usuario_perfiles/" + usuario
      ).then((result) => {
        result.json().then((resp) => {
          if (Object.keys(resp).length !== 0) {
            if (resp[0].perfil === "Usuario") {
              window.location.href = "/Menu";
            }
            console.log("Entro");
            setVisualizacion("block");
          } else {
            window.location.href = "/Menu";
          }
        });
      });
    }
  }
  function ValidarAccion() {
    console.log(locacion);
    let buscarID = locacion.search.split("id=");
    let id = buscarID[1].split("&");
    let clase = locacion.search.split("clase=");

    setId(id[0]);
    setIdClase(clase[1]);

    fetch(process.env.REACT_APP_SERVIDOR + "/consultarFormato_id/" + id[0], {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    }).then((result) => {
      result.json().then((resp) => {
        console.table(resp);
        setNombre(resp[0].nombre);
      });
    });
  }
  function Archivo() {
    let fechaActual =
      fecha.getDate() +
      "/" +
      (fecha.getMonth() + 1) +
      "/" +
      fecha.getFullYear();
    let fl = document.getElementById("archivo");
    /* console.log(fl.files[0]);
        console.log(id);
        console.log(idClase); */

    const data = new FormData();
    data.append("accion", "FormatoDocumento");
    data.append("id", id);
    data.append("id_clase", idClase);
    data.append("fecha", fechaActual);
    data.append("file", fl.files[0]);

    //console.log(data)
    if (fl.files[0] !== undefined) {
      fetch(process.env.REACT_APP_SERVIDOR + "/api/subir-archivo", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${access_token}`,
          Permssion: "1",
        },
        body: data,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          if (data.status === "Error") {
            icono = "error";
            titulo = "Mensaje";
            mensaje = "Error al subir el archivo";
          } else {
            icono = "success";
            titulo = "Mensaje";
            mensaje = "Archivo registrado exitosamente";
            window.location.href = "/IndexArchivos";
          }
          //alert(data.status+' mensaje:'+data.message);
        })
        .catch((error) => {
          if (error.response.status === 403) {
            Swal.fire({
              icon: "error",
              title: "No tienes permsisos para esta acción",
            });
          }
          console.log(error);
        });
    } else {
      icono = "error";
      titulo = "Mensaje";
      mensaje = "Ingresa el archivo a registrar";
    }
    if (mensaje !== "") {
      mostrarAlert(icono, titulo, mensaje);
    }
  }
  function mostrarAlert(icono, titulo, mensaje) {
    Swal.fire({
      icon: icono,
      title: mensaje,
    });
  }
  useEffect(() => {
    GetUsuario();
  }, []);
  useEffect(() => {
    ValidarAccion();
  }, []);

  return (
    <>
      <Container style={{ display: visualizacion }}>
        <br />
        <h1>FORMATO PARA SUBIR ARCHIVOS</h1>
        <br />
        <br />
        <Row className="justify-content-md-center">
          <Col xs={4}>
            <Form.Group>
              <Form.Label>MES DEL EVENTO</Form.Label>
              <Form.Control
                size="sm"
                type="text"
                value={nombre}
                disabled="true"
              />
            </Form.Group>
          </Col>
          <Col xs={4}>
            <Form.Group>
              <Form.Label>Fecha Evaluación</Form.Label>
              <DatePicker
                id="fecha"
                dateFormat="dd/MM/yyyy"
                size="sm"
                className="form-control form-control-sm"
                selected={fecha}
                onChange={(e) => {
                  setfecha(e);
                }}
              />
            </Form.Group>
          </Col>
          <Col xs={4}>
            <Form.Group
              id="formFileMultiple"
              className="mb-3"
              enctype="multipart/form-data"
            >
              <Form.Label>Archivo</Form.Label>
              <Form.Control type="file" id="archivo" className="archivo" />
            </Form.Group>
          </Col>
        </Row>
        <br />
        <Row
          className="justify-content-md-center"
          style={{
            width: "100%",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <Button
            style={{ width: "100px" }}
            onClick={(e) => {
              Archivo();
            }}
          >
            Registrar
          </Button>
        </Row>
      </Container>
    </>
  );
}
export default FormatoArchivos;
