import { useRef } from "react";
import { Badge, NavDropdown } from "react-bootstrap";
import { BsFillTrashFill } from "react-icons/bs";
import { RiFileEditFill } from "react-icons/ri";

function HeaderItemFile({
  label,
  isAdmin,
  file,
  documentId,
  onDelete,
  onEdit,
}) {
  const HOST_FTP = process.env.REACT_APP_HOST_FTP;
  const refInput = useRef(null);

  const handleClickEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    refInput.current.click();
  };

  const onChangeFile = (e) => {
    const file = e.target.files[0];
    onEdit({ documentId, documentName: label, file });
  };

  const handleClickDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onDelete(file);
  };

  const handleUrlFile = (file) => {
    if (file?.includes(HOST_FTP)) {
      return file;
    }

    return HOST_FTP + file;
  };

  return (
    <NavDropdown.Item
      href={handleUrlFile(file)}
      target="_blank"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="d-flex align-items-center">
        <div className="w-100 d-block">
          <p className="mb-0">{label}</p>
          {!Boolean(file) && <Badge bg="danger">No existe</Badge>}
        </div>

        {isAdmin && (
          <div>
            <button
              onClick={handleClickEdit}
              className="btn btn-sm btn-link"
              style={{ color: "#dee2e6" }}
            >
              <RiFileEditFill />
            </button>
            <button
              onClick={handleClickDelete}
              className="btn btn-sm btn-link"
              style={{ color: "#dee2e6" }}
            >
              <BsFillTrashFill />
            </button>

            <input
              type="file"
              accept="application/pdf"
              ref={refInput}
              onChange={onChangeFile}
              hidden
            />
          </div>
        )}
      </div>
    </NavDropdown.Item>
  );
}

export default HeaderItemFile;
