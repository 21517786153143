import { useEffect, useState } from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import Cookies from "universal-cookie";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import HeaderMain from "./common/components/Header/HeaderMain";

ChartJS.register(ArcElement, Tooltip, Legend);

function Navegador() {
  const cookie = new Cookies();
  const [tipoNav, setTipoNav] = useState(true);

  const handleChangeHeader = () => {
    window.location.search = "";
    cookie.remove("tipo_vehiculo");
    window.location.href = "/Menu";
  };

  useEffect(() => {
    if (window.location.pathname.includes("/gestion-mantenimiento")) {
      setTipoNav(false);
    } else {
      setTipoNav(true);
    }
  }, []);

  return (
    <>
      {tipoNav ? (
        <HeaderMain />
      ) : (
        <Navbar
          bg="dark"
          variant="dark"
          expand="xl"
          fixed="top"
          className="mb-3"
        >
          <Container>
            <Navbar.Brand
              onClick={() => handleChangeHeader()}
              style={{ cursor: "pointer" }}
            >
              PESV
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link
                  variant="dark"
                  href="../../gestion-mantenimiento-vehiculos"
                >
                  MTTO
                </Nav.Link>
                <Nav.Link
                  variant="dark"
                  href="/gestion-mantenimiento/consultar-kilometraje"
                >
                  Kilometraje/Horometro
                </Nav.Link>
                <Nav.Link
                  variant="dark"
                  href="/gestion-mantenimiento/consultar-mantenimiento"
                >
                  Mantenimiento
                </Nav.Link>
                <Nav.Link
                  variant="dark"
                  href="/gestion-mantenimiento/ficha-mantenimiento"
                >
                  Ficha de Mantenimiento
                </Nav.Link>
                <Nav.Link
                  variant="dark"
                  href="/gestion-mantenimiento/indicador-flota"
                >
                  Indicador de Flota
                </Nav.Link>
                <Nav.Link
                  variant="dark"
                  href="/gestion-mantenimiento/informeKilometraje"
                >
                  Informe Kilometraje
                </Nav.Link>
                <Nav.Link
                  variant="dark"
                  href="/gestion-mantenimiento/consultar-rutinas"
                >
                  Rutinas
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      )}
    </>
  );
}
export default Navegador;
