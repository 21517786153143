export const createInvestigationRoadAccidentService =
  (fetch) => async (investigation) => {
    try {
      const formData = new FormData();

      formData.append("files", investigation.eventDetails);
      formData.append("files", investigation.lessonLearned);
      formData.append("files", investigation.lessonDisclosureEvidence);
      Object.entries(investigation).forEach(([key, value]) => {
        if (
          key !== "eventDetails" &&
          key !== "lessonLearned" &&
          key !== "lessonDisclosureEvidence"
        ) {
          formData.append(key, value);
        }
      });
      const response = await fetch({
        url: "/api/v1/siniestros-viales/investigaciones",
        method: "post",
        body: formData,
        contentType: "multipart/form-data",
      });

      return response;
    } catch (error) {
      throw new Error(error.response?.data?.message || error.message);
    }
  };
