export const OPTIONS_INJURY_AGENT = [
  { value: "1", label: "Agente 1" },
  { value: "2", label: "Agente 2" },
  { value: "3", label: "Agente 3" },
];

export const OPTIONS_BEHAVIOR_OR_CONDITION = [
  { value: "1", label: "Condición 1" },
  { value: "2", label: "Condición 2" },
  { value: "3", label: "Condición 3" },
];

export const OPTIONS_INCIDENT_MECHANISM = [
  { value: "1", label: "Mecanismo 1" },
  { value: "2", label: "Mecanismo 2" },
  { value: "3", label: "Mecanismo 3" },
];

export const OPTIONS_INJURY_TYPE = [
  { value: "1", label: "Tipo 1" },
  { value: "2", label: "Tipo 2" },
  { value: "3", label: "Tipo 3" },
];

export const OPTIONS_AFFECTED_BODY_PART = [
  { value: "1", label: "Parte 1" },
  { value: "2", label: "Parte 2" },
  { value: "3", label: "Parte 3" },
];
