import { useState } from "react";
import { useFetch } from "../../../common/hooks/useFetch";
import Swal from "sweetalert2";
import { createInvestigationRoadAccidentService } from "../services/createInvestigationRoadAccident.service";

const initialStateInvestigation = {
  committeeAnalysisDate: "",
  directFatalityCosts: "",
  indirectFatalityCosts: "",
  directSevereInjuryCosts: "",
  indirectSevereInjuryCosts: "",
  directMinorInjuryCosts: "",
  indirectMinorInjuryCosts: "",
  directSimpleCollisionCosts: "",
  indirectSimpleCollisionCosts: "",
  accidentPaymentProof: "",
  injuryAgent: "",
  behaviorOrCondition: "",
  incidentMechanism: "",
  disabilityDays: "",
  injuryType: "",
  affectedBodyPart: "",
  correctiveAction: "",
  actionResponsible: "",
  actionExecutionDate: "",
  eventDetails: null,
  lessonLearned: null,
  lessonDisclosureEvidence: null,
};

export const useInvestigationAccidentCommand = (refresh) => {
  const fetchData = useFetch();
  const [errors, setErrors] = useState({ hasErrors: false, errors: {} });
  const [investigation, setInvestigation] = useState(initialStateInvestigation);

  const handleChangeInvestigation = ({ name, value }) => {
    setInvestigation((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const createInvestigationAccident = async (investigationAccident) => {
    try {
      const isValid = validations(investigationAccident);
      if (!isValid) return;
      const response = await createInvestigationRoadAccidentService(fetchData)(
        investigationAccident
      );

      refresh?.();
      Swal.fire({
        icon: "success",
        title: "Investigación registrada",
        text: response.message,
      });
      setInvestigation(initialStateInvestigation);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ocurrió un error al registrar la investigación",
      });
    }
  };

  const validations = (investigation) => {
    const newErrors = {};

    if (!investigation.committeeAnalysisDate) {
      newErrors.committeeAnalysisDate =
        "La fecha del análisis del comité es requerida";
    }

    if (!investigation.directFatalityCosts) {
      newErrors.directFatalityCosts =
        "Los costos directos por fatalidades son requeridos";
    }

    if (!investigation.indirectFatalityCosts) {
      newErrors.indirectFatalityCosts =
        "Los costos indirectos por fatalidades son requeridos";
    }

    if (!investigation.directSevereInjuryCosts) {
      newErrors.directSevereInjuryCosts =
        "Los costos directos por lesiones graves son requeridos";
    }

    if (!investigation.indirectSevereInjuryCosts) {
      newErrors.indirectSevereInjuryCosts =
        "Los costos indirectos por lesiones graves son requeridos";
    }

    if (!investigation.directMinorInjuryCosts) {
      newErrors.directMinorInjuryCosts =
        "Los costos directos por lesiones leves son requeridos";
    }

    if (!investigation.indirectMinorInjuryCosts) {
      newErrors.indirectMinorInjuryCosts =
        "Los costos indirectos por lesiones leves son requeridos";
    }

    if (!investigation.directSimpleCollisionCosts) {
      newErrors.directSimpleCollisionCosts =
        "Los costos directos por choques simples son requeridos";
    }

    if (!investigation.indirectSimpleCollisionCosts) {
      newErrors.indirectSimpleCollisionCosts =
        "Los costos indirectos por choques simples son requeridos";
    }

    if (!investigation.accidentPaymentProof) {
      newErrors.accidentPaymentProof =
        "El comprobante de pago por accidente es requerido";
    }

    if (!investigation.injuryAgent) {
      newErrors.injuryAgent = "El agente de lesión es requerido";
    }

    if (!investigation.behaviorOrCondition) {
      newErrors.behaviorOrCondition =
        "El comportamiento o condición es requerido";
    }

    if (!investigation.incidentMechanism) {
      newErrors.incidentMechanism = "El mecanismo del siniestro es requerido";
    }

    if (!investigation.disabilityDays) {
      newErrors.disabilityDays = "Los días de incapacidad son requeridos";
    }

    if (!investigation.injuryType) {
      newErrors.injuryType = "El tipo de lesión es requerido";
    }

    if (!investigation.affectedBodyPart) {
      newErrors.affectedBodyPart = "La parte del cuerpo implicada es requerida";
    }

    if (!investigation.correctiveAction) {
      newErrors.correctiveAction = "La acción correctiva es requerida";
    }

    if (!investigation.actionResponsible) {
      newErrors.actionResponsible = "El responsable de la acción es requerido";
    }

    if (!investigation.actionExecutionDate) {
      newErrors.actionExecutionDate =
        "La fecha de ejecución de la acción es requerida";
    }

    if (!investigation.eventDetails) {
      newErrors.eventDetails = "La información del evento es requerida";
    }

    if (!investigation.lessonLearned) {
      newErrors.lessonLearned = "La lección aprendida es requerida";
    }

    if (!investigation.lessonDisclosureEvidence) {
      newErrors.lessonDisclosureEvidence =
        "La evidencia de divulgación de la lección aprendida es requerida";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors({ hasErrors: true, errors: newErrors });
      return false;
    }

    return true;
  };

  return {
    investigation,
    handleChangeInvestigation,
    createInvestigationAccident,
    errors,
  };
};
