import { Link } from "react-router-dom";
import { ROUTES } from "../../../core/routes/routes";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import LOGO from "../../../img/logodatralabs.png";
import { useDocumentsQuery } from "./hooks/useDocumentsQuery";
import { usePerfilQuery } from "../../hooks/usePerfilQuery";
import HeaderItemFile from "./HeaderItemFile";
import HeaderItem from "./HeaderItem";
import { useDocumentsCommand } from "./hooks/useDocumentsCommand";
import { useDocumentsSafeCarsQuery } from "./hooks/useDocumentsSafeCarsQuery";
import { useDocumentsSafeCarsCommand } from "./hooks/useDocumentsSafeCarsCommand";
import { useState } from "react";
import ModalTypeVehicle from "./ModalTypeVehicle";
import Cookies from "universal-cookie";
import { useDocumentsRoadAccidentsQuery } from "./hooks/useDocumentsRoadAccidnetsQuery";
import { useDocumentsRoadAccidentsCommand } from "./hooks/useDocumentsRoadAccidentsCommand";

function HeaderMain() {
  const cookie = new Cookies();
  const nombre_user = cookie.get("nombre_user");
  const logo_empresa = cookie.get("logo_empresa");
  // const logo_empresa = "china.jpg";
  const LOGO_COMPANIA =
    process.env.REACT_APP_HOST_FTP + "/documentoLogo/" + logo_empresa;

  const { perfil } = usePerfilQuery();
  const { documents, refresh } = useDocumentsQuery();
  const { documentsSafeCars, refresh: refreshSafeCar } =
    useDocumentsSafeCarsQuery();
  const { deleteDocument, updateDocument } = useDocumentsCommand(refresh);
  const {
    updateDocument: updateDocumentSafeCar,
    deleteDocument: deleteDocumentSafeCar,
  } = useDocumentsSafeCarsCommand(refreshSafeCar);
  const { documentsRoadAccident, refresh: refreshRoadAccident } =
    useDocumentsRoadAccidentsQuery();
  const {
    uploadDocument: uploadDocumentRoadAccident,
    deleteDocument: deleteDocumentRoadAccident,
  } = useDocumentsRoadAccidentsCommand(refreshRoadAccident);

  const [showModalTypeVehicle, setShowModalTypeVehicle] = useState(false);
  const toggleModalTypeVehicle = () => setShowModalTypeVehicle((prev) => !prev);

  function cerrarSesion() {
    cookie.remove("perv_v2_cookies_user_kdfoeinapjdkfawep");
    cookie.remove("compania_user");
    window.location.href = "/";
  }

  return (
    <Navbar bg="dark" variant="dark" expand="xl" sticky="top">
      <Container>
        <Navbar.Brand>
          <Link to={ROUTES.HOME}>
            <img
              src={LOGO}
              alt="logo"
              style={{ maxHeight: "75%", maxWidth: "50%" }}
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse
          id="basic-navbar-nav"
          className="d-flex justify-content-between"
        >
          <Nav className="w-100 flex-fill">
            <NavDropdown
              title="Procedimientos documentacion"
              menuVariant="dark"
              flip={true}
            >
              <div className="px-2">
                <NavDropdown title="Planear" menuVariant="dark" drop="end">
                  {documents.map((document, index) =>
                    document.isTypeFile ? (
                      <HeaderItemFile
                        key={index}
                        documentId={document.id_documentos}
                        file={
                          document.originalNameFile
                            ? document.name_archivo
                            : null
                        }
                        isAdmin={perfil}
                        label={document.desc_documento}
                        onDelete={() =>
                          deleteDocument(document.originalNameFile)
                        }
                        onEdit={updateDocument}
                      />
                    ) : (
                      <HeaderItem
                        key={index}
                        label={document.desc_documento}
                        to={document.url}
                      />
                    )
                  )}
                </NavDropdown>
              </div>

              <div className="px-2">
                <NavDropdown
                  title="Hacer"
                  menuVariant="dark"
                  drop="end"
                  flip={true}
                >
                  <HeaderItem
                    to="/gestion-tareas/calendario"
                    label="P9. Plan Anual de Trabajo"
                  />
                  <HeaderItem
                    to="/gestion-tareas/calendario"
                    label="P10. Plan Anual de Capacitación"
                  />

                  <HeaderItemFile
                    label="P11. Responsabilidad y Comportamiento Seguro"
                    isAdmin={perfil}
                    file={documentsSafeCars.respo_comp_seguro}
                    documentId="respo_comp_seguro"
                    onEdit={({ documentId, file }) =>
                      updateDocumentSafeCar({ documentName: documentId, file })
                    }
                    onDelete={() => deleteDocumentSafeCar("respo_comp_seguro")}
                  />
                  <HeaderItemFile
                    label="P12. Plan de Preparación y Respuesta Ante Emergencias Viales"
                    isAdmin={perfil}
                    file={documentsSafeCars.plan_prep_respu_emergencia_vial}
                    documentId="plan_prep_respu_emergencia_vial"
                    onEdit={({ documentId, file }) =>
                      updateDocumentSafeCar({ documentName: documentId, file })
                    }
                    onDelete={() =>
                      deleteDocumentSafeCar("plan_prep_respu_emergencia_vial")
                    }
                  />

                  <HeaderItem
                    to="/RegistrosCaracteristicas"
                    label="P13. Investigación Interna de Siniestros Viales"
                  />

                  <HeaderItemFile
                    label="P14. Vias Seguras"
                    isAdmin={perfil}
                    file={documentsSafeCars.vias_seguras}
                    documentId="vias_seguras"
                    onEdit={({ documentId, file }) =>
                      updateDocumentSafeCar({ documentName: documentId, file })
                    }
                    onDelete={() => deleteDocumentSafeCar("vias_seguras")}
                  />
                  <HeaderItemFile
                    label="P15. Planificación de Desplazamientos"
                    isAdmin={perfil}
                    file={documentsSafeCars.planificacion_desplazamiento}
                    documentId="planificacion_desplazamiento"
                    onEdit={({ documentId, file }) =>
                      updateDocumentSafeCar({ documentName: documentId, file })
                    }
                    onDelete={() =>
                      deleteDocumentSafeCar("planificacion_desplazamiento")
                    }
                  />

                  <HeaderItem
                    to="/vehiculos-seguros/inspeccion-vehiculos"
                    label="P16. Inspección de Vehículos"
                  />
                  <HeaderItem
                    to="/vehiculos-seguros/inspeccion-vehiculos"
                    label="P17. Mantenimiento y Control de Vehículos"
                    onClick={toggleModalTypeVehicle}
                  />

                  <HeaderItemFile
                    label="P18. Gestión de Cambios"
                    isAdmin={perfil}
                    file={documentsSafeCars.gestion_cambio}
                    documentId="gestion_cambio"
                    onEdit={({ documentId, file }) =>
                      updateDocumentSafeCar({ documentName: documentId, file })
                    }
                    onDelete={() => deleteDocumentSafeCar("gestion_cambio")}
                  />
                  <HeaderItemFile
                    label="P19. Archivo y Retención"
                    isAdmin={perfil}
                    file={documentsSafeCars.archivo_retencion}
                    documentId="archivo_retencion"
                    onEdit={({ documentId, file }) =>
                      updateDocumentSafeCar({ documentName: documentId, file })
                    }
                    onDelete={() => deleteDocumentSafeCar("archivo_retencion")}
                  />
                </NavDropdown>
              </div>

              <div className="px-2">
                <NavDropdown title="Verficar" menuVariant="dark" drop="end">
                  <HeaderItem to="/Indicadores" label="P20. Indicadores" />

                  <HeaderItemFile
                    label=" P21. Registro y Análisis Estadístico de Siniestros Viales"
                    isAdmin={perfil}
                    file={documentsSafeCars.regis_anali_siniestros_viales}
                    documentId="regis_anali_siniestros_viales"
                    onEdit={({ documentId, file }) =>
                      updateDocumentSafeCar({ documentName: documentId, file })
                    }
                    onDelete={() =>
                      deleteDocumentSafeCar("regis_anali_siniestros_viales")
                    }
                  />
                  <HeaderItemFile
                    label="P22. Autitoría"
                    isAdmin={perfil}
                    file={documentsSafeCars.auditoria}
                    documentId="auditoria"
                    onEdit={({ documentId, file }) =>
                      updateDocumentSafeCar({ documentName: documentId, file })
                    }
                    onDelete={() => deleteDocumentSafeCar("auditoria")}
                  />
                </NavDropdown>
              </div>

              <div className="px-2">
                <NavDropdown title="Actuar" menuVariant="dark" drop="end">
                  <HeaderItem
                    to="/gestion-tareas/calendario"
                    label="P23. Mejora Contínua"
                  />

                  <HeaderItemFile
                    label="P24. Mecanismos de Participación y Comuniación"
                    isAdmin={perfil}
                    file={documentsSafeCars.meca_participacion_comunicacion}
                    documentId="meca_participacion_comunicacion"
                    onEdit={({ documentId, file }) =>
                      updateDocumentSafeCar({ documentName: documentId, file })
                    }
                    onDelete={() =>
                      deleteDocumentSafeCar("meca_participacion_comunicacion")
                    }
                  />
                </NavDropdown>
              </div>
            </NavDropdown>

            <NavDropdown title="Siniestros viales" menuVariant="dark">
              <NavDropdown.Item>
                <Link
                  to={ROUTES.REGISTER_ACCIDENTS}
                  style={{ textDecoration: "none", color: "#dee2e6" }}
                >
                  Registro de reportes
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link
                  to={ROUTES.REGISTER_INVESTIGATION}
                  style={{ textDecoration: "none", color: "#dee2e6" }}
                >
                  Registro de investigaciones
                </Link>
              </NavDropdown.Item>

              <HeaderItemFile
                documentId="sharedLessons"
                file={documentsRoadAccident.sharedLessons}
                label="Divulgación de lecciones aprendidas"
                isAdmin={perfil}
                onEdit={({ documentId, file }) =>
                  uploadDocumentRoadAccident({ documentName: documentId, file })
                }
                onDelete={() =>
                  deleteDocumentRoadAccident({ documentName: "sharedLessons" })
                }
              />

              <HeaderItemFile
                documentId="claimsCosts"
                file={documentsRoadAccident.claimsCosts}
                label="Costos de siniestros y juridicos"
                isAdmin={perfil}
                onEdit={({ documentId, file }) =>
                  uploadDocumentRoadAccident({ documentName: documentId, file })
                }
                onDelete={() =>
                  deleteDocumentRoadAccident({ documentName: "claimsCosts" })
                }
              />

              <HeaderItemFile
                documentId="legalRepVerification"
                file={documentsRoadAccident.legalRepVerification}
                label="Certificación de representante legal"
                isAdmin={perfil}
                onEdit={({ documentId, file }) =>
                  uploadDocumentRoadAccident({ documentName: documentId, file })
                }
                onDelete={() =>
                  deleteDocumentRoadAccident({
                    documentName: "legalRepVerification",
                  })
                }
              />
            </NavDropdown>

            <NavDropdown title="Administración y usuarios" menuVariant="dark">
              <NavDropdown.Item>
                <Link
                  to="/VehiculoSeguroHojaVidaVehiculos"
                  style={{ textDecoration: "none", color: "#dee2e6" }}
                >
                  Ver vehiculos
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link
                  to="/conductores"
                  style={{ textDecoration: "none", color: "#dee2e6" }}
                >
                  Ver conductores
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link
                  to="/administrar-encuestados"
                  style={{ textDecoration: "none", color: "#dee2e6" }}
                >
                  Administrar encuestados
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link
                  to="/GestionUsuario"
                  style={{ textDecoration: "none", color: "#dee2e6" }}
                >
                  Gestion usuarios
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link
                  to="/encuesta/esv"
                  style={{ textDecoration: "none", color: "#dee2e6" }}
                >
                  ESV
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link
                  to="/vehiculos-seguros/indicador-ESV"
                  style={{ textDecoration: "none", color: "#dee2e6" }}
                >
                  Indicador ESV
                </Link>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <div className="d-inline">
            <NavDropdown
              title={
                <img
                  src={LOGO_COMPANIA}
                  alt="logo de la compañia"
                  width={100}
                  height={50}
                />
              }
            >
              <NavDropdown.Item>{nombre_user}</NavDropdown.Item>
              <NavDropdown.Item onClick={() => cerrarSesion()}>
                Cerrar Sesión
              </NavDropdown.Item>
            </NavDropdown>
          </div>
        </Navbar.Collapse>
      </Container>

      <ModalTypeVehicle
        isOpen={showModalTypeVehicle}
        onClose={toggleModalTypeVehicle}
      />
    </Navbar>
  );
}

export default HeaderMain;
