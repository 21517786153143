import { Container } from "react-bootstrap";
import firma from "../img/firma.png";

function Objetivos() {
  return (
    <>
      <br />
      <h1>OBJETIVO</h1>
      <Container>
        <br />
        <div>
          <h3>Objetivo Generales del PESV</h3>
          <p className="texto">
            Prevenir y controlar la ocurrencia de accidentes de tránsito de los
            trabajadores; así mismo realizar seguimiento a las actividades en
            promoción y prevención de los trabajadores directos e indirectos y
            contratistas de la SOFERCO S.A.S, esto mediante la implementación de
            un modelo de mejoramiento continuo.
          </p>
        </div>
        <br />
        <br />
        <div>
          <h3>Objetivos Específicos del PESV</h3>
          <p className="texto">
            1. Fortalecer la gestión institucional en materia de seguridad vial
            que conduzca a la definición de políticas y responsabilidades de la
            misma. <br />
            2. Fortalecer el componente humano en materia de seguridad vial, a
            través de procesos de sensibilización, formación y diagnóstico del
            mismo perfil. <br />
            3. Propender por la seguridad e integridad física de los
            funcionarios y prestadores del servicio mediante la disposición de
            una flota de vehículos más seguros.
            <br />
            4. Disponer de una infraestructura segura en la Entidad, que permita
            minimizar las condiciones de riesgo en materia de accidentes viales.
            <br />
            5. Establecer mecanismos para la atención oportuna de emergencias
            originadas por accidentes de tránsito.
            <br />
          </p>
        </div>
        <br />
        <br />
        <div>
          <h3>Directrices de la alta dirección</h3>
          <p className="texto">
            La seguridad vial en SOFERCO S.A.S, está basada en el cumplimiento
            de las normas de tránsito y transporte establecidas en el código
            nacional. <br />
            La SOCIEDAD FERRETERA DE COMERCIO S.A.S mediante su representante
            legal se compromete a dar cumplimiento a los lineamientos
            establecidos en el Plan estratégico de Seguridad Vial de la empresa,
            teniendo en cuenta el seguimiento y control a lo planeado, diseñado,
            implementado y las mediciones de las acciones que resulten para
            cumplir con los objetivos a favor de la seguridad vial.
          </p>
        </div>
        <br />
        <p style={{ textAlign: "center" }}>
          <img src={firma} alt="Firma" className="imagenFirma" />
        </p>
      </Container>
    </>
  );
}
export default Objetivos;
