export const listDocumentsRoadAccidentsService = (fetchData) => async () => {
  try {
    const response = await fetchData({
      method: "get",
      url: "/api/v1/siniestros-viales/documentos",
    });
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};
