import { Container } from "react-bootstrap";
import firma from "../img/firma.png";

function PoliticaSeguridadVial() {
  return (
    <>
      <br />
      <h1>POLITICA DE SEGURIDAD VIAL</h1>

      <Container>
        <br />
        <div>
          <p className="texto">
            La SOCIEDAD FERRETERA DE COMERCIO S.A.S. es una empresa dedicada a
            la Comercialización al por mayor y al detal de materiales y
            herramientas para construcción, remodelación, taller e industria y
            se compromete a establecer, implementar, mantener y mejorar
            continuamente su Plan Estratégico de Seguridad Vial (PESV),
            cumpliendo los requisitos legales aplicables y asignando los
            recursos necesarios para realizar actividades de promoción y
            prevención de incidentes y accidentes viales en las actividades
            relacionadas con la empresa, identificando, interviniendo y
            minimizando los riesgos derivados del comportamiento del personal
            que ejerce el Rol de Conductor, mediante programas de manejo
            defensivo, seguridad vial y cuidados del vehículo, promoviendo la
            conducción segura y la inteligencia vial.
            <br />
            <br />
            La SOCIEDAD FERRETERA DE COMERCIO S.A.S ejerce seguimiento sobre las
            acciones implementadas para garantizar de manera oportuna y segura
            el normal desarrollo de las actividades cotidianas del personal que
            tiene y/o ejerce el rol de conductor, motociclista, ciclista, peatón
            en representación de la empresa, adoptando las medidas pertinentes
            para asegurar que a todos los vehículos y/o equipos al servicio de
            la empresa se les realicen las labores de mantenimiento preventivo
            mínimas recomendadas por los fabricantes.
            <br />
            <br />
            Esta política hace parte de las políticas de gestión de la empresa,
            será revisada anualmente, divulgada, comunicada y de obligatorio
            cumplimiento por parte de todos los trabajadores, independientemente
            de su forma de contratación; cualquier comportamiento que vaya en
            contra de su cumplimiento los hará merecedores de las
            correspondientes sanciones disciplinarias establecidas en el
            Reglamento Interno de Trabajo de la empresa.
            <br />
            <br />
          </p>
          <br />
        </div>
        <br />
        <p style={{ textAlign: "center" }}>
          <img src={firma} alt="Firma" className="imagenFirma" />
        </p>
        <p>Guadalajara de Buga, 26 de Octubre de 2021</p>
        <br />
        <br />
      </Container>
    </>
  );
}
export default PoliticaSeguridadVial;
