import moment from "moment";
import React, { useState, useEffect, forwardRef, useRef } from "react";
import { Container, Row, Col, Button, Modal, Form } from "react-bootstrap";
import Slider from "react-slick";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CardWeek from "../components/CardWeek";
import axios from "axios";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";
import { saveFile } from "../common/utils/saveFile.util";

function Calendar() {
  const access_token = new Cookies().get(
    "perv_v2_cookies_user_kdfoeinapjdkfawep"
  );
  const [currentYear, setCurrentYear] = useState(new Date());
  const CustomInputDate = forwardRef(({ value, onClick }, ref) => (
    <Form.Control
      variant="success"
      className="text-center"
      onClick={onClick}
      ref={ref}
      defaultValue={value}
    />
  ));
  const refSlick = useRef();

  // Obtenemos las semanas del año
  const [currentWeek, setCurrentWeek] = useState(moment(currentYear).isoWeek());
  const [allWeeks, setAllWeeks] = useState(
    moment(currentYear).isoWeeksInYear()
  );
  const [validatedInput, setValidatedInput] = useState(true);

  const [taksData, setTaskData] = useState([]);
  const [newTask, setNewTask] = useState({
    descripcion_tarea: "",
    fecha_tarea: "",
    tipo_tarea: "",
  });
  const [filterTask, setFilterTask] = useState({
    checkCapacitacion: true,
    checkDocumentacion: true,
    checkMantenimiento: true,
  });
  const [datePdf, setDatePdf] = useState({
    fechaInical: "",
    fechaFinal: "",
  });

  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [showPdf, setShowPdf] = useState(false);
  const [validatedPdf, setValidatedPdf] = useState(false);

  const settings = {
    dots: false,
    centerMode: true,
    centerPadding: "60px",
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    swipeToSlide: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    initialSlide: currentWeek - 1,
    beforeChange: (current, next) => {
      setCurrentWeek(next + 1);
      if (validatedInput) {
        if (current === 51 && next === 0) {
          let year = moment(currentYear).year() + 1;
          let newDate = moment(year + "-01-19")
            .isoWeek(next + 1)
            .day(1);
          newDate = new Date(newDate);
          setCurrentYear(newDate);
        } else if (current === 0 && next === 51) {
          let year = moment(currentYear).year() - 1;
          let newDate = moment(year + "-01-19")
            .isoWeek(next + 1)
            .day(1);
          newDate = new Date(newDate);
          setCurrentYear(newDate);
        } else {
          let newDate = moment(currentYear)
            .isoWeek(next + 1)
            .day(1);
          setCurrentYear(new Date(newDate));
        }
      }
    },
  };

  const queryTask = async (currentYearAux, weekAux, filterTaskAux) => {
    try {
      const access_token = new Cookies().get(
        "perv_v2_cookies_user_kdfoeinapjdkfawep"
      );
      const date = moment(currentYearAux || currentYear);
      const year = date.year();
      let taskFilter = filterTaskAux || filterTask;
      const { data } = await axios.post(
        process.env.REACT_APP_SERVIDOR + "/api/v2/list/tareas/mes",
        {
          date,
          year,
          week: weekAux || currentWeek,
          ...taskFilter,
        },
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      setTaskData(data);
      setValidatedInput(true);
    } catch (error) {
      console.log("Error while getting tasks: ", error);
    }
  };

  const queryInsertTask = async (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      e.preventDefault();
      try {
        const { data } = await axios.post(
          process.env.REACT_APP_SERVIDOR + "/api/v2/insert/tarea",
          newTask,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
              Permission: "1",
            },
          }
        );

        if (data.message === "success") {
          queryTask(currentYear, currentWeek, filterTask);
          setShow(false);
          setNewTask({
            descripcion_tarea: "",
            fecha_tarea: "",
          });
          setValidated(false);
        }
      } catch (error) {
        if (error.response.status === 403) {
          Swal.fire({
            icon: "error",
            title: "No tienes permsios para esta acción",
          });
        }
        console.log("Error inserting task: " + error);
      }
    }
  };

  const onChangeNewTask = (e) => {
    const { name, value } = e.target;
    setNewTask({
      ...newTask,
      [name]: value,
    });
  };

  const onChangeFilterTask = (e) => {
    const { name, checked } = e.target;
    setFilterTask({
      ...filterTask,
      [name]: checked,
    });
  };

  const modalInsertTask = (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header>
        <Modal.Title>Añadir Tarea</Modal.Title>
      </Modal.Header>
      <Modal.Body className="mx-2">
        <Form noValidate validated={validated} onSubmit={queryInsertTask}>
          <Form.Group className="mb-3">
            <Form.Label>Tipo Tarea</Form.Label>
            <Form.Select name="tipo_tarea" required onChange={onChangeNewTask}>
              <option value="">Selecciona</option>
              <option value="Documentación">Documentación</option>
              <option value="Capacitación">Capacitación</option>
              <option value="Mantenimiento">Mantenimiento</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Descripción Tarea</Form.Label>
            <Form.Control
              name="descripcion_tarea"
              as="textarea"
              row={4}
              required
              placeholder="Descripción Tarea"
              value={newTask.descripcion_tarea}
              onChange={onChangeNewTask}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Fecha Tarea</Form.Label>
            <Form.Control
              name="fecha_tarea"
              type="date"
              required
              placeholder="Descripción Tarea"
              value={newTask.fecha_tarea}
              onChange={onChangeNewTask}
            />
          </Form.Group>
          <Button variant="dark" type="submit">
            Añadir
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );

  const downloadPdf = async (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidatedPdf(true);
    } else {
      e.preventDefault();
      try {
        const { data } = await axios.post(
          process.env.REACT_APP_SERVIDOR +
            `/api/v2/download/pdf/tarea/${datePdf.fechaInical}/${datePdf.fechaFinal}`,
          { filterTask },
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
              Permission: "1",
            },
            responseType: "arraybuffer",
          }
        );
        setValidatedPdf(false);
        setShowPdf(false);
        setDatePdf({
          fechaInical: "",
          fechaFinal: "",
        });
        const blob = new Blob([data], { type: "application/pdf;charset=utf8" });
        saveFile(
          blob,
          `TAREAS-${datePdf.fechaInical}-${datePdf.fechaFinal}.pdf`
        );
      } catch (error) {
        if (error.response.statusCode === 403) {
          Swal.fire({
            icon: "error",
            title: "No tienes permsios para esta acción",
          });
        }
      }
    }
  };

  const onChangeDatePdf = (e) => {
    const { name, value } = e.target;
    setDatePdf({
      ...datePdf,
      [name]: value,
    });
  };

  useEffect(() => {
    queryTask(currentYear, currentWeek, filterTask);
  }, [currentYear, currentWeek, filterTask]);

  return (
    <Container className="text-center mt-3">
      <Row className="mb-3">
        <Col>
          <h3>CALENDARIO</h3>
          <Modal
            show={showPdf}
            onHide={() => {
              setShowPdf(false);
              setValidatedPdf(false);
              setDatePdf({
                fechaInical: "",
                fechaFinal: "",
              });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Descargar Pdf</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ margin: "5px" }}>
              <Form noValidate validated={validatedPdf} onSubmit={downloadPdf}>
                <Form.Group className="mb-3">
                  <Form.Label>Fecha Inicial</Form.Label>
                  <Form.Control
                    required
                    type="date"
                    name="fechaInical"
                    onChange={onChangeDatePdf}
                  />
                  <Form.Control.Feedback type="invalid">
                    Obligaotorio
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Fecha Final</Form.Label>
                  <Form.Control
                    required
                    type="date"
                    name="fechaFinal"
                    onChange={onChangeDatePdf}
                  />
                  <Form.Control.Feedback type="invalid">
                    Obligaotorio
                  </Form.Control.Feedback>
                </Form.Group>
                <Button variant="dark" type="submit">
                  Descargar PDF
                </Button>
              </Form>
            </Modal.Body>
          </Modal>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs="6" md="1" className="text-start pl-3 mb-2">
          <Button variant="success" onClick={() => setShow(true)}>
            Añadir
          </Button>
        </Col>
        <Col xs="6" md="4" className="mb-2">
          <Button onClick={() => setShowPdf(true)}>Descargar Tareas</Button>
        </Col>
        {modalInsertTask}
        <Col xs="12" md="2" className="mb-2">
          <DatePicker
            selected={currentYear}
            dateFormat="dd/MM/yyyy"
            onChange={(date) => {
              setCurrentYear(date);
              setCurrentWeek(moment(date).isoWeek());
              setAllWeeks(moment(date).isoWeeksInYear());
              setValidatedInput(false);
              refSlick.current.slickGoTo(moment(date).isoWeek() - 1);
            }}
            customInput={<CustomInputDate />}
          />
        </Col>
        <Col
          xs="12"
          md="5"
          className="text-sm-center text-md-end pl-3 justify-content-right mb-2"
        >
          <Form.Check
            inline
            label="Capacitación"
            name="checkCapacitacion"
            type="checkbox"
            id="inline-checkbox-1"
            checked={filterTask.checkCapacitacion}
            onChange={onChangeFilterTask}
          />
          <Form.Check
            inline
            label="Documentación"
            name="checkDocumentacion"
            type="checkbox"
            id="inline-checkbox-2"
            checked={filterTask.checkDocumentacion}
            onChange={onChangeFilterTask}
          />
          <Form.Check
            inline
            label="Mantenimiento"
            name="checkMantenimiento"
            type="checkbox"
            id="inline-checkbox-3"
            checked={filterTask.checkMantenimiento}
            onChange={onChangeFilterTask}
          />
        </Col>
      </Row>
      <Slider ref={refSlick} {...settings}>
        {Array.from({ length: allWeeks }).map((item, i) => (
          <div key={i}>
            <CardWeek props={{ week: i, data: taksData, queryTask }} />
          </div>
        ))}
      </Slider>
    </Container>
  );
}

export default Calendar;
