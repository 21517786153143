export const updateDocumentService =
  (fetch) =>
  async ({ documentId, documentName, file }) => {
    try {
      const formData = new FormData();
      formData.append("pdf_document_institucional", file);

      const response = await fetch({
        url: `/api/v2/insert/documentos/compania/${documentName}/${documentId}`,
        method: "post",
        body: formData,
        permission: "1",
      });

      return response;
    } catch (error) {
      throw new Error(error.response?.data?.message || error.message);
    }
  };
