import Swal from "sweetalert2";
import { useFetch } from "../../../hooks/useFetch";
import { uploadClaimsCostsService } from "../services/uploadClaimsCosts.service";
import { uploadLegalRepVerificationService } from "../services/uploadLegalRepVerification.service";
import { uploadSharedLessonsService } from "../services/uploadSharedLessons.service";
import { deleteClaimsCostsService } from "../services/deleteClaimsCosts.service";
import { deleteLegalRepVerificationService } from "../services/deleteLegalRepVerification.service";
import { deleteSharedLessonsService } from "../services/deleteSharedLessons.service";

export const useDocumentsRoadAccidentsCommand = (refresh) => {
  const fetchData = useFetch();

  const uploadDocument = async ({ documentName, file }) => {
    try {
      let response = null;
      if (documentName === "claimsCosts") {
        response = await uploadClaimsCostsService(fetchData)({ file });
      }

      if (documentName === "legalRepVerification") {
        response = await uploadLegalRepVerificationService(fetchData)({ file });
      }

      if (documentName === "sharedLessons") {
        response = await uploadSharedLessonsService(fetchData)({ file });
      }

      refresh?.();
      Swal.fire({
        icon: "success",
        title: "Documento subido",
        text: response?.message,
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message,
      });
      throw error;
    }
  };

  const deleteDocument = async ({ documentName }) => {
    try {
      let response = null;

      if (documentName === "claimsCosts") {
        response = await deleteClaimsCostsService(fetchData)();
      }

      if (documentName === "legalRepVerification") {
        response = await deleteLegalRepVerificationService(fetchData)();
      }

      if (documentName === "sharedLessons") {
        response = await deleteSharedLessonsService(fetchData)();
      }

      refresh?.();
      Swal.fire({
        icon: "success",
        title: "Documento eliminado",
        text: response?.message,
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message,
      });
      throw error;
    }
  };

  return { uploadDocument, deleteDocument };
};
