import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Modal,
  Button,
  Form,
} from "react-bootstrap";
import axios from "axios";
import Cookies from "universal-cookie";

import CardVehiculos from "../components/CardVehiculos";
import img from "../img/img_agregar_plus_250x250.png";
import Swal from "sweetalert2";

function HojaVidaVehiculos() {
  const cookie = new Cookies();
  const access_token = cookie.get("perv_v2_cookies_user_kdfoeinapjdkfawep");

  const [showModalInsert, setShowModalInsert] = useState(false);
  const [dataCars, setDataCars] = useState([]);
  const [dataCentros, setDataCentros] = useState([]);
  const [infoCar, setInfoCar] = useState({
    placa: "",
    tipo_vehiculo: "",
    promedio_dia: "",
    id_centro: "",
    propietario: "",
  });
  const [tipoVehiculo, setTipoVehiculo] = useState([]);
  const [imgCar, setImgCar] = useState(false);

  const queryData = async () => {
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_SERVIDOR + "/api/v2/list/vehiculos/mantenimiento",
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      setDataCars(data);
    } catch (error) {
      console.log("Error while fetching data from server: " + error.message);
    }
  };

  const queryCentros = async () => {
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_SERVIDOR + "/api/v2/list/centro",
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      setDataCentros(data);
    } catch (error) {
      console.log(
        "Error while fetching data centro from server: " + error.message
      );
    }
  };
  const queryTipoVehiculo = async () => {
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_SERVIDOR + "/api/v2/listar/vehiculos",
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      setTipoVehiculo(data.datos);
    } catch (error) {
      console.log(
        "Error while fetching data centro from server: " + error.message
      );
    }
  };

  const queryInsertVehiculos = async (e) => {
    e.preventDefault();

    // Construye el formdata para enviar la imagen del vehiculo
    let formData = new FormData();
    formData.append("formData", imgCar);

    try {
      if (infoCar.placa === "") {
        Swal.fire({
          icon: "error",
          title: "La placa es obligatorio",
        });
      } else if (infoCar.tipo_vehiculo === "") {
        Swal.fire({
          icon: "error",
          title: "El tipo de vehículo es obligatorio",
        });
      } else if (infoCar.promedio_dia === "") {
        Swal.fire({
          icon: "error",
          title: "Promedio día es obligatorio",
        });
      } else if (
        infoCar.id_centro === "no existe" ||
        infoCar.id_centro === ""
      ) {
        Swal.fire({
          icon: "error",
          title: "Centro es obligatorio",
        });
      } else if (infoCar.propietario === "") {
        Swal.fire({
          icon: "error",
          title: "Propietario es obligatorio",
        });
      } else {
        const { data } = await axios.post(
          process.env.REACT_APP_SERVIDOR +
            "/api/v2/insert/vehiculos/mantenimiento",
          infoCar,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
              Permission: "1",
            },
          }
        );
        // const { data1 } = await axios.post(
        //   process.env.REACT_APP_SERVIDOR +
        //     "/api/v2/insert/vehiculos/mantenimiento1",
        //   infoCar,
        //   {
        //     headers: {
        //       Authorization: `Bearer ${access_token}`,
        //       Permission: "1",
        //     },
        //   }
        // );
        if (imgCar) {
          await axios.post(
            process.env.REACT_APP_SERVIDOR +
              `/api/v2/insert/img/vehiculos/mantenimiento/${infoCar.placa}/${infoCar.id_centro}`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
                Permission: "1",
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
              },
            }
          );
        }
        if (data.message === "success") {
          setShowModalInsert(false);
          setInfoCar({
            placa: "",
            promedio_dia: "",
            id_centro: "",
          });
          setImgCar(false);
          queryData();
        } else {
          Swal.fire({
            icon: "info",
            titulo: "Mensaje",
            title: data.message,
          });
        }
      }
    } catch (error) {
      if (error.response.status === 403) {
        Swal.fire({
          icon: "error",
          title: "No tienes permiso para esta acción",
        });
      }
      console.log("Error while inserting data car server: " + error.message);
    }
  };

  const handleCloseModalInsert = () => {
    setShowModalInsert(false);
  };

  const onChangeInfoCar = (e) => {
    const { name, value } = e.target;
    setInfoCar({
      ...infoCar,
      [name]: value,
    });
  };

  const onChangeImg = (e) => {
    setImgCar(e.target.files[0]);
  };

  const modalInsert = (
    <Modal
      show={showModalInsert}
      onHide={handleCloseModalInsert}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1>Información Vehículo</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ margin: "2%" }}>
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Placa</Form.Label>
            <Form.Control
              name="placa"
              type="text"
              placeholder="Placa"
              onChange={onChangeInfoCar}
              autoFocus
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Tipo de Vehículo</Form.Label>
            <Form.Select
              placeholder="tipo_vehiculo"
              name="tipo_vehiculo"
              onChange={onChangeInfoCar}
            >
              <option value="no existe">Elige el tipo</option>

              {tipoVehiculo.map((item, i) => (
                <option key={i} value={item.id}>
                  {item.nombre}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Promedio de kilometros/horas por día</Form.Label>
            <Form.Control
              name="promedio_dia"
              type="text"
              placeholder="Promedio de día"
              onChange={onChangeInfoCar}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Centro</Form.Label>
            <Form.Select
              name="id_centro"
              placeholder="Centro"
              onChange={onChangeInfoCar}
            >
              <option value="no existe">Elige centro</option>
              {dataCentros.map((item, i) => (
                <option key={i} value={item.id_centro}>
                  {item.centro_descripcion}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Propietario</Form.Label>
            <Form.Select name="propietario" onChange={onChangeInfoCar}>
              <option value="">Elige un propietario</option>
              <option value="PROPIO">PROPIO</option>
              <option value="COLABORADOR">COLABORADOR</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Imagen</Form.Label>
            <Form.Control
              name="img_carro"
              type="file"
              placeholder="Imagen"
              onChange={onChangeImg}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModalInsert}>
          Cerrar
        </Button>
        <Button variant="primary" onClick={(e) => queryInsertVehiculos(e)}>
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  );

  useEffect(() => {
    queryTipoVehiculo();
    queryData();
    queryCentros();
  }, []);

  return (
    <Container className="text-center mt-2">
      <Row>
        <h1>VEHÍCULOS</h1>
      </Row>
      <Row lg={3} md={2} xs={1}>
        {dataCars.map((item, i) => (
          <Col key={i} className="mb-5" style={{ maxHeight: "300px" }}>
            <CardVehiculos
              props={{
                id: item.id_vehiculo,
                tipo: item.tipo_vehiculo_fk,
                name: item.placa,
                centro: item.nombre_centro,
                img: item.img_carro,
                data: item,
                queryData: queryData,
                id_centro: item.id_centro_fk,
              }}
            />
          </Col>
        ))}
        <Col className="mb-5" style={{ maxHeight: "300px" }}>
          <Card
            className="mx-auto"
            style={{ width: "18rem", height: "100%" }}
            onClick={() => setShowModalInsert(true)}
          >
            <Card.Img variant="top" src={img} style={{ padding: "8%" }} />
          </Card>
          {modalInsert}
        </Col>
      </Row>
    </Container>
  );
}
export default HojaVidaVehiculos;
