import "./assets/index.css";
import { useInvestigationAccidentCommand } from "./hooks/useInvestigationAccidentCommand";
import Textfield from "../../common/components/Textfiled";
import Select from "../../common/components/Select";
import {
  OPTIONS_AFFECTED_BODY_PART,
  OPTIONS_BEHAVIOR_OR_CONDITION,
  OPTIONS_INCIDENT_MECHANISM,
  OPTIONS_INJURY_AGENT,
  OPTIONS_INJURY_TYPE,
} from "./constants/constants";
import Button from "../../common/components/Button";
import { useRef } from "react";

function RegisterInvestigation() {
  const {
    investigation,
    handleChangeInvestigation,
    errors,
    createInvestigationAccident,
  } = useInvestigationAccidentCommand();
  const refInputLessonLearned = useRef(null);
  const refInputEventDetails = useRef(null);
  const refInputLessonDisclosureEvidence = useRef(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    createInvestigationAccident(investigation).then(() => {
      refInputLessonLearned.current.value = "";
      refInputEventDetails.current.value = "";
      refInputLessonDisclosureEvidence.current.value = "";
    });
  };

  return (
    <main className="main-container">
      <header className="header-container">
        <h1 className="header-title">Registrar reporte de siniestros viales</h1>
      </header>
      <section className="section-container">
        <form onSubmit={handleSubmit} className="form-container">
          <Textfield
            label="Fecha del comite donde fue analizado"
            type="date"
            placeholder="Fecha del comite donde fue analizado"
            name="committeeAnalysisDate"
            value={investigation.committeeAnalysisDate}
            onChange={handleChangeInvestigation}
            error={errors.errors.committeeAnalysisDate}
          />
          <Textfield
            label="Costos directos de fatalidades"
            type="number"
            placeholder="Costos directos de fatalidades"
            name="directFatalityCosts"
            value={investigation.directFatalityCosts}
            onChange={handleChangeInvestigation}
            error={errors.errors.directFatalityCosts}
          />
          <Textfield
            label="Costos indirectos de fatalidades"
            type="number"
            placeholder="Costos indirectos de fatalidades"
            name="indirectFatalityCosts"
            value={investigation.indirectFatalityCosts}
            onChange={handleChangeInvestigation}
            error={errors.errors.indirectFatalityCosts}
          />

          <Textfield
            label="Costos directos de heridos graves"
            type="number"
            placeholder="Costos directos de heridos graves"
            name="directSevereInjuryCosts"
            value={investigation.directSevereInjuryCosts}
            onChange={handleChangeInvestigation}
            error={errors.errors.directSevereInjuryCosts}
          />

          <Textfield
            label="Costos indirectos de heridos graves"
            type="number"
            placeholder="Costos indirectos de heridos graves"
            name="indirectSevereInjuryCosts"
            value={investigation.indirectSevereInjuryCosts}
            onChange={handleChangeInvestigation}
            error={errors.errors.indirectSevereInjuryCosts}
          />

          <Textfield
            label="Costos directos de heridos leves"
            type="number"
            placeholder="Costos directos de heridos leves"
            name="directMinorInjuryCosts"
            value={investigation.directMinorInjuryCosts}
            onChange={handleChangeInvestigation}
            error={errors.errors.directMinorInjuryCosts}
          />

          <Textfield
            label="Costos indirectos de heridos leves"
            type="number"
            placeholder="Costos indirectos de heridos leves"
            name="indirectMinorInjuryCosts"
            value={investigation.indirectMinorInjuryCosts}
            onChange={handleChangeInvestigation}
            error={errors.errors.indirectMinorInjuryCosts}
          />

          <Textfield
            label="Costos directos de choques simples"
            type="number"
            placeholder="Costos directos de choques simples"
            name="directSimpleCollisionCosts"
            value={investigation.directSimpleCollisionCosts}
            onChange={handleChangeInvestigation}
            error={errors.errors.directSimpleCollisionCosts}
          />

          <Textfield
            label="Costos indirectos de choques simples"
            type="number"
            placeholder="Costos indirectos de choques simples"
            name="indirectSimpleCollisionCosts"
            value={investigation.indirectSimpleCollisionCosts}
            onChange={handleChangeInvestigation}
            error={errors.errors.indirectSimpleCollisionCosts}
          />

          <Textfield
            label="Comprobante de egreso o pago a tercero por concepto de accidente"
            type="text"
            placeholder="Comprobante de pago de accidente"
            name="accidentPaymentProof"
            value={investigation.accidentPaymentProof}
            onChange={handleChangeInvestigation}
            error={errors.errors.accidentPaymentProof}
          />

          <Select
            label="Agente de lesión"
            name="injuryAgent"
            value={investigation.injuryAgent}
            onChange={handleChangeInvestigation}
            error={errors.errors.injuryAgent}
            options={OPTIONS_INJURY_AGENT}
          />

          <Select
            label="Comportamiento o condición"
            name="behaviorOrCondition"
            value={investigation.behaviorOrCondition}
            onChange={handleChangeInvestigation}
            error={errors.errors.behaviorOrCondition}
            options={OPTIONS_BEHAVIOR_OR_CONDITION}
          />

          <Select
            label="Mecanismo de siniestros"
            name="incidentMechanism"
            value={investigation.incidentMechanism}
            onChange={handleChangeInvestigation}
            error={errors.errors.incidentMechanism}
            options={OPTIONS_INCIDENT_MECHANISM}
          />

          <Textfield
            label="Días de discapacidad"
            type="number"
            placeholder="Días de discapacidad"
            name="disabilityDays"
            value={investigation.disabilityDays}
            onChange={handleChangeInvestigation}
            error={errors.errors.disabilityDays}
          />

          <Select
            label="Tipo de lesión"
            name="injuryType"
            value={investigation.injuryType}
            onChange={handleChangeInvestigation}
            error={errors.errors.injuryType}
            options={OPTIONS_INJURY_TYPE}
          />

          <Select
            label="Parte del cuerpo afectada"
            name="affectedBodyPart"
            value={investigation.affectedBodyPart}
            onChange={handleChangeInvestigation}
            error={errors.errors.affectedBodyPart}
            options={OPTIONS_AFFECTED_BODY_PART}
          />

          <Textfield
            label="Acción correctiva"
            type="text"
            placeholder="Acción correctiva"
            name="correctiveAction"
            value={investigation.correctiveAction}
            onChange={handleChangeInvestigation}
            error={errors.errors.correctiveAction}
          />

          <Textfield
            label="Responsable de la acción"
            type="text"
            placeholder="Responsable de la acción"
            name="actionResponsible"
            value={investigation.actionResponsible}
            onChange={handleChangeInvestigation}
            error={errors.errors.actionResponsible}
          />

          <Textfield
            label="Fecha de ejecución de la acción"
            type="date"
            placeholder="Fecha de ejecución de la acción"
            name="actionExecutionDate"
            value={investigation.actionExecutionDate}
            onChange={handleChangeInvestigation}
            error={errors.errors.actionExecutionDate}
          />

          <Textfield
            label="Detalles del evento"
            type="file"
            placeholder="Detalles del evento"
            name="eventDetails"
            reference={refInputEventDetails}
            onChange={({ name, file }) =>
              handleChangeInvestigation({ name, value: file })
            }
            error={errors.errors.eventDetails}
          />

          <Textfield
            label="Lección aprendida"
            type="file"
            placeholder="Lección aprendida"
            name="lessonLearned"
            reference={refInputLessonLearned}
            onChange={({ name, file }) =>
              handleChangeInvestigation({ name, value: file })
            }
            error={errors.errors.lessonLearned}
          />

          <Textfield
            label="Evidencia de divulgación de lección"
            type="file"
            reference={refInputLessonDisclosureEvidence}
            placeholder="Evidencia de divulgación de lección"
            name="lessonDisclosureEvidence"
            onChange={({ name, file }) =>
              handleChangeInvestigation({ name, value: file })
            }
            error={errors.errors.lessonDisclosureEvidence}
          />

          <footer className="form-footer">
            <Button>Registrar</Button>
          </footer>
        </form>
      </section>
    </main>
  );
}

export default RegisterInvestigation;
