import { Container, Form, Row, Col, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import axios from "axios";
import Swal from "sweetalert2";

function Datos_Configuracion() {
  const cookie = new Cookies();
  const access_token = cookie.get("perv_v2_cookies_user_kdfoeinapjdkfawep");

  const [dataCPKAvailability, setDataCPKAvailability] = useState({
    cpk: "0",
    availability: "0",
  });

  const onChangeDatos = (e) => {
    const { name, value } = e.target;
    setDataCPKAvailability({
      ...dataCPKAvailability,
      [name]: value,
    });
  };

  async function consultarInformacion() {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_SERVIDOR + "/api/v2/availability/CPK",
        "",
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      setDataCPKAvailability(data);
    } catch (error) {
      console.log(
        "Error while fetching data CPK availability: " + error.message
      );
    }
  }

  async function registrarInformacion() {
    if (dataCPKAvailability.cpk === "") {
      Swal.fire({
        icon: "error",
        title: "El CPK es obligatorio",
      });
    } else if (dataCPKAvailability.availability === "") {
      Swal.fire({
        icon: "error",
        title: "La disponibilidad es obligatoria",
      });
    } else {
      let jsonData = {
        cpk: dataCPKAvailability.cpk,
        disponibilidad: dataCPKAvailability.availability,
      };

      const { data } = await axios.post(
        process.env.REACT_APP_SERVIDOR + "/api/v2/insert/datos/configuracion",
        jsonData,
        {
          headers: {
            Authorization: "Bearer " + access_token,
            Permission: "1",
          },
        }
      );
      if (data["message"] === "error") {
        Swal.fire({
          icon: "error",
          title: "Error al registrar la información",
        });
      } else {
        Swal.fire({
          icon: "success",
          title: "Registro exitoso",
        });
      }

      await consultarInformacion();
    }
  }

  useEffect(() => {
    consultarInformacion();
  }, []);

  return (
    <Container>
      <h1>Datos Configuración</h1>

      <Form>
        <Row className="justify-content-md-center">
          <Col xs={6}>
            <Form.Group>
              <Form.Label column sm={2}>
                CPK:{" "}
              </Form.Label>
              <Form.Control
                type="number"
                name="cpk"
                value={dataCPKAvailability.cpk}
                placeholder="Ingrese el valor del CPK"
                onChange={onChangeDatos}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col xs={6}>
            <Form.Group>
              <Form.Label column sm={2}>
                Disponibilidad:{" "}
              </Form.Label>
              <Form.Control
                type="number"
                name="availability"
                value={dataCPKAvailability.availability}
                placeholder="Ingrese el valor de la disponibilidad"
                onChange={onChangeDatos}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col xs={2}>
            <br />
            <Button variant="dark" onClick={() => registrarInformacion()}>
              Registrar
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}

export default Datos_Configuracion;
