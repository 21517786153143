import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container, Col, Row, Form, Button, Modal } from "react-bootstrap";

import Cookies from "universal-cookie";
import XLSX from "sheetjs-style";
import moment from "moment";
import { BsFillTrashFill, BsChevronDown } from "react-icons/bs";
import { RiFileEditFill } from "react-icons/ri";

import Swal from "sweetalert2";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionActions from "@mui/material/AccordionActions";
import Typography from "@mui/material/Typography";
import { saveFile } from "../common/utils/saveFile.util";

function InspeccionVehiculos() {
  const access_token = new Cookies().get(
    "perv_v2_cookies_user_kdfoeinapjdkfawep"
  );
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [validated, setValidated] = useState(false);
  const [surveyValidated, setSurveyValidated] = useState(false);
  const [showSurvey, setShowSurvey] = useState(false);
  const [, setShowExportExcel] = useState(false);
  const [requiredDataSurvey, setrequiredDataSurvey] = useState({
    groupQuestion: "",
    warning: "",
    placa: "",
    initialDate: moment().format("YYYY-MM-DD"),
    finalDate: moment().format("YYYY-MM-DD"),
  });
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 0,
  });
  const [, setInfoExportExcel] = useState({
    groupQuestion: "",
    warning: "",
    placa: "",
    initialDate: "",
    finalDate: "",
  });
  const cookie = new Cookies();
  const nombre_user = cookie.get("nombre_user");

  const perfil = cookie.get("perfil");
  const [dataSurveys, setDataSurveys] = useState([]);
  const [, setDataColor] = useState([]);
  const [headersTable, setHeadersTable] = useState([]);
  const [surveyUpdate, setSurveyUpdate] = useState([]);
  const [showModalInsert, setShowModalInsert] = useState(false);
  const [showModalFile, setShowModalFile] = useState(false);
  const [data, setData] = useState([]);
  const [tipoVehiculo, setTipoVehiculo] = useState([]);
  const [, setaprobo] = useState(false);

  const [, setTipoVehiculoPlaca] = useState([]);

  const onChangeRequiredDataSurvey = (e) => {
    const { name, value } = e.target;
    setrequiredDataSurvey({
      ...requiredDataSurvey,
      [name]: value,
    });
  };

  const [infoInsertFile, setInfoInsertFile] = useState({ file: "" });

  const validityForm = (e, page) => {
    let validacion = "";
    if (e !== null) {
      const form = e.currentTarget;
      validacion = form.checkValidity();
    } else {
      validacion = false;
    }

    if (validacion === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      e.preventDefault();
      querySurveys(page);
    }
  };

  const querySurveys = async (page) => {
    try {
      const { groupQuestion, initialDate, finalDate, warning, placa } =
        requiredDataSurvey;
      const { data } = await axios.get(
        process.env.REACT_APP_SERVIDOR +
          `/api/v2/list/surveys?groupQuestion=${groupQuestion}&initialDate=${initialDate}&finalDate=${finalDate}&warning=${warning}&page=${
            page || pagination.currentPage
          }&placa=${placa}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      const headersTableAux = [];
      /*for (let key in data.data[0]) {
        headersTableAux.push(key);
      }*/

      let objectWithMostKeys = data.data.reduce((maxObject, currentObject) => {
        return Object.keys(currentObject).length > Object.keys(maxObject).length
          ? currentObject
          : maxObject;
      }, {});
      for (let key in objectWithMostKeys) {
        headersTableAux.push(key);
      }
      console.log("headersTableAux");
      console.log(headersTableAux);
      setHeadersTable(headersTableAux);
      setDataSurveys(data.data);
      setDataColor(data.dataColor);

      console.log("data.data");
      console.log(data.data);
      console.log(headersTable);
      setPagination({
        currentPage: page || pagination.currentPage,
        totalPages: data.pages,
      });
      setValidated(false);
    } catch (error) {
      console.log("Error query survays: " + error.message);
    }
  };

  const queryUpdateSurvey = async (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setSurveyValidated(true);
    } else {
      e.preventDefault();
      try {
        const { data } = await axios.put(
          process.env.REACT_APP_SERVIDOR + "/api/v2/update/survey",
          surveyUpdate,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
              Permission: "1",
            },
          }
        );

        if (data.message === "success") {
          setShowSurvey(false);
          setSurveyValidated(false);
          querySurveys(pagination.currentPage);
        }
      } catch (error) {
        console.log("Error updated survey: " + error.message);
      }
    }
  };
  const queryUpdateApproveSurvey = async (e) => {
    try {
      const { data } = await axios.put(
        process.env.REACT_APP_SERVIDOR + "/api/v2/update/approvesurvey",
        surveyUpdate,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            Permission: "1",
            nombre_usuario: nombre_user,
            tipo_perfil: perfil,
          },
        }
      );

      if (data.message === "success") {
        setShowSurvey(false);
        setSurveyValidated(false);
        querySurveys(pagination.currentPage);
      }
    } catch (error) {
      console.log("Error updated survey: " + error.message);
    }
  };
  /*
  const queryUpdateApproveSurvey = async e => {
    
      e.preventDefault();
      try {
        const { data } = await axios.put(
          process.env.REACT_APP_SERVIDOR + '/api/v2/update/approvesurvey?consecutivo=${surveyUpdate["Consecutivo"]}&aprobo=${aprobo}',
          surveyUpdate,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
              Permission: '1',
            },
          }
        );

        if (data.message === 'success') {
          setShowSurvey(false);
          setSurveyValidated(false);
          querySurveys(pagination.currentPage);
        }
      } catch (error) {
        console.log('Error updated survey: ' + error.message);
      }
    
  };*/

  const queryExportExcel = async (e) => {
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_SERVIDOR +
          `/api/v2/dowload/excel/survey?initialDate=${requiredDataSurvey.initialDate} 00:00:00&finalDate=${requiredDataSurvey.finalDate} 23:59:59&groupQuestion=${requiredDataSurvey.groupQuestion}&page=${pagination.currentPage}&warning=${requiredDataSurvey.warning}&placa=${requiredDataSurvey.placa}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            nombre_usuario: nombre_user,
            tipo_perfil: perfil,
          },
        }
      );
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

      const ws = XLSX.utils.json_to_sheet(data);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { booktype: "xlsx", type: "array" });
      const file = new Blob([excelBuffer], { type: fileType });
      const filename = `Encuesta_fecha_consulta${moment().format(
        "DD-MM-YYYY"
      )}_${moment().unix()}.xlsx`;
      saveFile(file, filename);
      setShowExportExcel(false);
      setInfoExportExcel({
        groupQuestion: "",
        initialDate: "",
        finalDate: "",
        placa: "",
      });
    } catch (error) {
      console.log("Error exporting Excel file: " + error.message);
    }
  };

  const onChangeSurvey = (e) => {
    const { name, value } = e.target;
    setSurveyUpdate({
      ...surveyUpdate,
      [name]: value,
    });
  };

  const [showApproveModal, setShowApproveModal] = useState(false);
  // Función que se llama al aprobar la encuesta
  const handleApprove = () => {
    // Lógica para aprobar la encuesta
    console.log("Encuesta aprobada" + surveyUpdate["Consecutivo"]);
    setaprobo(1);
    queryUpdateApproveSurvey();
    // Aquí puedes actualizar el estado, enviar datos a la API, etc.
  };

  // Función que se llama al rechazar la encuesta
  const handleReject = () => {
    // Lógica para rechazar la encuesta
    setaprobo(0);

    //queryUpdateApproveSurvey();

    console.log("Encuesta rechazada");
    // Aquí puedes actualizar el estado, enviar datos a la API, etc.
  };

  const ModalApproveSurvey = ({
    showApproveModal,
    setShowApproveModal,
    onApprove,
    onReject,
  }) => {
    return (
      <Modal
        show={showApproveModal}
        onHide={() => setShowApproveModal(false)}
        dialogClassName="modal-60w" // Tamaño del modal (ajustable)
        aria-labelledby="approve-survey-modal-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="approve-survey-modal-title">
            Aprobar Encuesta
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>¿Está seguro de que desea aprobar esta encuesta?</p>
          <p>Recuerde revisar la información antes de tomar la decisión.</p>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowApproveModal(false)} // Cierra el modal
          >
            Cancelar
          </Button>
          <Button
            variant="success"
            onClick={() => {
              onApprove(); // Llamada a la función de aprobar
              setShowApproveModal(false); // Cerrar el modal
            }}
          >
            Aprobar
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              onReject(); // Llamada a la función de rechazar
              setShowApproveModal(false); // Cerrar el modal
            }}
          >
            Rechazar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const ModalUpdateSurvey = (
    <Modal
      show={showSurvey}
      onHide={() => setShowSurvey(false)}
      dialogClassName="modal-80w"
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header closeButton>
        <Modal.Title>Encuesta</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          id="formularioSurvey"
          noValidate
          validated={surveyValidated}
          onSubmit={queryUpdateSurvey}
        >
          <Row lg={3} md={3} sm={2} xs={2}>
            {headersTable.map((question, i) =>
              i === 0 ? null : (
                <Col key={i} className="mb-2">
                  <Form.Group>
                    <Form.Label>
                      <div
                        className="text-truncate col-12"
                        style={{ maxWidth: "150px" }}
                      >
                        {question}
                      </div>
                    </Form.Label>
                  </Form.Group>
                  <Form.Control
                    name={question}
                    placeholder="Question"
                    value={surveyUpdate[question]}
                    onChange={onChangeSurvey}
                    required
                  />
                </Col>
              )
            )}
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button form="formularioSurvey" variant="dark" type="submit">
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  );
  const handleCloseModalInsert = () => {
    setShowModalInsert(false);
  };
  const onChangeFilePdf = async (e) => {
    let file = e.target.files[0];
    setInfoInsertFile({
      ...infoInsertFile,
      file: file,
    });
  };
  const modalFile = (
    <Modal show={showModalFile} onHide={() => setShowModalFile(false)}>
      <Modal.Body style={{ margin: "2%" }}>
        <Form.Group className="mb-3">
          <Form.Label>File</Form.Label>
          <Form.Control
            type="file"
            accept="application/pdf"
            onChange={onChangeFilePdf}
          />
        </Form.Group>
        <Button onClick={() => queryInserFileCar()}>Guardar</Button>
      </Modal.Body>
    </Modal>
  );

  const modalInsert = (
    <Modal
      show={showModalInsert}
      onHide={handleCloseModalInsert}
      backdrop="static"
    >
      <Modal.Body style={{ margin: "2%" }}>
        <Container>
          <Row>
            <Col xs={3} md={3}>
              PROCEDIMIENTO
            </Col>
            <Col xs={7} md={7} style={{ color: "#f58b84" }}>
              {data === undefined ||
              data.nombre_documentacion === "" ||
              data.nombre_documentacion === null ? (
                "NO HAY INFORME"
              ) : (
                <a
                  target={"new"}
                  href={
                    process.env.REACT_APP_HOST_FTP +
                    "pdf_pesv/" +
                    data.nombre_documentacion
                  }
                >
                  INFORME
                </a>
              )}
            </Col>
            <Col xs={1} md={1}>
              <label htmlFor="btn-file-pdf">
                <RiFileEditFill
                  aria-label="upload pdf"
                  onClick={() => {
                    setShowModalFile(true);
                  }}
                />
              </label>
            </Col>
            <Col xs={1} md={1}>
              <BsFillTrashFill onClick={() => queryDeleteFileFtp()} />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModalInsert}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const queryData = async () => {
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_SERVIDOR +
          "/api/v2/list/formato_archivos_compania",
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      setData(data[0]);
    } catch (error) {
      console.log("Error while fetching data from server: " + error.message);
    }
  };

  const queryInserFileCar = async () => {
    let formData = new FormData();
    formData.append("filePdfVehiculos", infoInsertFile.file);
    try {
      if (infoInsertFile.file === "" || infoInsertFile.file === null) {
        Swal.fire({
          icon: "error",
          title: "Debes elegir un archivo",
        });
      } else if (infoInsertFile.fecha === "") {
        Swal.fire({
          icon: "error",
          title: "Debes elegir una fecha de vencimiento",
        });
      } else {
        let pagina = "Inspeccion_Vehiculo";
        const { data } = await axios.post(
          process.env.REACT_APP_SERVIDOR +
            `/api/v2/files/formato_archivos_compania/${pagina}`,
          formData,
          {
            headers: {
              Permission: "1",
              Authorization: `Bearer ${access_token}`,
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (data.message === "success") {
          setShowModalFile(false);
          setInfoInsertFile({ file: "" });
          queryData();
        }
      }
    } catch (error) {
      if (error.response.status === 403) {
        Swal.fire({
          icon: "error",
          title: "No tienes permiso para esta acción",
        });
      }
    }
  };
  const queryDeleteFileFtp = async () => {
    try {
      let pagina = { nombre: "Inspeccion_Vehiculo" };

      const { data } = await axios.post(
        process.env.REACT_APP_SERVIDOR +
          `/api/v2/files/delete/formato_archivos_compania/`,
        pagina,
        {
          headers: {
            Permission: "1",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (data.message === "success") {
        queryData();
      }
    } catch (error) {
      if (error.response.status === 403) {
        Swal.fire({
          icon: "error",
          title: "No tienes permiso para esta acción",
        });
      }
      console.log("Error while deleting file from ftp: " + error.message);
    }
  };
  const queryLista = async () => {
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_SERVIDOR + "/api/v2/listar/vehiculos",
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (data.estado) {
        setTipoVehiculo(data.datos);
      }
    } catch (error) {
      console.log(
        "Error while fetching data centro from server: " + error.message
      );
    }
  };
  const queryListaPlaca = async () => {
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_SERVIDOR + `/api/v2/listar/vehiculosPlaca?tipo=3`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (data.estado) {
        setTipoVehiculoPlaca(data.datos);
        console.log("PLACAS");
        console.log(data.datos);
      }
    } catch (error) {
      console.log(
        "Error while fetching data centro from server: " + error.message
      );
    }
  };
  useEffect(() => {
    querySurveys();
    queryLista();
    queryData();
    queryListaPlaca();

    //validityForm(null, "Vehículos de carga");
  }, []);

  return (
    <Container className="mt-3 text-center inspeccion">
      <Row className="mb-3">
        <Col>
          <h2 className="title">INSPECCIÓN VEHÍCULOS</h2>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs="18">
          <Form
            noValidate
            validated={validated}
            onSubmit={(e) => validityForm(e, 1)}
          >
            <Row className="mb-1 align-items-center justify-content-center">
              <Col xs="auto formVehiculo">
                <Form.Label className="fs-6">Grupo de Preguntas</Form.Label>
              </Col>
              <Col xs="auto formVehiculo">
                <Form.Select
                  onChange={onChangeRequiredDataSurvey}
                  name="groupQuestion"
                  required
                  value={requiredDataSurvey.groupQuestion}
                >
                  <option value="">Elige grupo de preguntas</option>
                  {tipoVehiculo.map((fila, id) => (
                    <option key={id} value={fila.id}>
                      {fila.nombre}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col xs="auto formVehiculo">
                <Form.Label className="fs-6">Advertencia</Form.Label>
              </Col>
              <Col xs="auto formVehiculo">
                <Form.Select
                  onChange={onChangeRequiredDataSurvey}
                  name="warning"
                  value={requiredDataSurvey.warning}
                >
                  <option value="" style={{ backgroundColor: "white" }}>
                    Todos
                  </option>
                  <option
                    value="Verde"
                    style={{ backgroundColor: "#a8f584", color: "black" }}
                  >
                    APROBADO
                  </option>
                  <option
                    value="Amarillo"
                    style={{ backgroundColor: "#f9f65b", color: "black" }}
                  >
                    EN PROCESO
                  </option>
                  <option
                    value="Rojo"
                    style={{ backgroundColor: "#f58b84", color: "black" }}
                  >
                    ADVERTENCIA
                  </option>
                </Form.Select>
              </Col>

              <Col xs="auto formVehiculo">
                <Form.Label>Rango de Fechas</Form.Label>
              </Col>
              <Col xs="auto formVehiculo">
                <Form.Control
                  type="date"
                  name="initialDate"
                  value={requiredDataSurvey.initialDate}
                  onChange={onChangeRequiredDataSurvey}
                  autoComplete="off"
                />
              </Col>
              <Col xs="auto formVehiculo">
                <Form.Control
                  type="date"
                  name="finalDate"
                  value={requiredDataSurvey.finalDate}
                  onChange={onChangeRequiredDataSurvey}
                  autoComplete="off"
                />
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs="auto formVehiculo">
                <Form.Label>Placa</Form.Label>
              </Col>
              <Col xs="auto formVehiculo">
                <Form.Control
                  type="text"
                  name="placa"
                  value={requiredDataSurvey.placa}
                  onChange={onChangeRequiredDataSurvey}
                  autoComplete="off"
                />
              </Col>
              <Col xs="auto formVehiculo">
                <Button variant="dark" type="submit">
                  Consultar
                </Button>
              </Col>
              <Col xs="auto formVehiculo">
                <Button
                  variant="dark"
                  onClick={() =>
                    (window.location.href = "/lista-preguntas-preoperacional")
                  }
                >
                  Lista Chequeo Preoperacional
                </Button>
              </Col>
              {/*<Col xs="auto formVehiculo">
                <Button variant="danger"  onClick={() => setShowModalInsert (true)}>
                  Procedimiento
                </Button>
              </Col>*/}
              <Col xs="auto formVehiculo" className="text-end">
                <Button variant="success" onClick={() => queryExportExcel()}>
                  Exportar Excel
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>

      {ModalUpdateSurvey}
      {modalInsert}
      {modalFile}

      <div>
        {dataSurveys.map((survey, index) =>
          index < 0 ? null : (
            <Accordion
              expanded={expanded === index}
              onChange={handleChange(index)}
            >
              <AccordionSummary
                expandIcon={<BsChevronDown />}
                aria-controls="panel1bh-content"
                id="panel1bh-header-${index}"
                sx={{
                  backgroundColor:
                    survey["Estado"] === "Verde"
                      ? Object.keys(survey).length < headersTable.length
                        ? "#f9f65b"
                        : "#a8f584"
                      : survey["Estado"] === "Amarillo"
                      ? "#f9f65b"
                      : "#f58b84",
                  justifyContent: "flex-end",
                }}
              >
                <Typography sx={{ width: "25%", flexShrink: 0 }}>
                  Fecha :{survey["Fecha"]}
                </Typography>
                <Typography
                  sx={{ width: "50%", flexShrink: 0, textAlign: "left" }}
                >
                  Nombre : {survey[headersTable[4]]}
                </Typography>
                <Typography
                  sx={{ width: "15%", flexShrink: 0, textAlign: "left" }}
                >
                  Placa:{" "}
                  {new Date(survey["Fecha"]) >= new Date("2024-11-28T00:00:00")
                    ? survey[headersTable[6]]
                    : survey[headersTable[5]]}
                </Typography>
                <Typography
                  sx={{
                    width: "10%",
                    color: "text.secondary",
                    justifyContent: "flex-end",
                  }}
                >
                  {survey["Estado"] === "Verde"
                    ? Object.keys(survey).length < headersTable.length
                      ? "EN PROCESO"
                      : "APROBADO"
                    : survey["Estado"] === "Amarillo"
                    ? "EN PROCESO"
                    : "ADVERTENCIA"}
                </Typography>
              </AccordionSummary>

              {headersTable.map((header, i) =>
                i < 1 ? null : (
                  <AccordionDetails>
                    <Typography
                      sx={{ color: "text.secondary", textAlign: "left" }}
                    >
                      {header}:
                    </Typography>

                    <Typography
                      sx={{ color: "text.primary", textAlign: "left" }}
                    >
                      {survey[header]}
                    </Typography>
                  </AccordionDetails>
                )
              )}
              <AccordionActions>
                <Button
                  variant="warning"
                  onClick={() => {
                    setShowSurvey(true);
                    setSurveyUpdate(survey);
                  }}
                >
                  Modificar
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    setShowApproveModal(true);
                    setSurveyUpdate(survey);
                  }}
                >
                  Aprobar/Rechazar Encuesta
                </Button>
                <ModalApproveSurvey
                  showApproveModal={showApproveModal}
                  setShowApproveModal={setShowApproveModal}
                  onApprove={handleApprove}
                  onReject={handleReject}
                />
              </AccordionActions>
            </Accordion>
          )
        )}
      </div>
    </Container>
  );
}

export default InspeccionVehiculos;
