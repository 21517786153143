export const deleteDocumentSafeCarService =
  (fetch) =>
  async ({ document }) => {
    try {
      const response = await fetch({
        url: `/api/v2/delete/documents/cars/safe/${document}`,
        method: "delete",
      });

      return response;
    } catch (error) {
      throw new Error(error.response?.data?.message || error.message);
    }
  };
