import React from "react";
import { Bar } from "react-chartjs-2";

function HorizontalBarChart({ data, showLegend }) {
  const hasData =
    data &&
    data.datasets &&
    data.datasets.length > 0 &&
    data.datasets.some((dataset) => dataset.data.some((value) => value > 0));

  const options = {
    indexAxis: "y",
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: Boolean(showLegend),
        position: "top",
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.label}: ${tooltipItem.raw} units`;
          },
        },
      },
    },
  };

  return (
    <div className="w-100 h-100 bg-primar">
      {hasData ? (
        <Bar data={data} options={options} height="500px" />
      ) : (
        <div className="d-flex justify-content-center align-items-center h-100">
          <p>No hay datos para mostrar la gráfica.</p>
        </div>
      )}
    </div>
  );
}

export default HorizontalBarChart;
