export const updateDocumentSafeCarService =
  (fetch) =>
  async ({ documentName, file }) => {
    try {
      console.log("documentName", documentName);
      const formData = new FormData();
      formData.append("pdfVehiculsSeguros", file);
      const response = await fetch({
        url: `/api/v2/insert/document/vehiculos/seguros/${documentName}`,
        method: "post",
        body: formData,
        permission: "1",
      });
      return response;
    } catch (error) {
      throw new Error(error.response?.data?.message || error.message);
    }
  };
