import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

function HeaderItem({ label, to, onClick }) {
  const handleClick = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    onClick?.();
  };
  return (
    <Dropdown.Item className="textoNav" onClick={handleClick}>
      <Link to={to || ""} style={{ textDecoration: "none", color: "#dee2e6" }}>
        {label}
      </Link>
    </Dropdown.Item>
  );
}

export default HeaderItem;
