import Swal from "sweetalert2";
import { useFetch } from "../../../hooks/useFetch";
import { deleteDocumentService } from "../services/deleteDocument.service";
import { updateDocumentService } from "../services/updateDocument.service";

export const useDocumentsCommand = (refresh) => {
  const fetchData = useFetch();

  const updateDocument = async ({ documentId, documentName, file }) => {
    try {
      const response = await updateDocumentService(fetchData)({
        documentId,
        documentName,
        file,
      });

      refresh?.();
      console.log("response", response);
      Swal.fire({
        icon: "success",
        title: "Documento actualizado",
        text: "El documento se ha actualizado correctamente",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message,
      });
      throw error;
    }
  };

  const deleteDocument = async (document) => {
    try {
      const response = await deleteDocumentService(fetchData)(document);
      refresh?.();

      if (response.message === "no exist") {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "El documento no existe",
        });
        return;
      }

      Swal.fire({
        icon: "success",
        title: "Documento eliminado",
        text: "El documento se ha eliminado correctamente",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message,
      });
      throw error;
    }
  };

  return { updateDocument, deleteDocument };
};
