import { Form } from "react-bootstrap";

function Select({ label, name, value, onChange, options, required, error }) {
  const handleChange = (event) => {
    const { name, value } = event.target;
    onChange({ name, value });
  };

  return (
    <div className="mb-3">
      <Form.Group>
        <Form.Label>{label}</Form.Label>
        <Form.Select
          value={value}
          name={name}
          onChange={handleChange}
          required={required}
          className={error ? "is-invalid" : ""}
        >
          <option value="">Seleccione una opción</option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      {error && <small className="text-danger">{error}</small>}
    </div>
  );
}

export default Select;
