import React from "react";
import {
  Container,
  Table,
  Button,
  Row,
  Col,
  Modal,
  Form,
} from "react-bootstrap";
import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";
const moment = require("moment/moment");

function IndexUsuario() {
  const cookie = new Cookies();
  const access_token = cookie.get("perv_v2_cookies_user_kdfoeinapjdkfawep");

  const [users, setUsers] = useState([]);
  const [showModalDeclaracion, setShowModalDeclaracion] = useState(false);
  const [showModalFecha, setShowModalFecha] = useState(false);
  const [usersDeclaracion, setUsersDeclaracion] = useState([]);

  const [visualizacion, setVisualizacion] = useState("none");
  const [objFormulario, setObjFormulario] = useState({
    fecha_inicio: "",
    fecha_final: "",
    id_usuario: "",
    nombre_usuario: "",
  });

  const queryData = async () => {
    const cookie = new Cookies();
    const access_token = cookie.get("perv_v2_cookies_user_kdfoeinapjdkfawep");
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_SERVIDOR + "/api/v2/list/usuarios",
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );

      setUsers(data);
    } catch (error) {
      console.log("Error loading users from server: " + error.message);
    }
  };

  const modifyUser = async (id_user, accion) => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_SERVIDOR + "/api/v2/update/user",
        { id_user, accion },
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            Permission: "1",
          },
        }
      );

      if (data.message === "success") {
        queryData();
      }
    } catch (error) {
      if (error.response.status === 403) {
        Swal.fire({
          icon: "error",
          title: "No tienes permiso para esta acción",
        });
      }
      console.log("Error modifyUser: " + error.message);
    }
  };
  const declaracion = async (id, nombre) => {
    setObjFormulario({
      ...objFormulario,
      nombre_usuario: nombre,
      id_usuario: id,
    });
    setShowModalFecha(true);
  };
  const changeFormulario = (e) => {
    let { name, value } = e.target;
    setObjFormulario({
      ...objFormulario,
      [name]: value,
    });
  };

  const modalFecha = (
    <Modal show={showModalFecha} onHide={() => setShowModalFecha(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Ingresa el rango de fechas</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-3">
          <Form.Label column></Form.Label>
          <Form.Group as={Row} className="mb-3">
            <Col sm={6}>
              <Form.Control
                type="date"
                name="fecha_inicio"
                max={moment(new Date()).format("YYYY-MM-DD")}
                onChange={changeFormulario}
                value={objFormulario.fecha_inicio}
                autoComplete="off"
              />
            </Col>
            <Col sm={6}>
              <Form.Control
                type="date"
                name="fecha_final"
                max={moment(new Date()).format("YYYY-MM-DD")}
                onChange={changeFormulario}
                value={objFormulario.fecha_final}
                autoComplete="off"
              />
            </Col>
          </Form.Group>
        </Row>
        <Row>
          <Col>
            <Button variant="primary" onClick={() => queryDeclaracion()}>
              Consultar
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
  const modalDelcaracion = (
    <Modal
      show={showModalDeclaracion}
      onHide={() => setShowModalDeclaracion(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Declaración de {objFormulario.nombre_usuario}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table responsive bordered striped hover>
          <thead>
            <tr>
              <th>Acuerdo</th>
              <th>Fecha</th>
            </tr>
          </thead>
          <tbody>
            {usersDeclaracion.map((fila, id) => (
              <tr key={id}>
                <td>{id + 1}</td>
                <td>{moment(fila.fecha).format("YYYY-MM-DD, h:mm:ss")}</td>
              </tr>
            ))}
            <tr style={{ display: visualizacion }}>
              <td colSpan={2}>No hay datos registrados</td>
            </tr>
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => setShowModalDeclaracion(false)}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
  const queryDeclaracion = async () => {
    try {
      if (
        objFormulario.fecha_inicio !== "" &&
        objFormulario.fecha_final !== ""
      ) {
        const { data } = await axios.post(
          process.env.REACT_APP_SERVIDOR + "/api/v2/consult/user/statement",
          { objFormulario },
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
              Permission: "1",
            },
          }
        );

        if (data.length > 0) {
          setVisualizacion("none");
        } else {
          setVisualizacion("revert");
        }
        setUsersDeclaracion(data);
        setTimeout(() => {
          setShowModalFecha(false);
          setShowModalDeclaracion(true);
        }, 500);
      } else {
        Swal.fire({
          icon: "error",
          title: "Debes ingresar rango de fecha",
        });
      }
    } catch (error) {
      if (error.response.status === 403) {
        Swal.fire({
          icon: "error",
          title: "No tienes permiso para esta acción",
        });
      }
      console.log("Error: " + error.message);
    }
  };
  useEffect(() => {
    queryData();
  }, []);

  return (
    <Container className="text-center mt-2">
      <Row className="mb-3">
        <h2>LISTA DE USUARIOS</h2>
      </Row>
      <Row className="mb-3">
        <Col className="col-auto">
          <Button
            variant="primary"
            onClick={() => (window.location.href = "/registrar-usuario")}
          >
            Registrar usuario
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table responsive bordered striped hover>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Usuario</th>
                <th>Perfil</th>
                <th>Estado</th>
                <th>Declaraciones</th>
                <th>Acción</th>
              </tr>
            </thead>
            <tbody>
              {users.map((item, i) => (
                <tr key={i}>
                  <td>{item.nombre}</td>
                  <td>{item.usuario}</td>
                  <td>{item.perfiles}</td>
                  <td>{item.estado}</td>
                  <td>
                    <Button
                      variant="success"
                      onClick={() => declaracion(item.id, item.nombre)}
                    >
                      {" "}
                      Ver{" "}
                    </Button>
                  </td>
                  <td>
                    {item.estado === "Activo" ? (
                      <Button
                        variant="danger"
                        onClick={() => modifyUser(item.id, 1)}
                      >
                        Desactivar
                      </Button>
                    ) : (
                      <Button
                        variant="success"
                        onClick={() => modifyUser(item.id, 0)}
                      >
                        Activar
                      </Button>
                    )}
                    <Button
                      style={{ margin: "3px" }}
                      variant="primary"
                      onClick={() =>
                        (window.location.href = `registrar-usuario?id=${item.id}`)
                      }
                    >
                      Modificar
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      {modalDelcaracion}
      {modalFecha}
    </Container>
  );
}
export default IndexUsuario;
