import React, { useEffect } from "react";
import { Container, Row, Col, Table, Button, Form } from "react-bootstrap";
import { useState } from "react";
import Cookies from "universal-cookie";
import axios from "axios";
import Swal from "sweetalert2";
import XLSX from "sheetjs-style";

import moment from "moment";
import { saveFile } from "../common/utils/saveFile.util";

function FormularioIndicadores() {
  const cookie = new Cookies();
  const access_token = cookie.get("perv_v2_cookies_user_kdfoeinapjdkfawep");
  const [seleccionar, setSeleccionar] = useState(false);
  const [fecha, setFecha] = useState([]);
  const [infoExportExcel, setInfoExportExcel] = useState([]);
  const [objInforme, setObjInforme] = useState([]);
  const [indicador, serIndicador] = useState({
    id: "",
    nombre: "",
    descripcion: "",
    formula: "",
    frecuencia: "",
    cantVariables: "",
  });
  let datosFinal = [];
  const [htmlString, setHtmlString] = useState("");

  const queryIndicadores = async (e) => {
    let id_indicador = "";
    console.log(e.target.value);
    setFecha([]);

    if (e.target.value === "") {
      document.getElementById("selectIndicador").selectedIndex = 0;
    } else {
      id_indicador = e.target.value;
    }
    if (id_indicador !== "") {
      const datos = await axios.post(
        process.env.REACT_APP_SERVIDOR + `/api/v2/consultarIndicadores`,
        { id_indicador },
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            Permission: "1",
          },
        }
      );

      const datosObjecto = datos.data;
      if (datosObjecto.message) {
        const {
          id,
          nombre,
          descripcion,
          frecuencia,
          formula,
          cantidad_variantes,
        } = datosObjecto.resultado[0];

        const datos = await axios.post(
          process.env.REACT_APP_SERVIDOR + `/api/v2/consultarIndicadores/id`,
          { id },
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
              Permission: "1",
            },
          }
        );

        serIndicador({
          id: id,
          nombre: nombre,
          descripcion: descripcion,
          formula: formula,
          frecuencia: frecuencia,
          cantVariables: cantidad_variantes,
        });
        const datosContenido = datos.data;

        if (datosContenido.message) {
          await creacionTabla(
            datosObjecto.resultado[0],
            datosContenido.resultado
          );
        } else {
          await creacionTabla(datosObjecto.resultado[0], null);
        }
        await Version(id);

        setSeleccionar(true);
      } else {
        setSeleccionar(false);
      }
    } else {
      setHtmlString("");
      setSeleccionar(false);
    }
  };
  const creacionTabla = async (indicador, indicadorContenido) => {
    setHtmlString("");
    let tabla = "<thead><tr><th></th>";

    let frecuencia = "";
    if (indicador.frecuencia === "Trimestral y acumulado año") {
      frecuencia = "Trimestre";
    }
    if (indicador.frecuencia === "Anual") {
      frecuencia = "Anual";
    }
    if (
      indicador.frecuencia === "Mensual y acumulado año" ||
      indicador.frecuencia === "Acumulado mes y año"
    ) {
      frecuencia = "Mensual";
    }

    for (let i = 1; i <= indicador.cantidad_variantes; i++) {
      tabla += `<th>VARIABLE${i}</th>`;
    }
    tabla += `<th>INIDICADOR</th></tr></thead><tbody><tr>`;
    let filtro = [];
    switch (frecuencia) {
      case "Trimestre":
        for (let i = 1; i <= 4; i++) {
          tabla += `<th>TRIMESTRE ${i}</th>`;
          if (indicadorContenido !== null) {
            filtro = indicadorContenido.find(
              (e) => e.frecuencia === `TRIMESTRE ${i}`
            );
          } else {
            filtro = [];
          }
          for (let f = 1; f <= indicador.cantidad_variantes; f++) {
            if (filtro !== undefined) {
              if (filtro.length !== 0) {
                if (filtro.varibles[f - 1] != null) {
                  tabla += `<th> <input class="variante${i}" type="number" value='${
                    filtro.varibles[f - 1]
                  }' /></th>`;
                } else {
                  tabla += `<th> <input class="variante${i}" type="number" /></th>`;
                }
              } else {
                tabla += `<th> <input class="variante${i}" type="number" /></th>`;
              }
            } else {
              tabla += `<th> <input class="variante${i}" type="number" /></th>`;
            }
          }
          let valor_indicador = "";
          if (filtro !== undefined) {
            if (filtro.length !== 0) {
              valor_indicador = filtro.indicador;
            }
          }
          tabla += `<th><label id="indicador${i}">${valor_indicador}</label></th></tr>`;
        }
        break;
      case "Anual":
        for (let i = 1; i <= 1; i++) {
          let filtro = [];
          tabla += `<th>${new Date().getFullYear()}</th>`;
          if (indicadorContenido !== null) {
            filtro = indicadorContenido.find(
              (e) => e.frecuencia === new Date().getFullYear()
            );
          } else {
            filtro = [];
          }
          for (let f = 1; f <= indicador.cantidad_variantes; f++) {
            if (filtro !== undefined) {
              if (filtro.length !== 0) {
                if (filtro.varibles[f - 1] != null) {
                  tabla += `<th> <input class="variante${i}" type="number" value='${
                    filtro.varibles[f - 1]
                  }' /></th>`;
                } else {
                  tabla += `<th> <input class="variante${i}" type="number" /></th>`;
                }
              } else {
                tabla += `<th> <input class="variante${i}" type="number" /></th>`;
              }
            } else {
              tabla += `<th> <input class="variante${i}" type="number" /></th>`;
            }
          }
          let valor_indicador = "";
          if (filtro !== undefined) {
            if (filtro.length !== 0) {
              valor_indicador = filtro.indicador;
            }
          }
          tabla += `<th><label id="indicador${i}" >${valor_indicador}</label></th></tr>`;
        }
        break;
      case "Mensual":
        let monthsArray = [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ];

        for (let i = 1; i <= 12; i++) {
          tabla += `<th id="mes${i}">${monthsArray[i - 1]}</th>`;
          if (indicadorContenido !== null) {
            filtro = indicadorContenido.find(
              (e) => e.frecuencia === monthsArray[i - 1]
            );
          } else {
            filtro = [];
          }

          for (let f = 1; f <= indicador.cantidad_variantes; f++) {
            if (filtro !== undefined) {
              if (filtro.length !== 0) {
                if (filtro.varibles[f - 1] != null) {
                  tabla += `<th> <input class="variante${i}" type="number" value='${
                    filtro.varibles[f - 1]
                  }' /></th>`;
                } else {
                  tabla += `<th> <input class="variante${i}" type="number" /></th>`;
                }
              } else {
                tabla += `<th> <input class="variante${i}" type="number" /></th>`;
              }
            } else {
              tabla += `<th> <input class="variante${i}" type="number" /></th>`;
            }
          }
          let valor_indicador = "";
          if (filtro !== undefined) {
            if (filtro.length !== 0) {
              valor_indicador = filtro.indicador;
            }
          }
          tabla += `<th><label id="indicador${i}">${valor_indicador}</label></th></tr>`;
        }
        break;
      default:
        break;
    }
    setHtmlString(tabla);
  };
  const Calcular = () => {
    let nombre = indicador.id;
    let cant = indicador.cantVariables;

    let frecuencia = "";
    if (indicador.frecuencia === "Trimestral y acumulado año") {
      frecuencia = "Trimestre";
    }
    if (indicador.frecuencia === "Anual") {
      frecuencia = "Anual";
    }
    if (
      indicador.frecuencia === "Mensual y acumulado año" ||
      indicador.frecuencia === "Acumulado mes y año"
    ) {
      frecuencia = "Mensual";
    }

    datosFinal = [];
    let datosTemporal = {
      id: "",
      frecuencia: "",
      indicador: 0,
      variantes: [],
    };
    switch (frecuencia) {
      case "Trimestre":
        for (let t = 1; t <= 4; t++) {
          let datos = [];
          for (let i = 0; i < cant; i++) {
            let variantes = document.getElementsByClassName(`variante${t}`)[i]
              .value;
            if (variantes !== "") {
              datosTemporal.id = indicador.id;
              datosTemporal.frecuencia = `TRIMESTRE ${t}`;
              datosTemporal.variantes.push(variantes);

              datos.push(parseInt(variantes));
            }
          }
          if (datos.length > 0) {
            let indicador = Formular(datos, nombre);
            document.getElementById(`indicador${t}`).innerHTML = indicador;
            datosTemporal.indicador = indicador;
            datosFinal.push(datosTemporal);
          } else {
            document.getElementById(`indicador${t}`).innerHTML = "";
          }
          datosTemporal = {
            id: "",
            frecuencia: "",
            indicador: 0,
            variantes: [],
          };
        }

        break;
      case "Anual":
        for (let t = 1; t <= 1; t++) {
          let datos = [];
          for (let i = 0; i < cant; i++) {
            let variantes = document.getElementsByClassName(`variante${t}`)[i]
              .value;
            if (variantes !== "") {
              datosTemporal.id = indicador.id;
              datosTemporal.frecuencia = new Date().getFullYear();
              datosTemporal.variantes.push(variantes);

              datos.push(parseInt(variantes));
            }
          }
          if (datos.length > 0) {
            let indicador = Formular(datos, nombre);
            document.getElementById(`indicador${t}`).innerHTML = indicador;
            datosTemporal.indicador = indicador;
            datosFinal.push(datosTemporal);
          } else {
            document.getElementById(`indicador${t}`).innerHTML = "";
          }
          datosTemporal = {
            id: "",
            frecuencia: "",
            indicador: 0,
            variantes: [],
          };
        }

        break;
      case "Mensual":
        for (let t = 1; t <= 12; t++) {
          let datos = [];
          for (let i = 0; i < cant; i++) {
            let variantes = document.getElementsByClassName(`variante${t}`)[i]
              .value;

            if (variantes !== "") {
              datosTemporal.id = indicador.id;
              datosTemporal.frecuencia = document.getElementById(
                `mes${t}`
              ).innerHTML;
              datosTemporal.variantes.push(variantes);

              datos.push(parseInt(variantes));
            }
          }
          if (datos.length > 0) {
            let indicador = Formular(datos, nombre);
            document.getElementById(`indicador${t}`).innerHTML = indicador;
            datosTemporal.indicador = indicador;
            datosFinal.push(datosTemporal);
          } else {
            document.getElementById(`indicador${t}`).innerHTML = "";
          }
          datosTemporal = {
            id: "",
            frecuencia: "",
            indicador: 0,
            variantes: [],
          };
        }
        break;
      default:
        break;
    }
  };
  const Formular = (datos, nombre) => {
    let indicador = 0;
    switch (nombre) {
      case 1:
        if (
          datos[0] !== undefined &&
          datos[1] !== undefined &&
          datos[2] !== undefined
        ) {
          indicador = ((datos[0] * datos[1]) / datos[2]).toFixed(2);
        } else {
          mostrarAlertError(nombre);
          indicador = "0";
        }
        break;
      case 2:
        if (datos[0] !== undefined && datos[1] !== undefined) {
          indicador = (datos[0] + datos[1]).toFixed(2);
        } else {
          mostrarAlertError(nombre);
          indicador = "0";
        }
        break;
      case 3:
        if (datos[0] !== undefined && datos[1] !== undefined) {
          indicador = (datos[1] - datos[0]).toFixed(2);
        } else {
          mostrarAlertError(nombre);
          indicador = "0";
        }
        break;
      case 4:
        if (datos[0] !== undefined && datos[1] !== undefined) {
          indicador = (datos[1] - datos[0]).toFixed(2);
        } else {
          mostrarAlertError(nombre);
          indicador = "0";
        }
        break;
      case 5:
        if (datos[0] !== undefined && datos[1] !== undefined) {
          indicador = ((datos[0] / datos[1]) * 100).toFixed(2);
        } else {
          mostrarAlertError(nombre);
          indicador = "0";
        }
        break;
      case 6:
        if (datos[0] !== undefined && datos[1] !== undefined) {
          indicador = ((datos[0] / datos[1]) * 100).toFixed(2);
        } else {
          mostrarAlertError(nombre);
          indicador = "0";
        }
        break;
      case 7:
        if (datos[0] !== undefined && datos[1] !== undefined) {
          indicador = ((datos[0] / datos[1]) * 100).toFixed(2);
        } else {
          mostrarAlertError(nombre);
          indicador = "0";
        }
        break;
      case 8:
        if (datos[0] !== undefined && datos[1] !== undefined) {
          indicador = ((datos[0] / datos[1]) * 100).toFixed(2);
        } else {
          mostrarAlertError(nombre);
          indicador = "0";
        }
        break;
      case 9:
        if (datos[0] !== undefined && datos[1] !== undefined) {
          indicador = ((datos[0] / datos[1]) * 100).toFixed(2);
        } else {
          mostrarAlertError(nombre);
          indicador = "0";
        }
        break;
      case 10:
        if (datos[0] !== undefined && datos[1] !== undefined) {
          indicador = ((datos[0] / datos[1]) * 100).toFixed(2);
        } else {
          mostrarAlertError(nombre);
          indicador = "0";
        }
        break;
      case 11:
        if (datos[0] !== undefined && datos[1] !== undefined) {
          indicador = ((datos[0] / datos[1]) * 100).toFixed(2);
        } else {
          mostrarAlertError(nombre);
          indicador = "0";
        }
        break;
      case 12:
        if (datos[0] !== undefined && datos[1] !== undefined) {
          indicador = ((datos[0] / datos[1]) * 100).toFixed(2);
        } else {
          mostrarAlertError(nombre);
          indicador = "0";
        }
        break;
      case 13:
        if (datos[0] !== undefined && datos[1] !== undefined) {
          indicador = ((datos[0] / datos[1]) * 100).toFixed(2);
        } else {
          mostrarAlertError(nombre);
          indicador = "0";
        }
        break;
      case 14:
        if (datos[0] !== undefined && datos[1] !== undefined) {
          indicador = ((datos[1] / datos[0]) * 100).toFixed(2);
        } else {
          mostrarAlertError(nombre);
          indicador = "0";
        }
        break;

      default:
        break;
    }
    return indicador;
  };
  const Guardar = async () => {
    await Calcular();
    if (datosFinal.length > 0) {
      AlertEspera();
      const { data } = await axios.post(
        process.env.REACT_APP_SERVIDOR + "/api/v2/insert/indicador",
        datosFinal,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (data.message) {
        Swal.fire({
          title: "Registro exitoso",
          icon: "success",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "ADVERTENCIA",
        text: "Debes calcular los datos antes de Guardar",
        showConfirmButton: false,
        focusConfirm: false,
      });
    }
  };
  const Version = async (id) => {
    const { data } = await axios.post(
      process.env.REACT_APP_SERVIDOR +
        "/api/v2/consultarIndicadores/version_fecha",
      { id },
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    if (data.message) {
      setFecha(data.resultado);
    }
  };
  const Exportable = async () => {
    try {
      if (infoExportExcel.length > 0) {
        const { data } = await axios.post(
          process.env.REACT_APP_SERVIDOR +
            "/api/v2/consultarIndicadores/exportable",
          { indicador: indicador.id, fecha: infoExportExcel },
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        if (data.message) {
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

          const ws = XLSX.utils.json_to_sheet(data.resultado);
          const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
          const excelBuffer = XLSX.write(wb, {
            booktype: "xlsx",
            type: "array",
          });
          const filename = `Indicador_Versión_${moment().format(
            "DD-MM-YYYY"
          )}_${moment().unix()}.xlsx`;
          const file = new Blob([excelBuffer], { type: fileType });
          saveFile(file, filename);
          setInfoExportExcel([]);
        }
      } else {
        Swal.fire({
          icon: "warning",
          title: "ADVERTENCIA",
          text: "Selecciona la versión a exportar.",
          showConfirmButton: false,
          showCloseButton: true,
          focusConfirm: false,
          confirmButtonText: "Aceptar",
        });
      }
    } catch (error) {
      console.log("Error exporting Excel file: " + error.message);
    }
  };
  const AlertEspera = () => {
    let timerInterval;
    Swal.fire({
      title: "GUARDANDO INFORMACIÓN",
      timer: 3000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        const b = Swal.getHtmlContainer().querySelector("b");
        if (b) {
          timerInterval = setInterval(() => {
            b.textContent = Swal.getTimerLeft();
          }, 100);
        }
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log("I was closed by the timer");
      }
    });
  };
  const mostrarAlertError = (nombre) => {
    Swal.fire({
      icon: "info",
      title: "INFORMATIVO",
      html: `Ingresa las variables necesarias para calcular el <br/><strong>${nombre}</strong>`,
      showConfirmButton: false,
      focusConfirm: false,
    });
  };
  const Indicadores = async () => {
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_SERVIDOR + `/api/v2/consultar/Indicadores`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            Permission: "1",
          },
        }
      );

      if (data.message) {
        setObjInforme(data.resultado);
      }
    } catch (error) {
      console.log("Error en la consulta: " + error);
    }
  };
  useEffect(() => {
    Indicadores();
  }, []);

  return (
    <>
      <Container className="text-center mt-3">
        <br />
        <h2>FORMULARIO DE INDICADORES</h2>
        <br />
        <Row className="mb-3">
          <Col xs="18">
            <Form.Select
              id="selectIndicador"
              onChange={(e) => queryIndicadores(e)}
              className="mb-3"
            >
              <option value="">Selecciona Indicador</option>
              {objInforme.map((fila, id) => (
                <option key={id} value={fila.id}>
                  {fila.nombre}
                </option>
              ))}
            </Form.Select>
            {seleccionar ? (
              <Form.Group className="mb-3">
                <pre
                  style={{
                    textAlign: "justify",
                    fontSize: "20px",
                    fontFamily: "Arial, Helvetica, sans-serif",
                  }}
                >
                  <Form.Control
                    as="textarea"
                    value={indicador.descripcion}
                    disabled
                    style={{
                      minHeight: "200px",
                      maxHeight: "250px",
                      border: "none",
                      background: "white",
                    }}
                  />
                </pre>
                <Form.Label>
                  <strong>FORMULA: </strong> {indicador.formula}
                </Form.Label>
              </Form.Group>
            ) : (
              <Form.Group className="mb-3">
                <br />
                <h5>
                  <strong>NO TIENES INDICADOR SELECCIONADO</strong>
                </h5>
                <br />
              </Form.Group>
            )}
          </Col>
        </Row>
        {fecha.length > 0 ? (
          <Row className="mb-3">
            <Col xs="2">
              <Form.Label>
                <strong>Versiones: </strong>
              </Form.Label>
            </Col>
            <Col xs="6">
              <Form.Group className="mb-3">
                <Form.Select
                  onChange={(e) => setInfoExportExcel(e.target.value)}
                >
                  <option></option>
                  {fecha.map((item, id) => (
                    <option key={id} value={item.fecha}>
                      VERSIÓN: {id + 1}: {item.fecha}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs="4">
              <Button
                style={{ width: "100%" }}
                variant="dark"
                onClick={() => Exportable()}
              >
                Exportable
              </Button>
            </Col>
          </Row>
        ) : null}
        <br />
        <Row className="mb-3">
          <Col xs="18">
            <Table dangerouslySetInnerHTML={{ __html: htmlString }}></Table>
          </Col>
        </Row>
        <Row className="mb-2">
          <br />
          <br />
          <Col xs={8}>
            {seleccionar ? (
              <Button
                style={{ textAling: "center", width: "50%", marginLeft: "50%" }}
                variant="dark"
                onClick={() => Guardar()}
              >
                Guardar
              </Button>
            ) : null}
          </Col>
          <br />
        </Row>
      </Container>
    </>
  );
}
export default FormularioIndicadores;
