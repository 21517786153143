import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";

function RegistrarRutina() {
  const cookie = new Cookies();
  const access_token = cookie.get("perv_v2_cookies_user_kdfoeinapjdkfawep");
  const tipo_vehiculo = cookie.get("tipo_vehiculo");

  const navigate = useNavigate();

  const [validated, setValidated] = useState(false);
  const [validacionAccion, setValidacionAccion] = useState(false);

  const [datos, setDatos] = useState({
    Rutina: "",
    Kilometraje: "",
    Tipo: "",
    Operaciones: "",
    Actividad: "",
    Centro: "",
  });

  const onChangeInfo = (e) => {
    const { name, value } = e.target;
    setDatos({
      ...datos,
      [name]: value,
    });
  };
  const ConsultarRutinas = async () => {
    if (window.location.search !== "") {
      let id_rutina = window.location.search.split("=")[1];
      const { data } = await axios.post(
        process.env.REACT_APP_SERVIDOR + "/api/v2/listar/consultar_rutinas/id",
        { tipo: tipo_vehiculo, id_rutina: id_rutina },
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (data.estado) {
        setValidacionAccion(true);
        const datosRutina = data.datos[0];
        setDatos({
          Rutina: datosRutina.rutina,
          Kilometraje: datosRutina.kilometraje,
          Tipo: datosRutina.tipo,
          Operaciones: datosRutina.operaciones,
          Actividad: datosRutina.actividad,
        });
      }
    }
  };
  const validityForm = async (e) => {
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      e.preventDefault();
      if (!validacionAccion) {
        await RegistrarRutina();
      } else {
        await ModificarRutina();
      }
    }
  };
  const RegistrarRutina = async () => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_SERVIDOR +
          "/api/v2/mantenimiento/registrar_rutinas",
        { datos: datos, tipo: tipo_vehiculo },
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            Permission: "1",
          },
        }
      );
      if (data.message === "success") {
        mostrarAlert("success", "Mensaje", "Registro Exitoso");
        setDatos({
          Rutina: "",
          Kilometraje: "",
          Tipo: "",
          Operaciones: "",
          Actividad: "",
        });
      }

      if (data.message === "success") {
        setValidated(false);
      }
    } catch (error) {
      console.log("Error insert rutina: " + error.message);
      if (error.response.status === 403) {
        Swal.fire({
          icon: "error",
          title: "No tienes permiso para esta acción",
        });
      }
    }
  };
  const ModificarRutina = async () => {
    try {
      let id_rutina = window.location.search.split("=")[1];

      const { data } = await axios.post(
        process.env.REACT_APP_SERVIDOR +
          "/api/v2/mantenimiento/actualizar_rutinas",
        { dato: datos, tipo: tipo_vehiculo, idRutina: id_rutina },
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            Permission: "1",
          },
        }
      );

      if (data.message === "success") {
        mostrarAlert("success", "Mensaje", "Actualización Exitoso");
        setDatos({
          Rutina: "",
          Kilometraje: "",
          Tipo: "",
          Operaciones: "",
          Actividad: "",
        });
        setValidated(false);
        navigate("/gestion-mantenimiento/consultar-rutinas");
      }
    } catch (error) {
      console.log("Error updated rutina: " + error.message);
      if (error.response.status === 403) {
        Swal.fire({
          icon: "error",
          title: "No tienes permiso para esta acción",
        });
      }
    }
  };
  const mostrarAlert = (icono, titulo, mensaje) => {
    Swal.fire({
      icon: icono,
      title: mensaje,
    });
  };
  useEffect(() => {
    ConsultarRutinas();
  }, []);

  return (
    <>
      <Container className="text-center mt-3">
        <Row className="mb-3">
          <h1>Formulario de Rutinas</h1>
        </Row>
        <br />
        <Row className="mb-3">
          <Form
            noValidate
            validated={validated}
            onSubmit={(e) => validityForm(e)}
          >
            <Row className="mb-1 align-items-center justify-content-center">
              <Col xs="2">
                <Form.Label>Rutina: </Form.Label>
              </Col>
              <Col xs="3">
                <Form.Group>
                  <Form.Control
                    required
                    name="Rutina"
                    type="text"
                    value={datos.Rutina}
                    onChange={onChangeInfo}
                    maxLength={50}
                  />
                  <Form.Control.Feedback type="invalid">
                    Obligatorio
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <br />
            <Row className="mb-1 align-items-center justify-content-center">
              <Col xs="2">
                <Form.Label>Sistema: </Form.Label>
              </Col>
              <Col xs="3">
                <Form.Group>
                  <Form.Control
                    required
                    name="Tipo"
                    type="text"
                    value={datos.Tipo}
                    onChange={onChangeInfo}
                    maxLength={50}
                  />
                  <Form.Control.Feedback type="invalid">
                    Obligatorio
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs="2">
                <Form.Label>Elemento:</Form.Label>
              </Col>
              <Col xs="3">
                <Form.Group>
                  <Form.Control
                    required
                    name="Operaciones"
                    type="text"
                    value={datos.Operaciones}
                    onChange={onChangeInfo}
                    maxLength={255}
                  />
                  <Form.Control.Feedback type="invalid">
                    {" "}
                    Obligatorio
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <br />
            <Row className="mb-1 align-items-center justify-content-center">
              <Col xs="2">
                <Form.Label>Actividad:</Form.Label>
              </Col>
              <Col xs="3">
                <Form.Select
                  required
                  value={datos.Actividad}
                  name="Actividad"
                  onChange={(e) => {
                    onChangeInfo(e);
                  }}
                >
                  <option></option>
                  <option value="Lubricar">L: Lubricar</option>
                  <option value="Reemplazar">R: Reemplazar</option>
                  <option value="Ajustar">A: Ajustar</option>
                  <option value="Ejecutar">E: Ejecutar</option>
                  <option value="Torque específico">
                    T: Torque específico
                  </option>
                </Form.Select>
                <Form.Group>
                  <Form.Control.Feedback type="invalid">
                    Obligatorio
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs="2"></Col>
              <Col xs="3"></Col>
            </Row>
            <br />
            <br />

            <Row className="mb-1 align-items-center justify-content-center">
              <Col xs="2">
                <Button type="submit" variant="dark">
                  Aceptar
                </Button>
              </Col>
            </Row>
          </Form>
          <br />
        </Row>
      </Container>
    </>
  );
}
export default RegistrarRutina;
