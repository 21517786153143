export const deleteClaimsCostsService = (fetchData) => async () => {
  try {
    const response = await fetchData({
      method: "delete",
      url: `/api/v1/siniestros-viales/documentos/costos-siniestros-juridicos`,
    });

    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};
