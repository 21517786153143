import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import {
  Card,
  ListGroup,
  Modal,
  Button,
  Form,
  Row,
  Col,
} from "react-bootstrap";
import { BsFillTrashFill } from "react-icons/bs";
import Cookies from "universal-cookie";

function CardWeek({ props }) {
  const access_token = new Cookies().get(
    "perv_v2_cookies_user_kdfoeinapjdkfawep"
  );
  const { week, data, queryTask } = props;
  let items = data.filter((item) => item.week === week);

  let tasks = [];
  items.forEach((element) => {
    element.data.forEach((task) => {
      tasks.push(task);
    });
  });

  const [infoTask, setInfoTask] = useState({
    descripcion_tarea: "",
    fecha_tarea: "",
  });

  const [show, setShow] = useState(false);

  const handleModal = (task) => {
    setInfoTask({
      ...task,
    });
    setShow(true);
  };
  const modalInfoTask = (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header>
        <Modal.Title>Info Tarea</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h6>{infoTask.descripcion_tarea}</h6>
        <p>{moment(infoTask.fecha_tarea).format("DD-MM-YYYY")}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setShow(false);
            setInfoTask({});
          }}
        >
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const paintBg = (type) => {
    let color = "";
    if (type === "Documentación") {
      color = "#0dcaf0";
    } else if (type === "Capacitación") {
      color = "#EFA3C8";
    } else if (type === "Mantenimiento") {
      color = "#ffc107";
    } else {
      color = "#212529";
    }

    return color;
  };

  const queryDeleteTask = async (id) => {
    try {
      const { data } = await axios.delete(
        process.env.REACT_APP_SERVIDOR + `/api/v2/delete/tarea/${id}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            Permission: "1",
          },
        }
      );

      if (data.message === "success") {
        queryTask();
      }
    } catch (error) {
      console.log("Error deleting task: " + error.message);
    }
  };

  const queryUpdateTask = async (e, id) => {
    const { checked } = e.target;
    let estado = checked ? "Completado" : "Activo";
    try {
      const { data } = await axios.put(
        process.env.REACT_APP_SERVIDOR + "/api/v2/update/task",
        { estado, id },
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );

      if (data.message === "success") {
        queryTask();
      }
    } catch (error) {
      console.log("Error updating task: " + error.message);
    }
  };

  return (
    <Card border="dark" className="mx-2" style={{ height: "480px" }}>
      <Card.Header>
        <Card.Title>SEMANA NUMERO {week + 1}</Card.Title>
      </Card.Header>
      <Card.Body style={{ overflowY: "scroll" }}>
        <ListGroup className="list-group-flush">
          {tasks.map((task, i) => (
            <ListGroup.Item key={i}>
              <Row>
                <Col xs="1">
                  <Form.Check
                    checked={task.estado_tarea === "Completado" ? true : false}
                    onChange={(e) => queryUpdateTask(e, task.id_tarea)}
                  />
                </Col>
                <Col xs="5">
                  <span
                    style={{ backgroundColor: paintBg(task.tipo_tarea) }}
                    className="badge text-dark"
                  >
                    {task.tipo_tarea}
                  </span>
                </Col>
                <Col xs="4">
                  <span
                    style={{ backgroundColor: paintBg(task.tipo_tarea) }}
                    className="badge text-dark"
                  >
                    {moment(task.fecha_tarea).format("DD-MM-YYYY")}
                  </span>
                </Col>
                <Col xs="1">
                  <BsFillTrashFill
                    onClick={() => queryDeleteTask(task.id_tarea)}
                  />
                </Col>
              </Row>
              <Row onClick={() => handleModal(task)}>
                <div className="text-truncate col-12 ">
                  <p
                    className={
                      task.estado_tarea === "Completado"
                        ? " text-decoration-line-through"
                        : ""
                    }
                  >
                    {task.descripcion_tarea}
                  </p>
                </div>
              </Row>
            </ListGroup.Item>
          ))}
          {modalInfoTask}
        </ListGroup>
      </Card.Body>
    </Card>
  );
}

export default CardWeek;
